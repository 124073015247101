<template>
    <div class="flex flex-col justify-center items-center p-4 px-6">
        <div class="font-300 bg-gray-200 rounded-full">
            <p class="pt-2 pb-2.5 px-3">{{ iniziali }}</p>
        </div>
        <h1 class="text-3xl font-black">{{ user.fullName }}</h1>
        <p class="text-gray-600">{{ user.email }}</p>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { useSessionStore } from '@/stores/session';
    import { storeToRefs } from 'pinia';
    import getInizialiUser from '@/utilities/getInizialiUser';

    const sessionStore = useSessionStore();
    const { user } = storeToRefs(sessionStore);
    const { iniziali, fullName, email, companyName } = getInizialiUser();
</script>

<style>

</style>