<template>
    <div>
        <button @click="downloadPDF" class="w-64 h-12 rounded mt-2 p-2 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300 flex justify-center items-center">Scarica pdf</button>
        <div class="mt-12 h-screen overflow-auto">
            <div v-for="obj, i in diarioSistema" class="mb-6 w-72 md:w-[32rem]">
                <div class="flex text-sm">
                    <p class="mr-2">Sistema</p>
                    <p class="text-zinc-400">{{ times[i] }} 🕒</p>
                </div>
                <div class="bg-zinc-100 dark:bg-zinc-700 rounded p-2">
                    <p class="max-w-lg">{{ obj.utenteWriter.firstName }} {{ obj.utenteWriter.lastName }} {{ obj.systemDiary.note }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { GetSystemDiary } from '@/helpers/claims.service';
import createDiaryPDF from '@/utilities/createDiaryPDF';

    const props = defineProps({
        claimId: String,
    })

    const diarioSistema = ref([]);
    const times = ref([]);

    onMounted(() => {
        getSystemDiary(props.claimId);
    })

    const downloadPDF = function downloadPDF() {
        const pdfName = 'diario_di_sistema_' + props.claimId; 

        let arr = [];
        for (let obj of diarioSistema.value) {
            const t = new Date(obj.systemDiary.createAt);
            const time = `${t.getHours()}:${t.getMinutes()} • ${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()}`;
            const message = `${obj.utenteWriter.firstName} ${obj.utenteWriter.lastName} ${obj.systemDiary.note}`

            arr.push({ "time": time, "message": message })
        }

        createDiaryPDF(pdfName, props.claimId, arr);
    };

    async function getSystemDiary(claimId) {
        const response = await GetSystemDiary(claimId);
        if (response.status === 200) {
            diarioSistema.value = response.data.diary;
            diarioSistema.value.reverse();

            for (let obj of diarioSistema.value) {
                const t = new Date(obj.systemDiary.createAt);
                const time = `${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()} - ${t.getHours()}:${t.getMinutes()}`;
                times.value.push(time);
            }

            return
        }
    }
</script>

<style scoped>
</style>