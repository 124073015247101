import { useToast } from 'vue-toast-notification';
import { useSinistroStore } from '@/stores/sinistro';
import { MaxAllowedFileSize } from '@/helpers/constants';
import { AddDocumentToClaim } from '@/helpers/claims.service';
import { CreateCode } from '@/helpers/utils';

export async function onFileChanged(event) {
    
    const obj = event.target;
    const file =  obj.files[0];
    const name = obj.getAttribute('name');
    
    if (file.size > MaxAllowedFileSize) {
        let size = file.size;
        size = ( size >>> 20 ) + ',' + ( size & (2*0x3FF ) ) + ' MB ' ;

        const toast = useToast();
        toast.error("File troppo grande " + size + "", {
            position: 'top',
            timeOut: 15000,
        });
        return null;
    }
    
    const sinistro = useSinistroStore();
    return sinistro.uploadSingleFileAction(file).then((data) => {
        console.log(data)
        if (data.isOk){
            let docs = [];
            docs.push({id: data.data.id, fileName: data.data.fileName, code: CreateCode(name), name: name, fname: file.name});
            
            return docs
        }

        const toast = useToast();
        toast.error("Errore nel caricamento del file " + file.name, {
            position: 'top',
            timeOut: 15000,
        });
    });
}

export async function addFileToClaim(files, claimId) {
    const payload = {
        'optionalDocuments': files
    };

    AddDocumentToClaim(claimId, payload).then(resp => {
        if (resp.status == 200) {
            const toast = useToast();
            toast.success("Documenti aggiunti correttamente", {
                position: 'top',
                timeOut: 15000,
            });

            return
        }

        const toast = useToast();
        toast.error("Errore caricamento documenti", {
            position: 'top',
            timeOut: 15000,
        });

    }).catch(err => {
        console.log(err);
    })
}