import * as api from './api'
import * as constants from './constants';


export const GetAllIbans = async () =>  {
    const action = api.GetAction(constants.API, '/tcl-auth/companyIban', 'GET')
    const response = await api.CallApi(action);
    return response
};

export const DeleteIban = async (ibanId) =>  {
    const action = api.GetAction(constants.API, `/tcl-auth/companyIban/delete/${ibanId}`, 'POST')
    const response = await api.CallApi(action);
    return response
};

export const AddIban = async (payload) =>  {
    const action = api.GetAction(constants.API, `/tcl-auth/companyIban`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
};

export const EditIban = async (ibanId, payload) =>  {
    const action = api.GetAction(constants.API, `/tcl-auth/companyIban/edit/${ibanId}`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
};

export const SetDefaultIban = async (ibanId) =>  {
    const action = api.GetAction(constants.API, `/tcl-auth/companyIban/setDefault/${ibanId}`, 'POST')
    const response = await api.CallApi(action);
    return response
};