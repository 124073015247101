<template>
    <div>
        <canvas ref="ctx"></canvas>
    </div>
</template>

<script setup lang="ts">
    import { ref, watchEffect } from 'vue';
    import Chart from 'chart.js/auto';
    import { ChartData } from '@/models/models';
    // import { lista_filtri } from '@/helpers/constants';

    const emits = defineEmits(['select']);
    const props = defineProps<{
        chartType: string | any,
        data: ChartData[] | any,
        label?: string,
    }>();

    const ctx = ref<HTMLCanvasElement>();
    const chart = ref<Chart>();

    watchEffect(() => {
        initializeChart();
    })

    async function initializeChart() {
        if (!ctx.value) {
            return
        }

        if (chart.value) {
            chart.value.destroy();
        }
                
        chart.value = new Chart(ctx.value as HTMLCanvasElement, {
            type: props.chartType,
            data: {
                labels: props.data.map((row: ChartData) => row.key),
                datasets: [{
                    label: props.label ?? 'Numero sinistri',
                    data: props.data.map((row: ChartData) => row.count),
                    pointBorderWidth: 6,
                    pointHoverBorderWidth: 12,
                    borderColor: '#ef4444',
                    backgroundColor: '#ef4444',
                }]
            },
            options: {
                elements: {
                    line: {
                        tension: 0.4,
                    }
                },
                onClick: (e: Event | any) => {
                    const interactionOptions = {};

                    const elements = chart.value!.getElementsAtEventForMode(e, 'point', interactionOptions, false);
                    const canvasPosition = elements[0]?.element
                    const dataX = chart.value!.scales.x.getValueForPixel(canvasPosition?.x);
                
                    const select = props.data[dataX!] ?? {};    
                    emits('select', select.key);
                }
            }
        });
    }
</script>

<style scoped>

</style>