
export const ConvertStringToCamelCase = (sentence: string) => {
    let str = sentence.replace(/\\/g, '');
    str = sentence.replace(/\//g, '');

    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g,
    function(camelCaseMatch, i) {
       if (+camelCaseMatch === 0)
          return "";
       return i === 0 ? camelCaseMatch.toLowerCase() :
       camelCaseMatch.toUpperCase();
    });
};


export const RemoveAccents = (paraString: string) => {
    let strAccents = paraString.split('');
    let strAccentsOut: any[] | string = new Array();
    let strAccentsLen = strAccents.length;
    let accents =    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëÇçðÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
    let accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeCcdDIIIIiiiiUUUUuuuuNnSsYyyZz";
    for (let y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) != -1) {
            strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
        } else
            strAccentsOut[y] = strAccents[y];
    }
    strAccentsOut = strAccentsOut.join('');
    return strAccentsOut;
};

export const CreateCode = (arg: string) => {
    return ConvertStringToCamelCase(RemoveAccents(arg))
};

export const Truncate = (str: string, n: number) => {
    return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
};

export const TruncateSaveExt = (str: string, n: number) => {
    // ! TODO ----
    return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
};

// https://developers.google.com/maps/documentation/javascript/geocoding
export const GetLatLong = async (address: any) =>  {
    let latitude, longitude;

    // @ts-ignore
    var geocoder = new google.maps.Geocoder();
    await geocoder.geocode({'address': address}, (results: any[]) => {
        latitude = results[0].geometry.location.lat();
        longitude = results[0].geometry.location.lng();
    })

    return { latitude, longitude }
}

export const fromStringToFloat = (str: string) => {
    let n = ''

    str = str.replaceAll('.', '');
    str = str.replaceAll(',', '');

    if (str.includes(' €')) {
        n = str.substr(0, str.indexOf(' '))
    }

    return parseFloat(n)
}


export const ToCurrency = (value: number) => {
    var formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
    });
    return formatter.format(value);
}

export function extractNumber(str: string) {
    const pattern = /(\d+),(\d{2})/;
    const match = pattern.exec(str);
    if (match) {
        const numStr = `\${match[1]}.\${match[2]}`;
        return Number(numStr);
    }
    
    return null;
}

export const ToCents = (value: number) => {
    return Math.round(value * 100);
}

export const ToMoney = (value: number) => {
    return value / 100;
}

export const removeFormattingFromPhoneNumber = (str: string) => {
    if (!str || str == "") {
        return ""
    }

    str = str.replace(/\s+/g, '');
    str = str.replace('+39', '');
    str = str.replace('+49', '');

    return str
}