<template>
    <div class="flex flex-col md:flex-row justify-center divide-x divide-dashed divide-gray-300 dark:bg-zinc-900 dark:text-white">
        <div id="info-generali" class="m-12 w-96 mr-12">
            <HeaderSinistro />
            <table class="my-6 w-80 md:w-96 dark:bg-zinc-800 dark:text-white">
                <tbody class="divide-y">
                    <tr class="text-left">
                        <th class="p-2 px-2">Marca</th>
                        <td class="text-sm">{{ marca }}</td>
                    </tr>
                    <tr class="text-left">
                        <th class="p-2 px-2">Modello</th>
                        <td class="text-sm">{{ modello }}</td>
                    </tr>
                    <tr class="text-left">
                        <th class="p-2 px-2">{{ $t('infoGenerali.chassis') }}</th>
                        <td class="text-sm">{{ telaio }}</td>
                    </tr>
                    <tr class="text-left">
                        <th class="p-2 px-2">{{ $t('infoGenerali.fiscalCode') }}</th>
                        <td class="text-sm">{{ codiceFiscaleContraente }}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <div class="my-6" v-if="isRca">
                    <h1 class="text-xl font-bold">Data danni da <span class="text-red-600">circolazione</span></h1>
                    <table>
                        <tbody>
                            <tr class="text-left" >
                                <th class="p-2 px-2">{{ $t('infoGenerali.rcaActivationDate') }}</th>
                                <td class="text-sm">{{ dataAttivazioneRCA }}</td>
                            </tr>
                            <tr class="text-left">
                                <th class="p-2 px-2">{{ $t('infoGenerali.rcaEndingDate') }}</th>
                                <td class="text-sm">{{ dataFineRCA }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="my-6" v-if="isCvt">
                    <h1 class="text-xl font-bold">Data danni da <span class="text-red-600">stazionamento</span></h1>
                    <table class="my-3 dark:bg-zinc-800 dark:text-white">
                        <tbody class="divide-y">
                            <tr class="text-left">
                                <th class="p-2 px-2">{{ $t('infoGenerali.cvtActivationDate') }}</th>
                                <td class="text-sm">{{ dataAttivazioneCVT }}</td>
                            </tr>
                            <tr class="text-left">
                                <th class="p-2 px-2">{{ $t('infoGenerali.cvtEndingDate') }}</th>
                                <td class="text-sm">{{ dataFineCVT }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="p-12 py-14">
            <div>
                <h1 class="text-2xl font-bold w-50 mb-6">Dati necessari</h1>
                <div class="phoneNumber mt-6">
                    <p>{{ $t('infoGenerali.phoneNumberInput.phoneNumber') }} <span class="text-red-600">*</span></p>
                    <MazPhoneNumberInput show-code-on-list :only-countries="['IT', 'DE']" color="danger"
                        id="phone-input" class="w-80 md:w-96" v-model="sinistro.phoneNumber" :translations="{
                            countrySelector: {
                                placeholder: $t('infoGenerali.phoneNumberInput.countrySelectorPlaceholder'),
                                error: $t('infoGenerali.phoneNumberInput.countrySelectorError'),
                            },
                            phoneInput: {
                                placeholder: $t('infoGenerali.phoneNumberInput.phoneInputPlaceholder'),
                                example: $t('infoGenerali.phoneNumberInput.phoneInputExample'),
                            },
                    }" autocomplete="off" />
                </div>
                <div class="address mt-6">
                    <p>{{ $t('infoGenerali.address') }} <span class="text-red-600">*</span></p>
                    <GoogleMapsAddress which-address="addressUser" :addressFound="placeClaime.formatted_address" @get-place="(place) => getPlace(place)" />
                </div>
                <div class="email mt-6">
                    <p>{{ $t('infoGenerali.email') }} <span class="text-red-600">*</span></p>
                    <input name="emailContraente" v-model="emailContraente" class="input w-80 md:w-96 dark:bg-zinc-700"
                        type="email" />
                </div>
            </div>
            <button @click="goToNext()" class="float-right w-64 p-2 bg-red-500 text-white mt-12 hover:bg-red-600 focus:ring focus:ring-red-300">AVANTI</button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useSinistroStore } from '@/stores/sinistro';
import { useToast } from 'vue-toast-notification';
import GoogleMapsAddress from '../GoogleMapsAddress.vue';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import 'maz-ui/css/main.css'
import HeaderSinistro from './HeaderSinistro.vue';
import { removeFormattingFromPhoneNumber } from '@/helpers/utils'

const emits = defineEmits(['goNext'])
const sinistro = useSinistroStore()

const isRca = ref(sinistro.policyDataRCA.prodotto !== null);
const isCvt = ref(sinistro.policyDataCVT.prodotto !== null);

const NomeContraente = sinistro.policyDataCVT.nominativoContraente !== '' ? ref(sinistro.policyDataCVT.nominativoContraente) : ref(sinistro.policyDataRCA.nominativoContraente);

const targa = ref(sinistro.policyDataRCA.targa !== '' ? sinistro.policyDataRCA.targa : sinistro.policyDataCVT.targa);
const telaio = ref(sinistro.policyDataRCA.telaio !== '' ? sinistro.policyDataRCA.telaio : sinistro.policyDataCVT.telaio);
const marca = ref(sinistro.policyDataRCA.marca !== '' ? sinistro.policyDataRCA.marca : sinistro.policyDataCVT.marca);
const modello = ref(sinistro.policyDataRCA.modello !== '' ? sinistro.policyDataRCA.modello : sinistro.policyDataCVT.modello);

const placeClaime = ref(sinistro.addressUser.returnedPlace);
const emailContraente = ref(sinistro.policyDataRCA.emailContraente !== '' ? sinistro.policyDataRCA.emailContraente : sinistro.policyDataCVT.emailContraente);
const codiceFiscaleContraente = ref(sinistro.policyDataRCA.codiceFiscaleContraente !== '' ? sinistro.policyDataRCA.codiceFiscaleContraente : sinistro.policyDataCVT.codiceFiscaleContraente);

// -- Date -- //
let t = new Date(sinistro.policyDataRCA.dataAttivazione);
let time = `${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()}`;
const dataAttivazioneRCA = ref(time);

t = new Date(sinistro.policyDataCVT.dataAttivazione);
time = `${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()}`;
const dataAttivazioneCVT = ref(time);

t = new Date(sinistro.policyDataRCA.dataFine);
time = `${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()}`;
const dataFineRCA = ref(time);

t = new Date(sinistro.policyDataCVT.dataFine);
time = `${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()}`;
const dataFineCVT = ref(time);

sinistro.setDatiSinistro({
    "eventDate": sinistro.eventDate,
    "nomeContraente": NomeContraente.value,
    "targa": targa.value,
    "emailContraente": emailContraente.value,
});

onMounted(() => {
    checkIfDarkMode();
})

const toast = useToast();

function goToNext() {
    if (checkInputUser()) {
        sinistro.setDatiSinistro({
            "eventDate": sinistro.eventDate,
            "nomeContraente": NomeContraente.value,
            "targa": targa.value,
            "telaio": telaio.value,
            "telefonoContraente": sinistro.phoneNumber,
            "indirizzoContraente": placeClaime.value.formatted_address,
            "emailContraente": emailContraente.value,
            "codiceFiscaleContraente": codiceFiscaleContraente.value,
        });
        emits('goNext', 3)
    }
}

function getPlace(place) {
    placeClaime.value = place;
}

function checkInputUser() {
    let phoneToCheck = removeFormattingFromPhoneNumber(sinistro.phoneNumber);
    const regexOnlyChars = /[a-zA-Z]/g;
    
    const isGoodPhoneNumber = phoneToCheck != '' && phoneToCheck.length >= 7 && !regexOnlyChars.test(phoneToCheck);
    if (!isGoodPhoneNumber) {
        toast.error('Inserisci un numero di telefono valido.', {
            position: 'top'
        });
        return false
    }
    if (placeClaime.value.formatted_address == '' || !placeClaime.value.formatted_address) {
        toast.error('Inserisci un indirizzo.', {
            position: 'top'
        });
        return false
    }
    if (emailContraente.value == '' || !emailContraente.value.includes("@") || !emailContraente.value.includes(".")) {
        toast.error('Inserisci un indirizzo email valido.', {
            position: 'top'
        });
        return false
    }

    return true
}

// Funzione per cambiare il colore dell'input del telefono
function checkIfDarkMode() {
    const theme = localStorage.getItem('theme');
    let phoneInput_1 = document.querySelector('#phone-input > div.m-select.m-phone-number-input__select > div > div');
    let phoneInput_2 = document.querySelector('.m-phone-number-input');
    let phoneInput_3 = document.querySelector('input.w-96:nth-child(1)');

    if (theme == 'dark') {
        phoneInput_1.style.background = '#27272a';
        phoneInput_2.style.background = '#18181b';
        phoneInput_3.style.background = '#27272a';
    }
}

</script>

<style scoped>

</style>