import * as api from './api'
import * as constants from './constants';
import { EditMechanicPayload } from '@/models/Claims';
import Axios from 'axios';

export const GetPageClaims = async (page: number, perPage: number) =>  {
    const action = api.GetAction(constants.API, `/tcl-dealer/claim?limit=${perPage}&page=${page}`, 'GET')
    const response = await api.CallApi(action);
    return response
};

export const SearchClaims = async (queryStr: string | any) =>  {
    const action = api.GetAction(constants.API, `/tcl-dealer/claim/search`, 'POST', {query: queryStr})
    const response = await api.CallApi(action);
    return response
};

export const GetWipClaimsFilter = async (filter: string) =>  {
    const action = api.GetAction(constants.API, `/tcl-dealer/claim/${filter}`, 'GET')
    const response = await api.CallApi(action);
    return response
};

export const GetFilteredPageClaims = async (page: number, perPage: number, stateOfClaim?: string) =>  {
    if (typeof stateOfClaim == 'undefined') {
        stateOfClaim = 'all';
    }
    
    const action = api.GetAction(constants.API, `/tcl-dealer/claim/${stateOfClaim}?limit=${perPage}&page=${page}`, 'GET')
    const response = await api.CallApi(action);
    return response
};

export const getClaimByClaimId = async (claimId: string) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/claim/getClaim/${claimId}`, 'GET')
    const response = await api.CallApi(action);
    return response
}

export const GetClaimsByDealer = async (userId: string) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/claim/getClaimsByDealer/${userId}`, 'GET')
    const response = await api.CallApi(action);
    return response
}

export const CreateClaim = async (payload: object | any) =>  {
    const action = api.GetAction(constants.API, `/tcl-dealer/claim`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
};

export const GetFile = async (url: string) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/static` + url, 'GET')
    const response = await api.CallApi(action);
    return response
}

export const GetCalculatedCondit = async (payload: object | any) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/condit/calc`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
}

export const CalculateCrystal = async (payload: object | any) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/claim/calculateCrystal`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
}


export const AddDocumentToClaim = async (claimId: string, payload: object | any) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/claim/addDocumentToClaim/${claimId}`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
}

export const GetSystemDiary = async (claimId: string) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/claim/getSystemDiary/${claimId}`, 'GET')
    const response = await api.CallApi(action);
    return response
}

export const EditMechanic = async (claimId: string, payload: EditMechanicPayload) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/claim/editMechanic/${claimId}`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
}

export const DownloadFileHandler = async (URL: string, method: string, fileName: string) => {
    let response;

    switch (method) {
        case "GET":
            response = await Axios.get(URL, {
                responseType: 'blob'
            });
            break
        case "POST":
            response = await Axios.post(URL, {
                responseType: 'blob'
            });
            break
        default:
            response = await Axios.get(URL, {
                responseType: 'blob'
            });
            break
    }

    // Crea un link di download e cliccalo
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}