import { defineStore } from 'pinia'
import * as constants from '../helpers/constants'
import { login2FA, logout, confirmCode2FA } from "@/helpers/auth.service";
import router from '@/router';
import { useToast } from 'vue-toast-notification';
import { useCompanyStore } from '@/stores/company';

const user = JSON.parse(localStorage.getItem(constants.USER_INFO));
const initialState = user 
? { isAuthenticated: true, user: user }
: { isAuthenticated: false, user: null }

export const useSessionStore = defineStore('session', {
    state: () => (
		initialState
	),
    actions: {
        async loginAction(email, password) {
			return await login2FA(email, password);
        },

        async confirmCode(code, encrToken) {
			const resp = await confirmCode2FA(code, encrToken);
			
			if (resp.isOk && resp.data !== null) {
				this.user = resp.data;
				this.isAuthenticated = true;

				const company = useCompanyStore();
				company.initStore();
				company.SetCompanyAction();

				const toast = useToast();
				toast.success('Accesso eseguito correttamente!', {
					position: 'top'
				});

				router.push('/dashboard');
			} else {
				const toast = useToast();
				toast.error('Codice errato.', {
					position: 'top'
				});
			}
        },

        async logoutAction() {
            const isOk = await logout();
			if (isOk) {

			}
			this.isAuthenticated = false;
			this.user = null;
			router.push('/login');
        },
		gotoLogin() {
			this.isAuthenticated = false;
			this.user = null;
			router.push('/login');
		}
    }
});
