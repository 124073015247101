import axios from 'axios';
import * as constants from './constants';
import { useSessionStore } from '@/stores/session';
// TODO L'utente andrebbe inizializzato 
// const defaultState = {
//     userId: null,
//     fullName: null,
//     token: null,
//     isLoggedId: false
// };
// const userInfo = localStorage.getItem('USER_INFO');
// const INITIAL_STATE = userInfo ? JSON.parse(userInfo) : defaultState;

const GetToken = () => {
    let user = JSON.parse(localStorage.getItem(constants.USER_INFO));

    if (user && user.access_token) {
        return `Bearer ${user.access_token}`;
    } else return false
}

export const GetAction = (type, url, method, data) => {
    return {
        type: type,
        payload: {
            url: url,
            method: method,
            data: data
        }
    }
};

export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return jsonPayload;
};


export const GetUserRoles = () => {
    return JSON.parse(localStorage.getItem(constants.USER_TOKEN_PAYLOAD));
};

export const RemoveToken = () => {
    localStorage.removeItem(constants.USER_INFO);
    localStorage.removeItem(constants.USER_TOKEN_PAYLOAD);
    localStorage.removeItem(constants.COMPANY_NAME);
}

export const AddUserToken = (userData) => {
    localStorage.setItem(constants.USER_INFO, JSON.stringify(userData));
    localStorage.setItem(constants.USER_TOKEN_PAYLOAD, parseJwt(userData.access_token));
}

export const CallApi = (action) => {
    
    if (action.type === constants.API) {
        const { url, method, data } = action.payload;
        //axios.defaults.headers.common["Content-type"] = "application/json";
        const token = GetToken()
        if (token) {
            axios.defaults.headers.common['Authorization'] = token;
        }

        //TODO QUI SI PUO ACCENDERE UN LOADING
        return axios({
            method,
            url: constants.BASE_URL + url,
            data: data ? data : null,
        }).then((response)=>{
            //TODO QUI SI PUO SPEGNERE UN LOADING
            return response
        }).catch(err => {
            //TODO QUI SI PUO SPEGNERE UN LOADING

            //console.log(err.response)
            if (!err.response) {console.warn(err);}
            else {
                // GESTIONE NON AUTORIZZATO TOKEN SCADUTO 401 
                //TODO VA MESSO IL POPUP PER TOKEN SCADUTO
                if (err.response && err.response.status === 401) 
                {
                    RemoveToken();
                    const store = useSessionStore();
                    store.gotoLogin();
                }
                //console.log(err.response.status)
                    
            }
            return err
        });
    }

    if (action.type === constants.API_FILE_UPLOAD) {

        const { url, method, data } = action.payload;
        //console.log("payload", action.payload);

        //axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const token = GetToken()
        if (token) {
            axios.defaults.headers.common['Authorization'] = token;
        }

        const formData = new FormData(); 
        //console.log("ct", axios.defaults.headers.common["Content-type"]);
        
        // Update the formData object 
        formData.append(
            "file",
            data.file,
            data.file.name
        ); 

        //TODO QUI SI PUO ACCENDERE UN LOADING
        return axios({
            method,
            url: constants.BASE_URL + url,
            data: formData,
        }).then((response)=>{
            //TODO QUI SI PUO SPEGNERE UN LOADING
            return response
        }).catch(err => {
            //TODO QUI SI PUO SPEGNERE UN LOADING

            console.log(err.response)
            if (!err.response) {console.warn(err);}
            else {
                // GESTIONE NON AUTORIZZATO TOKEN SCADUTO 401 
                //TODO VA MESSO IL POPUP PER TOKEN SCADUTO
                if (err.response && err.response.status === 401) 
                {
                    RemoveToken();
                    const store = useSessionStore();
                    store.gotoLogin();
                }
                //console.log(err.response.status)
                    
            }
            return err
        });
    }
};