<template>
    <div class="flex justify-around h-screen">
        <div class="self-center hidden lg:block">
            <!-- <img class="w-40 ml-20" src="../assets/logo/logo-removebg.png" /> -->
            <img class="w-96" src="../assets/svg/login.svg" />
            <img id="grafica-triangolo-grigio-scuro" src="../assets/svg/triangolo_login.svg"/>
            <img id="grafica-ovale-grigio-chiaro" src="../assets/svg/ovale_login.svg"/>
        </div>
        <div v-if="step == 1" class="mt-12 self-center lg:mr-64">
            <img class="w-40" src="../assets/logo/logo-removebg.png" />
            <h1 class="text-xl font-bold">Ciao! Bentornato nel <br><span class="text-red-600">Portale Concessionari</span>.</h1>
            <p class="w-64 opacity-60">Esegui il login per accedere al portale concessionari</p>
            
            <form @submit.prevent="submitFormCredentials">     
                <div class="email input-container">
                    <p class="mb-2 mt-6">Email</p>
                    <input class="input w-64 p-2" placeholder="Inserisci la email" type="email" v-model="email" />
                </div>
                <div class="password input-container">
                    <p class="mb-2 mt-6">Password</p>
                    <input class="input w-64 p-2" placeholder="Inserisci la password" type="password" v-model="password" />
                </div>

                <div class="flex flex-col">
                    <button class="mt-10 p-3 w-64 text-white cursor-pointer hover:bg-red-700 focus:ring focus:ring-red-300 bg-red-600" type="submit">ACCEDI</button>
                    <!--<button @click="handleIQuote" class="mt-5 p-3 w-64 cursor-pointer hover:bg-zinc-200 active:bg-zinc-600 active:text-white focus:ring focus:ring-zinc-300 border border-black" type="button">Accedi tramite iQuote</button>-->
                </div>
            </form>
        </div>
        <div v-if="step == 2" class="mt-12 self-center lg:mr-64">
            <img class="w-40" src="../assets/logo/logo-removebg.png" />
            <h1 class="text-xl font-bold">Autenticazione<br><span class="text-red-600">2FA</span>.</h1>
            <p class="w-64 opacity-60">Inserisci il codice di verifica che abbiamo inviato all'indirizzo email {{ censuredEmail }}</p>
           
            <form @submit.prevent="submitFormOTP">     
                <div class="email input-container mb-2 mt-6">
                    <input class="input w-64 p-2" placeholder="Inserisci il codice" type="text" v-model="code" />
                </div>

                <div class="flex flex-col">
                    <button class="mt-10 p-3 w-64 text-white cursor-pointer hover:bg-red-700 focus:ring focus:ring-red-300 bg-red-600" type="submit">ACCEDI</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { useSessionStore } from '@/stores/session.js';
    import { useToast } from 'vue-toast-notification';

    const step = ref(1);
    const email = ref('');
    const password = ref('');
    const censuredEmail = ref('');
    const encrToken = ref('');
    const code = ref('');

    const store = useSessionStore();

    async function submitFormCredentials() {
        if (checkInputOk(email.value, password.value)) {
            const response = await store.loginAction(email.value, password.value);

            if (response.isOk && response.data !== null && response.data !== undefined) {
				step.value = 2;
                censuredEmail.value = censureEmail(email.value);
                encrToken.value = response.data;
                
			} else {
                const toast = useToast();
                const errorMessage = response?.response?.response?.data?.message;

                if (errorMessage && errorMessage != "") {
                    toast.error(errorMessage, {
                        position: 'top'
                    });
                } else {   
                    toast.error('Credenziali errate.', {
                        position: 'top'
                    });
                }

                step.value = 1;
			}
        }
    }

    function checkInputOk(email, password) {
        const toast = useToast();

        if (!email || email == "") {
            toast.error('Inserisci un indirizzo email', {
                position: 'top'
            });

            return false
        }

        if (!password || password == "") {
            toast.error('Inserisci una password', {
                position: 'top'
            });

            return false
        }

        if (email.length > 100) {
            toast.error('Email troppo grande, limite massimo 100 caratteri', {
                position: 'top'
            });

            return false
        }

        if (password.length > 100) {
            toast.error('Password troppo grande, limite massimo 100 caratteri', {
                position: 'top'
            });

            return false
        }

        return true
    }

    async function submitFormOTP() {
        if (code.value.length == 6) {
            const response = await store.confirmCode(code.value, encrToken.value);
            return
        }

        const toast = useToast();
        toast.info('Il codice deve essere lungo 6 caratteri.', {
            position: 'top'
        });
    }

    // function handleIQuote() {
    //     const toast = useToast();
    //     toast.default('Accedi tramite iQuote momentaneamente fuori servizio.', {
    //         position: 'top'
    //     });
    // }

    function censureEmail(email) {
        let censuredEmail = '';
        let indexStopCensure = email.indexOf("@");

        for (let i = 0; i < email.length; i++) {
            if (i > 2 && i < indexStopCensure) {
                censuredEmail += '*';
                continue;
            }

            censuredEmail += email[i];
        }

        return censuredEmail;
    }
    
</script>

<style scoped>
    #grafica-triangolo-grigio-scuro {
        position: absolute;
        z-index: -10;
        top: -4rem;
        left: -40rem;
    }

    #grafica-ovale-grigio-chiaro {
        position: absolute;
        z-index: -20;
        top: -30rem;
        left: -36rem;
    }
</style>