<template>
    <loading 
        v-model:active="isLoading" 
        :is-full-page=true 
        loader="spinner" 
        color="red" />
    <div>
        <div class="my-12 flex flex-col md:flex-row md:divide-x divide-dashed divide-gray-300 justify-center">
            <div class="px-3 md:px-0 md:mr-12">
                <div id="danno" class="mt-12">
                    <h1 class="text-2xl font-bold w-50 mb-6">Seleziona la <span class="text-red-600">tipologia di danno</span> {{ $t('infoGenerali.warranty.title3') }}.</h1>
                    <div class="shadow-xl h-fit p-6">
                        <div class="divide-y divide-black cursor-pointer dark:divide-gray-400 dark:bg-zinc-800">
                            <div class="divide-y divide-black cursor-pointer dark:divide-gray-400 dark:bg-zinc-800">
                                <div v-for="product in ['Danni da circolazione (RCA)', 'Danni da stazionamento (CVT)']" @click="handleProduct(product)" :style="[selectedProduct == product && {backgroundColor: '#DC2626', color: 'white'}, product == 'Danni da circolazione (RCA)' && !isRca && { opacity: '32%', cursor: 'not-allowed' }, product == 'Danni da stazionamento (CVT)' && !isCvt && { opacity: '32%', cursor: 'not-allowed' }]" class="flex justify-between p-2 hover:bg-gray-200 dark:hover:bg-zinc-700 active:bg-gray-400 active:text-white">
                                    <div class="flex w-full justify-between">
                                        <p>{{product}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectedProduct !== ''" class="px-3 md:px-0 md:pl-12">
                <div id="garanzia" class="mt-12 w-96">
                    <h1 class="text-2xl font-bold w-50 mb-6">Seleziona la <span class="text-red-600">garanzia</span> {{ $t('infoGenerali.warranty.title3') }}.</h1>
                    <div class="shadow-xl h-fit p-6">
                        <div class="divide-y divide-black cursor-pointer dark:divide-gray-400 dark:bg-zinc-800">
                            <div v-for="garanzia in listOfGaranzie" class="">
                                <div @click="handleGaranzia(garanzia)" class="flex justify-between w-full p-2 hover:bg-gray-200 dark:hover:bg-zinc-700 active:bg-gray-400 active:text-white" :style="[selectedGaranzia == garanzia.code && {backgroundColor: '#DC2626', color: 'white'}, !garanzia.isActive && {opacity: '32%', cursor: 'not-allowed'}]">
                                    <p>{{garanzia.name}}</p>
                                    <span class="material-symbols-outlined">{{garanzia.image}}</span>
                                </div>
                                <div v-if="selectedGaranzia === 'calamitaNaturali' && garanzia.code == 'calamitaNaturali'" class="my-2 pl-6 divide-y divide-black cursor-pointer dark:divide-gray-400 dark:bg-zinc-800">
                                    <p @click="sinistro.setSelectedParameterActOfGod('HAIL_STORM')" class="p-2 hover:bg-gray-200" :style="[sinistro.selectedParameterActOfGod == 'HAIL_STORM' && {backgroundColor: '#DC2626', color: 'white'}]">Grandine</p>
                                    <p @click="sinistro.setSelectedParameterActOfGod('OTHER')" class="p-2 hover:bg-gray-200" :style="[sinistro.selectedParameterActOfGod == 'OTHER' && {backgroundColor: '#DC2626', color: 'white'}]">Altro</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full mb-6">
            <div class="flex justify-between mx-12 md:mx-48 lg:mx-80 xl:justify-around 2xl:mx-[36rem]">
                <button @click="$emit('goBack', 2)" class="w-40 mx-2 p-2 mt-6 bg-gray-500 text-white hover:bg-gray-600 focus:ring focus:ring-gray-300">INDIETRO</button>
                <button @click="goToNext()" class="w-40 mx-2 p-2 mt-6 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300">AVANTI</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import { storeToRefs } from 'pinia';
    import { useSinistroStore } from '@/stores/sinistro';
    import { useToast } from 'vue-toast-notification';
    import Loading from 'vue-loading-overlay';

    const emits = defineEmits(['goNext', 'goBack']);
    const sinistro = useSinistroStore();

    const { selectedParameterActOfGod } = storeToRefs(sinistro);
    const isLoading = ref(false);
    const listOfGaranzie = ref([]);

    const selectedGaranziaObj = ref({});
    const selectedGaranzia = ref('');
    const selectedProduct = ref('');

    const isRca = ref(sinistro.policyDataRCA.prodotto !== null);
    const isCvt = ref(sinistro.policyDataCVT.prodotto !== null);
    
    onMounted(() => {
        if (isRca.value && !isCvt.value) {
            handleProduct('Danni da circolazione (RCA)');
            return
        }

        if (!isRca.value && isCvt.value) {
            handleProduct('Danni da stazionamento (CVT)');
            return
        }

        return
    });
    
    function handleGaranzia(garanzia) {
        if (garanzia.isActive) {
            selectedGaranzia.value = garanzia.code;
            selectedGaranziaObj.value = garanzia;

            sinistro.setSelectedParameterActOfGod('');
            sinistro.setSelectedGaranzia(garanzia);

            return
        }

        selectedGaranzia.value = '';
        sinistro.setSelectedGaranzia({});
        selectedGaranziaObj.value = {};
        sinistro.setSelectedParameterActOfGod('');

        toast.info(garanzia.guidelines.replace('{{GARANZIA}}', garanzia.name), {
            position: 'top',
            timeOut: 15000,
        });
    }

    async function goToNext(){
        if (checkInputUser()) {
            const isOkStore = await sinistro.getDocsFromGaranziaAperturaAction(selectedGaranzia.value);
            if (isOkStore) {
                emits('goNext', 4)
                return 
            }
            
            toast.error("documenti non trovati per " + selectedGaranzia.value, {
                position: 'top',
                timeOut: 15000,
                
            });
        }
    }

    const toast = useToast();

    function checkInputUser() {
        const isCalamitaNaturaliAndIsEmpty = selectedGaranzia.value == 'calamitaNaturali' && selectedParameterActOfGod.value == '';
        if (isCalamitaNaturaliAndIsEmpty) {
            toast.error('Seleziona la tipologia di calamità.', {
                position: 'top'
            });
            return false
        }

        if (selectedGaranzia.value == '') {
            toast.error('Seleziona una garanzia.', {
                    position: 'top'
                });
            return false
        }

        return true
    }

    function setListOfGaranzie() {
        if (sinistro.selectedProduct === 'Danni da circolazione (RCA)') {
            listOfGaranzie.value = sinistro.rcaGuarantees;
        }
        
        if (sinistro.selectedProduct === 'Danni da stazionamento (CVT)') {
            listOfGaranzie.value = sinistro.cvtGuarantees;
        }
    }

    function handleProduct(product) {
        selectedProduct.value = product;
        sinistro.selectedProduct = product;

        const isAllowedRca = (product == 'Danni da circolazione (RCA)' && isRca.value)
        const isAllowedCvt = (product == 'Danni da stazionamento (CVT)' && isCvt.value)

        const isSelectedRcaAndIsNotAllowed = (product == 'Danni da circolazione (RCA)' && !isAllowedRca)
        const isSelectedCvtAndIsNotAllowed = (product == 'Danni da stazionamento (CVT)' && !isAllowedCvt)
        
        if (isSelectedRcaAndIsNotAllowed || isSelectedCvtAndIsNotAllowed) {
            selectedProduct.value = '';
            sinistro.selectedProduct = '';

            return
        }

        setListOfGaranzie();
    }
</script>

<style scoped>
</style>