<template>
    <div>
        <CriterioCondizioni
            question="Rimpiazza o non rimpiazza?"
            :options="['rimpiazza', 'non rimpiazza']"
            @send-value="(value: string) => setIsReplaceAndAskForRestartIfAvailable(value)"
        />

        <CriterioCondizioni v-if="isAskingRestart" 
            question="Restart?"
            :options="['si', 'no']"
            @send-value="(value: string) => setIsRestartSelected(value)"
        />
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import CriterioCondizioni from '../CriterioCondizioni.vue';
    import { useSinistroStore } from '@/stores/sinistro';
    import { storeToRefs } from 'pinia';

    const emits = defineEmits(['changeStepAndCalculate']);
    const props = defineProps({
        selectedGaranziaCode: String,
        ods: Object,
    });

    const sinistro = useSinistroStore();
    const { isRestart10, isRestart20, isRestartSelected, otherDetails } = storeToRefs(sinistro);

    const isReplace = ref(false);
    const isAskingRestart = ref(false);

    function setIsReplaceAndAskForRestartIfAvailable(value: string) {
        isReplace.value = value == 'rimpiazza' ? true : false;
        
        if (isReplace.value) {
            askForRestartIfAvailable();
            return
        }

        setIsRestartSelected('no');
    }

    function setIsRestartSelected(value: string) {
        isRestartSelected.value = value == 'si' ? true : false;
        isAskingRestart.value = false;

        otherDetails.value.furtoTotaleIncendioDetails.isRestartSelected = isRestartSelected.value;
        otherDetails.value.furtoTotaleIncendioDetails.isReplace = isReplace.value;
        otherDetails.value.furtoTotaleIncendioDetails.isRestart10 = isRestart10.value;
        otherDetails.value.furtoTotaleIncendioDetails.isRestart20 = isRestart20.value;
        
        emits('changeStepAndCalculate');
    }

    function askForRestartIfAvailable() {
        for (let obj in props.ods) {
            
            const guarantee = props.ods[obj];
            const guaranteeExists = guarantee && guarantee.length > 0;
            
            if (guaranteeExists) {
                isRestart10.value = guarantee[0].restart10;
                isRestart20.value = guarantee[0].restart20;
                
                if (isRestart10.value || isRestart20.value) {
                    isAskingRestart.value = true;
                    return
                }
            }
        }
        
        setIsRestartSelected('no');
    }

</script>

<style scoped>

</style>