<template>
    <div class="pt-24 absolute">
        <div :style="[showModal && {opacity: 1, visibility: 'visible', transition: 'all 0.15s linear'}]" class="modal fixed top-0 left-0 w-full h-full backdrop-blur opacity-0">
            <div class="modal-content absolute top-1/2 left-1/2 bg-white shadow-2xl py-4 px-6 min-w-[50%] w-fit rounded">
                <div class="flex mb-4">
                    <span @click="$emit('closeModal')" class="material-symbols-outlined float-right cursor-pointer rounded-full">close</span>
                </div>
                <div class="p-2">
                    <p>Sei sicuro di voler inviare il sinistro?</p>
                </div>
                <button @click="$emit('closeModal')" class="w-24 md:w-40 p-2 mt-12 md:mr-16 border-solid border-black hover:bg-red-500 active:bg-red-600 active:text-white active:border-red-600">Chiudi</button>
                <button @click="$emit('sendClaim')" class="w-24 md:w-40 p-2 bg-red-500 text-white float-right mt-12 hover:bg-red-600 focus:ring focus:ring-red-300">Conferma</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const emits = defineEmits(['closeModal', 'sendClaim']);
    const showModal = ref(true);

    function windowOnClick(event) {
        let modal = document.querySelector(".modal");

        if(event.target === modal) {
            emits('closeModal')
        }
    }
    window.addEventListener("click", windowOnClick);
</script>

<style scoped>
    .modal {
        transition: all 0.15s linear;
        visibility: hidden;
    }
    .show-modal {
        opacity: 1;
        visibility: visible !important;
        transition: all 0.15s linear;
    }
    .modal-content {
        transform: translate(-50%, -50%);
    }
</style>