<template>
    <div class="w-full h-screen bg-slate-100 dark:bg-zinc-900 pt-24">
        <div class="flex justify-center">
            <div class="rounded shadow flex flex-col justify-center bg-white p-4 px-12 w-[32rem] md:w-[48rem] xl:w-[64rem]">
                <ProfileComponent />
                <div class="flex justify-center my-3">
                    <div class="flex">
                        <div @click="handleTab('iban')" :style="[whichTab == 'iban' && {backgroundColor: '#ef4444', color: 'white'}]" class="flex items-center cursor-pointer rounded hover:bg-gray-200 mx-2 p-2 px-3">
                            <span class="material-symbols-outlined mr-2">account_balance</span>
                            <p>Iban</p>
                        </div>
                        <hr />
                        <div @click="handleTab('accordo_quadro')" :style="[whichTab == 'accordo_quadro' && {backgroundColor: '#ef4444', color: 'white'}]" class="flex items-center cursor-pointer rounded hover:bg-gray-200 mx-2 p-2 px-3">
                            <span class="material-symbols-outlined mr-2">details</span>
                            <p>Accordo quadro</p>
                        </div>
                    </div>
                </div>
                <div class="my-12">
                    <IbanComponent v-if="whichTab == 'iban'" />
                    <AccordoComponent v-if="whichTab == 'accordo_quadro'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import ProfileComponent from '@/components/account/ProfileComponent.vue'
    import IbanComponent from '@/components/account/IbanComponent.vue'
    import AccordoComponent from '@/components/new_road_accident/dealer_agreement/AgreementComponent.vue'

    import { ref } from 'vue';

    const whichTab = ref('profile');
    const dealerAgreement = ref({});

    function handleTab(tab) {
        whichTab.value = tab
    }
</script>

<style>

</style>