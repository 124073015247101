<template>
    <div class="z-50 rounded shadow-lg p-6 fixed right-2 bottom-2 bg-white border border-slate-300 w-[32rem] h-4/5 overflow-scroll">
        <div>
            <GenericInputModal v-if="showModal"
                title="Inserisci il titolo della richiesta"
                @close-modal="showModal = false"
                @confirm="(name) => startTicket(name)"
            />

            <div v-if="!isStartedTicket">
                <span @click="$emit('closePopup')" class="cursor-pointer material-symbols-outlined absolute right-2 top-2">close</span>
                <div class="flex flex-col items-center">
                    <div class="mb-6 flex flex-col items-center">
                        <img class="w-64 lg:w-128" src="@/assets/svg/messages_not_found.svg" />
                        <p>Complimenti! Non hai nessuna richiesta.</p>
                    </div>
                    <button @click="showModal = true" class="w-64 h-12 rounded mt-2 p-2 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300 flex justify-center items-center">
                        <span class="material-symbols-outlined">add</span>Nuova richiesta
                    </button>
                </div>
            </div>
            <div v-else class="mx-3">
                <div v-if="!isChat">
                    <div class="flex items-center justify-between">
                        <h1 class="text-2xl font-bold mb-2">Lista richieste</h1>
                        <span @click="$emit('closePopup')" class="cursor-pointer material-symbols-outlined">close</span>
                    </div>

                    <div class="flex justify-between items-center">
                        <button @click="showModal = true" class="w-64 h-12 rounded mt-2 p-2 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300 flex justify-center items-center">
                            <span class="material-symbols-outlined">add</span>Nuova richiesta
                        </button>
                    </div>

                    <!-- Lista tickets -->
                    <div v-for="(ticket, index) in tickets" v-show="!ticket.isDone" class="group p-4 my-6 rounded shadow cursor-pointer hover:shadow-md" :key="index">
                        <div @click="handleChat(ticket)" class="flex items-center justify-between">
                            <div>
                                <h1 class="text-xl font-semibold">{{ ticket.name }}</h1>
                                <p v-if="ticket.messages[Object.keys(ticket.messages).length - 1]" class="py-1 text-sm text-gray-500">{{ticket.messages[Object.keys(ticket.messages).length - 1].user.firstName}} {{ticket.messages[Object.keys(ticket.messages).length - 1].user.lastName}}: {{ticket.messages[Object.keys(ticket.messages).length - 1].message !== '' ? ticket.messages[Object.keys(ticket.messages).length - 1].message : 'Richiesta file'}}</p>
                                <p v-else class="py-1 text-sm text-gray-500">Ancora nessun messaggio</p>
                            </div>
                            <button @click="handleChat(ticket)" class="hidden group-hover:block h-12 mx-2 rounded p-2 px-4 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300">
                                <div class="flex items-center">
                                    <p class="text-sm">Apri</p>
                                </div>
                            </button>
                        </div>
                    </div>
                    <!-- Fine lista tickets -->
                </div>

                <!-- Chat -->
                <div v-else>
                    <div class="flex justify-start items-center fixed bg-white py-6 pb-3 -my-6">
                        <div class="w-[26rem] flex items-center">
                            <span @click="isChat = false" class="cursor-pointer material-symbols-outlined">arrow_back</span>
                            <h1 class="text-2xl font-bold ml-4 mb-2 truncate">{{ ticket.name }}</h1>
                        </div>
                        <span @click="$emit('closePopup')" class="cursor-pointer material-symbols-outlined">close</span>
                    </div>

                    <ChatInterfacePopup
                        class="pt-12"
                        :ticketProp="ticket"
                        :claimId="claimId"
                    />
                </div>
                <!-- Fine chat -->
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, watch, onMounted, onUnmounted } from 'vue';
    import GenericInputModal from '@/components/GenericInputModal.vue';
    import ChatInterfacePopup from '@/components/messages/ChatInterfacePopup.vue';

    import { CreateTicket, GetTickets, GetMessages } from '@/helpers/messages.service.js';
    import { BLOCK_PERIODIC_GET_CLAIM } from '@/helpers/constants';

    const claimId = ref('');
    const tickets = ref([]);
    const ticket = ref({});
    const isStartedTicket = ref(false);
    const isChat = ref(false);
    const showModal = ref(false);

    const props = defineProps({
        claimId: String,
    });

    const emits = defineEmits(['closePopup']);

    onMounted(async () => {
        claimId.value = props.claimId;
        await getTickets(claimId.value);

        if (tickets.value?.length > 0) {
            handleChat(tickets.value[tickets.value.length - 1]);
        }

        !BLOCK_PERIODIC_GET_CLAIM && getMessagesEvery2Seconds();
    })

    onUnmounted(() => {
        !BLOCK_PERIODIC_GET_CLAIM && clearInterval(intervalId);
    });

    watch(props, () => {
        claimId.value = props.claimId;
        getTickets(claimId.value);
    });

    async function getTickets(claimId) {
        await GetTickets(claimId)
        .then(resp => {
            if (resp.status == 200) {
                tickets.value = resp.data.tickets;

                if (tickets.value) {
                    if (tickets.value.length > 0) {
                        isStartedTicket.value = true;
                    }
                }
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

    function handleChat(ticketObj) {
        isChat.value = true;

        const index = tickets.value.indexOf(ticketObj);
        if (index !== -1) {
            ticket.value = tickets.value[index];
        }
    }

    async function startTicket(name) {
        showModal.value = false;

        const payload = {
            'name': name,
        };

        await CreateTicket(props.claimId, payload).then(resp => {
            const statusCode = resp.status;
            if (statusCode === 201) {
                getTickets(props.claimId);
            }
        }).catch(err => {
            const toast = useToast();
            toast.error("C'è stato un problema, riprova.", {
                position: 'top',
            })
            console.log(err);
        });
    }

    async function getMessages() {
        if (!isChat.value) {
            return
        }

        const resp = await GetMessages(ticket.value._id);
        if (resp.data) {
            ticket.value.messages = resp.data.messages;
        }
    }

    let intervalId;
    function getMessagesEvery2Seconds() {
        intervalId = setInterval(() => {
            getMessages();
        }, 5000);
    }

</script>

<style scoped>

</style>
