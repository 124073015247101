<template>
    <div class="flex justify-between">
        <div class="hidden" :style="[isSent && {display: 'block'}]"></div>
        <div class="flex items-end mb-6">
            <div v-if="!isSent" class="font-300 bg-gray-200 rounded-full mx-2 mt-4 p-2 px-3">
				<p>{{initials}}</p>
			</div>
            <div>
                <div class="flex text-sm">
                    <p class="mr-2">{{ owner }}</p>
                    <p class="text-zinc-400">{{ formattedData }} 🕒</p>
                </div>
                <div class="bg-zinc-100 dark:bg-zinc-700 rounded p-2">
                    <p class="max-w-xs">{{ message }}</p>
                </div>
            </div>
            <div v-if="isSent" class="font-300 bg-gray-200 rounded-full mx-2 mt-4 p-2 px-3">
				<p>{{initials}}</p>
			</div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        isSent: Boolean,
        owner: String,
        time: String,
        message: String,
        initials: String,
    })

    const t = new Date(props.time);
    let time = `${t.getHours()}:${t.getMinutes()} • ${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()}`;

    const formattedData = ref(time);
</script>

<style scoped>

</style>