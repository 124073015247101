<template>
    <div class="pt-24 absolute">
        <div :style="[showModal && {opacity: 1, visibility: 'visible', transition: 'all 0.15s linear'}]" class="modal fixed top-0 left-0 w-full h-full backdrop-blur opacity-0">
            <div class="modal-content absolute top-1/2 left-1/2 bg-white shadow-2xl py-4 px-6 min-w-[50%] w-fit rounded">
                <div class="flex mb-4">
                    <span @click="$emit('closeModal')" class="material-symbols-outlined float-right cursor-pointer rounded-full">close</span>
                </div>
                <div class="p-2">
                    <h1 class="text-2xl">{{ title }}</h1>
                    <input ref="input" type="text" v-model="text" :placeholder="title" class="p-2 mb-2 w-full outline-none" />
                </div>
                <div class="flex justify-between">
                    <button @click="$emit('closeModal')" class="w-40 p-2 mt-12">Chiudi</button>
                    <button @click="$emit('confirm', text)" class="bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300 rounded mt-12 w-40">Conferma</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, nextTick } from 'vue';

    const emit = defineEmits(['confirm', 'closeModal']);
    const props = defineProps({
        title: String,
    });

    const showModal = ref(true);
    const input = ref(null);
    const text = ref('');

    function windowOnClick(event) {
        let modal = document.querySelector(".modal");

        if(event.target === modal) {
            emit('closeModal')
        }
    }

    nextTick(() => {
        input.value.focus();
    })

    window.addEventListener("click", windowOnClick);
</script>

<style scoped>
    .modal {
        transition: all 0.15s linear;
        visibility: hidden;
    }

    .modal-content {
        transform: translate(-50%, -50%);
    }
</style>