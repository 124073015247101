import * as api from './api'
import * as constants from './constants';


export const getGuaranteesByPlateNumber = async (plateNumber, eventDate, isTaxi) =>  {
    const action = api.GetAction(constants.API, '/tcl-dealer/company/getGuaranteesByPlateNumber', 'POST', {plateNumber: plateNumber, eventDate: eventDate, isTaxi: isTaxi})
    const response = await api.CallApi(action);
    return response
};

export const getGoogleAddress = async (address) =>  {
    const action = api.GetAction(constants.API, '/tcl-dealer/claim/getGoogleAddress', 'POST', {address: address})
    const response = await api.CallApi(action);
    return response
};

export const getDocsFromGaranzia = async (fase, nomeGaranzia) =>  {
    const action = api.GetAction(constants.API, '/tcl-dealer/company/getDocsFromGaranzia', 'POST', {'fase': fase, 'nomeGaranzia': nomeGaranzia})
    const response = await api.CallApi(action);
    return response
};

export const getDocsFromGaranziaApertura = async (nomeGaranzia) =>  {
    return getDocsFromGaranzia('apertura', nomeGaranzia)
};

export const getDocsFromGaranziaLiquidazione = async (nomeGaranzia) =>  {
    return getDocsFromGaranzia('liquidazione', nomeGaranzia)
};