<template>
    <div v-if="showProfileCard" @mouseleave="$emit('toggleProfileCard', false)" class="w-80 absolute z-10 right-12 bg-white p-3 rounded-md shadow-2xl divide-y divide-solid dark:bg-zinc-800 dark:text-white">
        <template v-if="!showLanguages">
            <RouterLink to="/account">
                <div class="cursor-pointer hover:bg-gray-200 rounded-md p-2 pb-4 dark:hover:bg-zinc-900">
                    <div class="flex justify-center">
                        <div class="text-l font-300 bg-gray-200 rounded-full cursor-pointer mr-2 dark:bg-zinc-700">
                            <p class="pt-2 pb-2.5 px-3">{{userProfile.iniziali}}</p>
                        </div>
                        <div>
                            <h1>{{userProfile.fullName}}</h1>
                            <p class="text-sm text-gray-600 dark:text-gray-300 font-thin">{{userProfile.email}}</p>
                        </div>
                        <span class="material-symbols-outlined mt-auto mb-auto ml-2">unfold_more</span>
                    </div>
                    <p class="mt-2 -mb-2 text-center py-2 bg-zinc-100 rounded dark:bg-zinc-700">{{userProfile.companyName}}</p>
                </div>
            </RouterLink>
            <div class="mt-5">
                <div v-if="themeVar == 'light'" @click="$emit('handleTheme', 'dark')" class="flex cursor-pointer mt-2 p-2 rounded-md hover:bg-gray-200 active:bg-red-600 active:text-white dark:hover:bg-zinc-900">
                    <span class="material-symbols-outlined mr-2">dark_mode</span>
                    <p class="text-l font-300 cursor-pointer">{{ $t("darkMode") }}</p>
                </div>
                <div v-if="themeVar == 'dark'" @click="$emit('handleTheme', 'light')" class="flex cursor-pointer mt-2 p-2 rounded-md hover:bg-gray-200 active:bg-red-600 active:text-white dark:hover:bg-zinc-900">
                    <span class="material-symbols-outlined mr-2">light_mode</span>
                    <p class="text-l font-300 cursor-pointer">{{ $t("lightMode") }}</p>
                </div>
            </div>
            <!-- <div class="mt-2 pt-2">
                <div @click="handleShowLanguages" class="flex cursor-pointer align-center p-2 rounded-md hover:bg-gray-200 active:bg-red-600 active:text-white dark:hover:bg-zinc-900">
                    <span class="material-symbols-outlined mr-2">language</span>
                    <p class="text-l font-300 cursor-pointer">Lingua</p>
                </div>
            </div> -->
            <div class="mt-2 pt-2">
                <a href="/files/manuale.pdf" download="manuale.pdf">
                    <div class="flex cursor-pointer align-center p-2 rounded-md hover:bg-gray-200 active:bg-red-600 active:text-white dark:hover:bg-zinc-900">
                        <span class="material-symbols-outlined mr-2">developer_guide</span>
                        <p class="text-l font-300 cursor-pointer">Manuale</p>
                    </div>
                </a>
            </div>
            <div class="mt-2 pt-2">
                <div @click="handleLogout" class="flex cursor-pointer align-center p-2 rounded-md hover:bg-gray-200 active:bg-red-600 active:text-white dark:hover:bg-zinc-900">
                    <span class="material-symbols-outlined mr-2">power_rounded</span>
                    <p class="text-l font-300 cursor-pointer">Log out</p>
                </div>
            </div>
        </template>

        <div id="linguaContainer" class="" v-if="showLanguages">
            <div @click="showLanguages = false" class="flex content-center cursor-pointer mt-2 p-2 rounded-md hover:bg-gray-200 active:bg-red-600 active:text-white dark:hover:bg-zinc-900">
                <span class="material-symbols-outlined mr-2">arrow_back</span>
                <p>Indietro</p>
            </div>
            <div>
                <ul class="divide-y">
                    <div @click="$i18n.locale = 'it'" class="flex content-center cursor-pointer mt-2 p-2 rounded-md hover:bg-gray-200 active:bg-red-600 active:text-white dark:hover:bg-zinc-900">
                        <img src="../assets/images/flags/italy.png" />
                        <li class="">Italiano</li>
                    </div>
                    <div @click="$i18n.locale = 'en'" class="flex content-center cursor-pointer mt-2 p-2 rounded-md hover:bg-gray-200 active:bg-red-600 active:text-white dark:hover:bg-zinc-900">
                        <img src="../assets/images/flags/united_states.png" />
                        <li class="">Inglese</li>
                    </div>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { storeToRefs } from 'pinia';
    import { useLanguageStore } from '../stores/language.js'
    import { watch, ref } from 'vue';
    import { useSessionStore } from '../stores/session.js'

    const props = defineProps({
        show_profile_card: Boolean,
        user_profile: Object,
        theme: String
    })

    const emits = defineEmits(["handleTheme", "toggleProfileCard"]);

    const store = useSessionStore();
    const showProfileCard = ref(false);
    const userProfile = ref({});
    const themeVar = ref('');
    
    const showLanguages = ref(false);
    const storeLanguage = useLanguageStore();

    watch(props, () => {
        showProfileCard.value = props.show_profile_card;
        userProfile.value = props.user_profile;
        themeVar.value = props.theme;
    })


    function handleLogout(){
		showProfileCard.value = false;
        emits('toggleProfileCard', false);
		store.logoutAction();
	};

    function handleShowLanguages() {
        showLanguages.value = !showLanguages.value;
    }

    function changeLanguage(lan) {
        storeLanguage.changeLanguageAction(lan);
        console.log($i18n.locale)
    }

    function downloadDocHandler() {
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", "@/assets/docs/manuale.pdf"]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "manuale.pdf";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

</script>

<style scoped>
    img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
    }
</style>