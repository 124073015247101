import * as api from './api'
import * as constants from './constants';

export const CreateTicket = async (claimId, payload) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/messages/${claimId}/createTicket`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
}

export const GetTickets = async (claimId) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/messages/${claimId}/getTickets`, 'GET')
    const response = await api.CallApi(action);
    return response
}

export const SendMessage = async (claimId, payload) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/messages/${claimId}/sendMessage`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
}

export const CompleteTicket = async (claimId, payload) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/messages/${claimId}/completeTicket`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
}

// export const GetTicketsTodo = async () => {
//     const action = api.GetAction(constants.API, `/tcl-dealer/messages/getTicketsTodo`, 'GET')
//     const response = await api.CallApi(action);
//     return response
// }

export const GetPaginateTicketsTodo = async (perPage, page) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/messages/getPaginateTicketsTodo?limit=${perPage}&page=${page}`, 'GET')
    const response = await api.CallApi(action);
    return response
}

export const GetMessages = async (ticketId) => {
    const action = api.GetAction(constants.API, `/tcl-dealer/messages/getMessages/${ticketId}`, 'GET')
    const response = await api.CallApi(action);
    return response
}

export const AddDocumentToClaimFromMessage = async (claimId, payload) => {
    console.log(claimId, payload)
    const action = api.GetAction(constants.API, `/tcl-dealer/messages/addDocumentToClaim/${claimId}`, 'POST', payload)
    const response = await api.CallApi(action);
    return response
}