// export const BASE_URL = 'http://localhost:80';  //   Sviluppo locale
export const BASE_URL = "/api";                   //  Produzione

export const VERSION = 'v1.10.3';

export const DEBUG_CREATE_CLAIM = false;
export const BLOCK_PERIODIC_GET_CLAIM = false;

export const USER_INFO = "USER_INFO";
export const USER_TOKEN_PAYLOAD = "USER_TOKEN_PAYLOAD";
export const COMPANY_NAME = 'COMPANY_NAME';
export const API = "API";
export const API_FILE_UPLOAD = "API_FILE_UPLOAD";
export const API_BLOB = "API_BLOB";
export const THEME_DARK = "dark";
export const LANGUAGE = "language";


//File
export const MaxFileNameShowLen = 40;
export const MaxAllowedFileSize = 10 * 1024 * 1024;

//Google Maps
export const GOOGLE_API_KEY = 'AIzaSyAX6UDUOmpjqdJ9ua1GFqS4zU3NLiYPMZg';

export const lista_filtri = {
    stateOfClaim: {
        "ToAssign": "Non assegnato",
        "Assigned": "Assegnato",
        "ApprovalPending": "Attesa approvazione",
        "Approved": "Autorizzata",
        "ExpertAssignment": "Incarico perito",
        "ExpertApproval": "Invio accordo conservativo",
        "Processing": "Lavorazione",
        "SettlementPending": "Attesa Liquidazione",
        "Settled": "Liquidato",
        "Archived": "Respinto",
        // "PreProcessing": "Pre lavorazione",
        // "CustomerSignature": "Firma cliente",
        // "SettlementInProgress": "Liquidazione in corso",
    }
}

export const months = {
    1: "Gennaio",
    2: "Febbraio",
    3: "Marzo",
    4: "Aprile",
    5: "Maggio",
    6: "Giugno",
    7: "Luglio",
    8: "Agosto",
    9: "Settembre",
    10: "Ottobre",
    11: "Novembre",
    12: "Dicembre",
};

export const listOfMonths = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre", 
];
