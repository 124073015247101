import { UploadSingleFile } from '@/helpers/file.service';
import { defineStore } from 'pinia'
import { getGuaranteesByPlateNumber, getDocsFromGaranziaApertura, getGoogleAddress } from '@/helpers/user.service'
import { DEBUG_CREATE_CLAIM, PRODUCT_RCA, PRODUCT_CVT, DEFAULT_PRODUCT_TYPE } from '@/helpers/constants.js';

export const useSinistroStore = defineStore('sinistro', {
    state: () => ({
        selectedProductType: '', // RCA, CVT
        selectedParameterActOfGod: '',
        selectedGaranzia: {},
        rcaGuarantees: [],
        cvtGuarantees: [],
        policiesData: {},
        policyDataRCA: {},
        policyDataCVT: {},
        docsForGuarantees: {},
        phoneNumber: DEBUG_CREATE_CLAIM ? '+393124567890' : '',
        addressUser: {
            placeClaime: '',
            latitude: 0,
            longitude: 0,
            descriptionClaime: '',
            returnedPlace: {}
        },
        placeDescriptionClaim: {
            placeClaime: '',
            latitude: 0,
            longitude: 0,
            descriptionClaime: '',
            returnedPlace: {}
        },
        requiredDocumentsUploaded: [],
        tableSinistro: {},
        eventDate: '',
        datiSinistro: {},
        claimId: '',
        isTaxi: false,
        isVhCommercial: false,
        isVhSubstitute: true,
        isFastApp: false,
        quotation: {},
        policyCondition: {},
        assignedIban: {},
        ods: {},
        otherDetails: {
            furtoTotaleIncendioDetails: {
                isReplace: false,
                isRestart10: false,
                isRestart20: false,
                isRestartSelected: false,
                restartAmount: 0,
            },
            cristalliDetails: {
                isRepare: false,
                isReplace: false,
            },
            meccanico: '',
        },
        isRestart10: false,
        isRestart20: false,
        isRestartSelected: false,
        rcaData: {}
	}),
    actions: {
        initStore() {
            this.selectedProductType = '';
            this.rcaGuarantees = [];
            this.cvtGuarantees = [];
            this.policiesData = {};
            this.policyDataRCA = {};
            this.policyDataCVT = {};
            this.docsForGuarantees = {};
            this.selectedGaranzia = {};
            this.phoneNumber = DEBUG_CREATE_CLAIM ? '+393124567890' : '',
            this.addressUser = {
                placeClaime: '',
                latitude: 0,
                longitude: 0,
                descriptionClaime: '',
                returnedPlace: {}
            };
            this.placeDescriptionClaim = {
                placeClaime: '',
                latitude: 0,
                longitude: 0,
                descriptionClaime: '',
                returnedPlace: {}
            };
            this.requiredDocumentsUploaded = [];
            this.datiSinistro = {};
            this.eventDate = '';
            this.claimId = '';
            this.isTaxi = false;
            this.isVhCommercial = false;
            this.isVhSubstitute = true;
            this.isFastApp = false;
            this.quotation = {};
            this.policyCondition = {};
            this.assignedIban = {};
            this.selectedParameterActOfGod = '';
            this.ods = {};
            this.otherDetails = {
                furtoTotaleIncendioDetails: {
                    isReplace: false,
                    isRestart10: false,
                    isRestart20: false,
                    isRestartSelected: false,
                    restartAmount: 0,
                },
                cristalliDetails: {
                    isRepare: false,
                    isReplace: false,
                },
                meccanico: '',
            };
            this.isRestart10 = false,
            this.isRestart20 = false,
            this.isRestartSelected = false,
            this.rcaData = {}
        },
        setClaimId(d) {
            this.claimId = d;
        },
        getClaimId() {
            return this.claimId;
        },
        setEventDate(d) {
            this.eventDate = d;
        },
        setIsTaxi(t) {
            this.isTaxi = t;
        },
        setIsVhCommercial(v) {
            this.isVhCommercial = v;
        },
        setIsVhSubstitute(v) {
            this.isVhSubstitute = v;
        },
        setIsFastApp(f) {
            this.isFastApp = f;
        },
        setDatiSinistro(d) {
            this.datiSinistro = d;
        },
        setSelectedGaranzia(g) {
            this.selectedGaranzia = g;
        },
        setSelectedParameterActOfGod(a) {
            this.selectedParameterActOfGod = a;
        },
        setPlaceDescriptionClaim(pdc) {
            this.placeDescriptionClaim = pdc;
        },
        setPhoneNumber(p) {
            this.phoneNumber = p;
        },
        setQuotation(q) {
            this.quotation = q;
        },
        setPolicyCondition(p) {
            this.policyCondition = p;
        },
        setAssignedIban(i) {
            this.assignedIban = i;
        },
        setDamageLocations(damageLocations) {
            this.rcaData['damageLocations'] = damageLocations;
        },
        async getGuaranteesByPlateNumberAction(plateNumber, eventDate) { // (response: any, isOk: boolean)
            const response = await getGuaranteesByPlateNumber(plateNumber, eventDate);
            if (response.status != 200) {
                return {response: response, isOk: false}
            }

            this.policiesData = response.data.policiesData;
            this.policyDataRCA = response.data.policiesData.policyDataRCA;
            this.policyDataCVT = response.data.policiesData.policyDataCVT;

            this.rcaGuarantees = response.data.guarantees.rca; // sempre
            this.cvtGuarantees = response.data.guarantees.cvt; // sempre
            let actions = response.data.actions;

            this.ods = response.data.ods;
            const guaranteeRca = this.ods.guaranteesRca?.length > 0 ? this.ods.guaranteesRca[0] : null;
            const guaranteeCvt = this.ods.guaranteesCvt?.length > 0 ? this.ods.guaranteesCvt[0] : null;
            const isRca = guaranteeRca != null;
            const isCvt = guaranteeCvt != null;

            if (!isRca && !isCvt) {
                return {response: response, isOk: false}
            }

            let phoneNumber = guaranteeRca?.telefono ? guaranteeRca?.telefono : guaranteeCvt?.telefono;
            let address = guaranteeRca?.indirizzo ? guaranteeRca?.indirizzo : guaranteeCvt?.indirizzo;

            const email = guaranteeRca?.emailContraente !== '' ? guaranteeRca?.emailContraente : guaranteeCvt?.emailContraente;
            const fullName = guaranteeRca?.nominativoContraente !== '' ? guaranteeRca?.nominativoContraente : guaranteeCvt?.nominativoContraente;
            if (isRca || isCvt) {
                const telaio = guaranteeRca?.telaio !== '' ? guaranteeRca?.telaio : guaranteeCvt?.telaio;
                const marca = guaranteeRca?.nomeModello !== '' ? guaranteeRca?.nomeModello : guaranteeCvt?.nomeModello;
                const modello = guaranteeRca?.nomeModello !== '' ? guaranteeRca?.nomeModello : guaranteeCvt?.nomeModello;
                const codiceFiscale = guaranteeRca?.codiceFiscaleContraente !== '' ? guaranteeRca?.codiceFiscaleContraente : guaranteeCvt?.codiceFiscaleContraente;

                this.setDatiSinistro({
                    "eventDate": eventDate,
                    "targa": plateNumber,
                    "telaio": telaio ? telaio : "",
                    "marca": marca ? marca : "",
                    "modello": modello ? modello : "",
                    "codiceFiscaleContraente": codiceFiscale ? codiceFiscale : "",
                    "productTypes": [],
                    "actions": actions,
                });
            }

            let isGoodAniaResponse = false;
            if (!isRca) {
                isGoodAniaResponse = this.isGoodAniaResponse(response.data.ania, eventDate, plateNumber, actions);
            }

            this.datiSinistro['nomeContraente'] = fullName ? fullName : "";
            this.datiSinistro['emailContraente'] = email ? email : "";

            if (isRca || isGoodAniaResponse) {
                this.datiSinistro.productTypes.push(PRODUCT_RCA);
                this.datiSinistro[PRODUCT_RCA] = {
                    'dataAttivazione': actions.rca.startAt,
                    'dataFine': actions.rca.expireAt,
                };
            }

            if (isCvt) {
                this.datiSinistro.productTypes.push(PRODUCT_CVT);
                this.datiSinistro[PRODUCT_CVT] = {
                    'dataAttivazione': actions.cvt.startAt,
                    'dataFine': actions.cvt.expireAt,
                };
            }

            if (phoneNumber != null) {
                this.setPhoneNumber(phoneNumber);
            }

            if (address != null) {
                const res = await getGoogleAddress(address);
                if (!res.response) {
                    this.addressUser.returnedPlace = res.data.address;
                }
            }

            return {response: response, isOk: true}
        },

        async getDocsFromGaranziaAperturaAction(nomeGaranzia) {
            const response = await getDocsFromGaranziaApertura(nomeGaranzia);
            if (response.status == 200) {
                const docs = response.data.docs;
                this.docsForGuarantees = docs;
                return true
            }
            return false
        },
        async uploadSingleFileAction(file) {
            const response = await UploadSingleFile(file);
            if (response.status == 200) {
                this.requiredDocumentsUploaded.push(response.data)
                return {isOk: true, data: response.data};
            }
            return {isOk: false, data: null};
        },
        removeUploadSingleFileAction(index) {
            this.requiredDocumentsUploaded.splice(index, 1);
        },
        isProductTypeInProductTypes(productType) {
            if (!this.datiSinistro.productTypes) {
                return false
            }

            return this.datiSinistro.productTypes.indexOf(productType) !== -1
        },
        isGoodAniaResponse(ania, eventDate, plateNumber, actions) {
            if (!ania) {
                return false
            }

            let telaio = ania.idVeicolo;
            let marca = ania.nomeCostr;
            let modello = ania.denomComm;
            let codiceFiscaleContraente = ania.idFiscProprietario;

            this.setDatiSinistro({
                "eventDate": eventDate,
                "targa": plateNumber,
                "nomeContraente": "",
                "emailContraente": "",
                "telaio": telaio ? telaio : "",
                "marca": marca ? marca : "",
                "modello": modello ? modello : "",
                "codiceFiscaleContraente": codiceFiscaleContraente ? codiceFiscaleContraente : "",
                "productTypes": [PRODUCT_RCA],
                "actions": actions,
            });

            return true
        }
    }
});
