<template>
    <div class="bg-slate-100 dark:bg-zinc-900 w-screen h-full p-6 flex flex-col items-center">
        <div class="flex flex-col lg:flex-row">
            <div class="bg-white p-6 rounded shadow w-fit my-6 lg:mx-6">
                <div class="mx-6 mb-3 w-[32rem]">
                    <h1 class="text-xl font-bold">Sinistri per stato</h1>
                    <p class="text-sm text-gray-500">Puoi vedere in maniera sequenziale il numero di sinistri raggruppati per stato. Prova a cliccare uno dei punti!</p>
                </div>
                <Graph v-if="data"
                    chartType="line"
                    :data="data"
                    @select="(selectedStatus: string) => showClaimsWithSelectedStatus(selectedStatus)"
                />
            </div>
            <div class="bg-white p-6 rounded shadow w-fit my-6 lg:mx-6">
                <div class="mx-6 mb-3 w-[32rem]">
                    <h1 class="text-xl font-bold">Sinistri per data</h1>
                    <p class="text-sm text-gray-500">Puoi vedere in maniera sequenziale il numero di sinistri raggruppati per data.</p>
                </div>
                <Graph v-if="claimsCountByDate"
                    chartType="bar"
                    :data="claimsCountByDate"
                />
            </div>
        </div>
        <div class="flex flex-col lg:flex-row">
            <div class="bg-white p-6 rounded shadow w-fit my-6 lg:mx-6">
                <div class="mx-6 mb-3 w-[32rem]">
                    <h1 class="text-xl font-bold">Sinistri per valore</h1>
                    <p class="text-sm text-gray-500">Puoi vedere in maniera sequenziale il numero di sinistri raggruppati per valore.</p>
                </div>
                <Graph v-if="totalAmountOfOwnCompanyClaimsByStatus"
                    chartType="line"
                    :data="totalAmountOfOwnCompanyClaimsByStatus"
                    label="Valore (€)"
                />
            </div>
            <div class="flex">
                <div class="flex flex-col mt-5">
                    <div class="my-1 bg-white shadow p-6 rounded w-38 h-fit mx-6 dark:bg-zinc-800">
                        <p class="text-gray-500">Segnalatori totali</p>
                        <p class="font-bold text-xl">{{ totalNumberOfOwnCompanyDealers }}</p>
                    </div>
                    <div class="my-1 bg-white shadow p-6 rounded w-38 h-fit mx-6 dark:bg-zinc-800">
                        <p class="text-gray-500">Sinistri totali</p>
                        <p class="font-bold text-xl">{{ totalNumberOfOwnCompanyClaims }}</p>
                    </div>
                </div>
                <div class="my-6 bg-white shadow py-2 rounded w-fit h-fit mx-6 dark:bg-zinc-800">
                    <table class="font-thin dark:bg-zinc-800 dark:text-white">
                        <thead class="dark:text-white">
                            <tr class="border-gray-300 border-b text-left">
                                <th class="text-left p-2 px-5">Segnalatori</th>
                                <th class="text-left p-2 px-5">Sinistri segnalati</th>
                            </tr>
                        </thead>
                        <tbody class="dark:bg-zinc-800 divide-y dark:divide-zinc-700">
                            <tr v-show="obj.utenteCreateClaim" @click="getClaimsByDealerHandler(obj.utenteCreateClaim?._id)" v-for="obj in listOfClaimsByUser" class="h-16 hover:!bg-gray-300 transition cursor-pointer dark:hover:!bg-zinc-900">
                                <td v-if="obj.utenteCreateClaim">
                                    <span class="pt-2 pb-2.5 px-3 ml-3 bg-gray-200 rounded-full text-center">{{ obj.utenteCreateClaim.firstName[0] }}{{ obj.utenteCreateClaim.lastName[0] }}</span>
                                    <span class="text-left p-2 px-5">{{ obj.utenteCreateClaim.firstName }} {{ obj.utenteCreateClaim.lastName }}</span>
                                </td>
                                <td v-if="obj.utenteCreateClaim" class="text-left p-2 px-5">{{ obj.count }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <MazBottomSheet v-model="showTable">
            <div class="max-h-[80vh] overflow-scroll">
                <Table
                    :colonne="[$t('table.columns.sinistri.code'), $t('table.columns.sinistri.backendCode'), $t('table.columns.sinistri.client'), $t('table.columns.sinistri.phone'), $t('table.columns.sinistri.fiscalCode'), $t('table.columns.sinistri.plateNumber'), $t('table.columns.sinistri.eventDate'), $t('table.columns.sinistri.status'), $t('table.columns.sinistri.reportingUser'), $t('table.columns.sinistri.liquidatorUser')]"
                    :data="listOfClaims"
                />
            </div>
        </MazBottomSheet>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import Graph from '@/components/graphs/Graph.vue';
    import { ChartData, ClaimsNumberByState, DisplayedData, GenericCountResponse, ClaimsNumberByDate } from '@/models/models';
    import { UsersByNumber, Pagination } from '@/models/Claims';
    import { GetFilteredPageClaims, GetClaimsByDealer } from '@/helpers/claims.service';
    import { GetClaimsNumberByState, GetClaimsNumberByDealer, GetTotalNumberOfOwnCompanyDealers, GetTotalNumberOfOwnCompanyClaims, GetNumberOfOwnCompanyClaimsByDate, GetTotalAmountOfOwnCompanyClaimsByStatus } from '@/helpers/analytics';
    import { lista_filtri, months } from '@/helpers/constants';
    import Table from '@/components/Table.vue';
    import setDisplayedData from '@/utilities/setDisplayedDataClaims';
    import MazBottomSheet from 'maz-ui/components/MazBottomSheet'

    const listOfClaims = ref<DisplayedData[]>([]);
    const data = ref<ChartData[]>([]);
    const listOfClaimsByUser = ref<UsersByNumber[]>([]);
    const showTable = ref(false);
    const defaultPagination = ref<Pagination>({ total: 0, page: 1, perPage: 5, prev: 0, next: 0, totalPage: 1 });
    const pagination = ref<Pagination>(defaultPagination.value);

    const totalNumberOfOwnCompanyDealers = ref<number>(0);
    const totalNumberOfOwnCompanyClaims = ref<number>(0);
    const claimsCountByDate = ref<ChartData[]>([]);
    const totalAmountOfOwnCompanyClaimsByStatus = ref<ChartData[]>([]);

    onMounted(() => {
        getClaimsNumberByStateHandler();
        getClaimsNumberByUserHandler();
        getTotalNumberOfOwnCompanyDealersHandler();
        getTotalNumberOfOwnCompanyClaimsHandler();
        getNumberOfOwnCompanyClaimsByDateHandler();
        getTotalAmountOfOwnCompanyClaimsByStatusHandler();
    })

    async function getClaimsNumberByStateHandler() {
        const response = await GetClaimsNumberByState();
        const status = response.status;
        if (status === 200) {
            const result = response.data as ClaimsNumberByState[];
            for (let row of result) {
                //@ts-ignore
                const stateOfClaim = lista_filtri.stateOfClaim[row.stateOfClaim];                
                data.value.push({
                    key: stateOfClaim,
                    count: row.count,
                });
            }
        }
    }

    async function showClaimsWithSelectedStatus(selectedStatusItalian: string) {
        let selectedStatus: string = ''; 
        for (let stateOfClaim in lista_filtri.stateOfClaim) {
            //@ts-ignore
            if (lista_filtri.stateOfClaim[stateOfClaim] == selectedStatusItalian) {
                selectedStatus = stateOfClaim;
            }
        }

        if (selectedStatus !== '') {
            const response = await GetFilteredPageClaims(1, 10, selectedStatus);
            const status = response.status;

            if (status === 200) {
                listOfClaims.value = setDisplayedData(response.data.claims.data);
                pagination.value = response.data.claims.pagination ?? calculatePagination(response.data.claims.data);

                showTable.value = true;
            }
        }
    }

    async function getClaimsNumberByUserHandler() {
        const response = await GetClaimsNumberByDealer();
        if (response.status === 200) {
            listOfClaimsByUser.value = response.data as UsersByNumber[];
        }
    }

    async function getClaimsByDealerHandler(userId: string | undefined) {
        if (userId) {
            const response = await GetClaimsByDealer(userId);

            if (response.status === 200) {            
                listOfClaims.value = setDisplayedData(response.data);
                pagination.value = response.data.pagination ?? calculatePagination(response.data);
                
                showTable.value = true;
            }
        }
    }

    async function getTotalNumberOfOwnCompanyDealersHandler() {
        const response = await GetTotalNumberOfOwnCompanyDealers();
        if (response.status === 200) {
            const data = response.data as GenericCountResponse;
            totalNumberOfOwnCompanyDealers.value = data.count;
        }
    }

    async function getTotalNumberOfOwnCompanyClaimsHandler() {
        const response = await GetTotalNumberOfOwnCompanyClaims();
        if (response.status === 200) {
            const data = response.data as GenericCountResponse;
            totalNumberOfOwnCompanyClaims.value = data.count;
        }
    }
    
    async function getNumberOfOwnCompanyClaimsByDateHandler() {
        const response = await GetNumberOfOwnCompanyClaimsByDate();
        if (response.status === 200) {
            setDataToShowForDateGraph(response.data as ClaimsNumberByDate[]);
        }
    }

    async function getTotalAmountOfOwnCompanyClaimsByStatusHandler() {
        const response = await GetTotalAmountOfOwnCompanyClaimsByStatus();
        if (response.status === 200) {
            const data = response.data as GenericCountResponse[] ?? [];
            totalAmountOfOwnCompanyClaimsByStatus.value = [];
            
            for (let row of data) {
                //@ts-ignore
                const stateOfClaim = lista_filtri.stateOfClaim[row._id];

                totalAmountOfOwnCompanyClaimsByStatus.value.push({
                    key: stateOfClaim,
                    count: row.count / 100,
                })
            }
        }
    }

    // if no pagination was given then calculate the pagination by your own
    function calculatePagination(data: any[]): Pagination {
        let pagination: Pagination = defaultPagination.value;        
        pagination.total = data.length;        
        pagination.totalPage = Math.floor(data.length / pagination.perPage);

        return pagination
    }

    function setDataToShowForDateGraph(data: ClaimsNumberByDate[]) {
        claimsCountByDate.value = [];
        const tempData = data;

        if (!data) {
            return
        }

        if (data.length > 12) {
            for (let i = 0; i < 11; i++) {
                tempData.push(data[i]);
            }
        }

        for (let obj of data) {
            claimsCountByDate.value.push({
                // @ts-ignore
                key: `${months[obj.month]} ${obj.year}`,
                count: obj.count,
            });
        }
    }

</script>

<style scoped>

</style>