<template>
	<!-- Desktop -->
    <header class="bg-white hidden md:block w-full z-10 dark:bg-zinc-800" v-if="isAuthenticated">
		<div class="flex flex-row items-baseline justify-between px-8 py-2">
			<div class="flex items-center gap-4">
				<div>
					<RouterLink to="/"><img class="w-40 dark:invert dark:sepia dark:saturate-0 dark:hue-rotate-288 dark:brightness-0 dark:contrast-102" src="../assets/logo/logo-removebg.png" /></RouterLink>
					<p class="text-xs">{{ VERSION }}</p>
				</div>
				<div class="flex flex-row items-center justify-center gap-2 text-l font-300">
					<RouterLink to="/nuovo-sinistro" @click="whichPage = 'sinistro'" class="rounded-md px-4 py-2 hover:bg-gray-300 dark:hover:bg-zinc-900 active:bg-red-600 dark:active:bg-red-300 active:!text-white dark:text-white dark:active:!text-black">Crea un nuovo sinistro</RouterLink>
					<RouterLink v-if="isAdminUser" to="/amministrazione" @click="whichPage = 'sinistro'" class="rounded-md px-4 py-2 hover:bg-gray-300 dark:hover:bg-zinc-900 active:bg-red-600 dark:active:bg-red-300 active:!text-white dark:text-white dark:active:!text-black">Amministrazione</RouterLink>
					<Search />
				</div>
			</div>
			<div @click="(showProfileCard = !showProfileCard)" class="absolute right-12 top-2 font-300 bg-gray-200 rounded-full cursor-pointer mr-2 hover:bg-gray-300 active:bg-red-600 active:text-white">
				<p class="pt-2 pb-2.5 px-3">{{user_profile.iniziali}}</p>
			</div>
		</div>
		<CardUserHeader 
			:show_profile_card="showProfileCard"
			:user_profile="user_profile"
			:theme="theme"
			@handle-theme="(selectedTheme) => handleTheme(selectedTheme)"
			@toggle-profile-card="(show) => toggleProfileCard(show)"
		/> 
		<hr>
  	</header>
	
	<!-- Mobile -->
	<header class="block md:hidden bg-white w-full z-10 dark:bg-zinc-800" v-if="isAuthenticated">
		<div class="flex justify-around items-center px-8 py-2">
			<button @click="isExpanded = !isExpanded" class="px-3 p-2 rounded-full active:bg-gray-200"><span class="text-2xl material-symbols-outlined">menu</span></button>
			<div>
				<RouterLink to="/"><img class="w-28 dark:invert dark:sepia dark:saturate-0 dark:hue-rotate-288 dark:brightness-0 dark:contrast-102" src="../assets/logo/logo-removebg.png" /></RouterLink>
				<p class="text-xs">{{ VERSION }}</p>
			</div>
			<div @click="(showProfileCard = !showProfileCard)" class="w-fit h-fit font-300 bg-gray-200 rounded-full cursor-pointer mr-2 hover:bg-gray-300 active:bg-red-600 active:text-white">
				<p class="pt-2 pb-2.5 px-3">{{user_profile.iniziali}}</p>
			</div>
		</div>
		<CardUserHeader 
			:show_profile_card="showProfileCard"
			:user_profile="user_profile"
			:theme="theme"
			@handle-theme="(selectedTheme) => handleTheme(selectedTheme)"
			@toggle-profile-card="(show) => toggleProfileCard(show)"
		/>
		<div v-if="isExpanded" class="z-50 absolute py-6 bg-white w-2/3 h-screen">
			<div class="flex flex-col items-center">
				<RouterLink to="/nuovo-sinistro" @click="whichPage = 'sinistro'" class="text-l font-300 mb-3 rounded-md px-4 py-2 hover:bg-gray-300 dark:hover:bg-zinc-900 active:bg-red-600 dark:active:bg-red-300 active:!text-white dark:text-white dark:active:!text-black">Crea un nuovo sinistro</RouterLink>
				<RouterLink v-if="isAdminUser" to="/amministrazione" @click="whichPage = 'sinistro'" class="text-l font-300 mb-3 rounded-md px-4 py-2 hover:bg-gray-300 dark:hover:bg-zinc-900 active:bg-red-600 dark:active:bg-red-300 active:!text-white dark:text-white dark:active:!text-black">Amministrazione</RouterLink>
				<Search />
			</div>
		</div>
		<hr>
  	</header>
</template>

<script setup>
    import { useSessionStore } from '../stores/session.js'
	import { VERSION } from '@/helpers/constants';
    import { storeToRefs } from 'pinia'
	import { ref, onMounted, onUpdated } from 'vue';
	import getInizialiUser from '../utilities/getInizialiUser.js';
	import CardUserHeader from './CardUserHeader.vue';
	import Search from './Search.vue'
	import { parseJwt } from '@/helpers/api';

    const store = useSessionStore();
    const { isAuthenticated } = storeToRefs(store);

	const whichPage = ref('dashboard');
	const showProfileCard = ref(false);
	const isExpanded = ref(false);
	const isAdminUser = ref(false);
	const user_profile = ref({
		'iniziali': '',
		'fullName': '',
		'email': '',
		'companyName': ''
	})
	const theme = ref('');

	onUpdated(() => {
		setUser();
		isAdminUser.value = checkIfUserIsAdmin();

		handleTheme(localStorage.theme ? localStorage.theme : 'light')
	})

	onMounted(() => {
		setUser();
		isAdminUser.value = checkIfUserIsAdmin();

		handleTheme(localStorage.theme ? localStorage.theme : 'light')
	})

	function setUser() {
		const response = getInizialiUser();
		
		user_profile.value.fullName = response.fullName;
		user_profile.value.email = response.email;
		user_profile.value.iniziali = response.iniziali;
		user_profile.value.companyName = response.companyName;
	}

	function checkIfUserIsAdmin() {
		if (store.user) {
			const userRoles = JSON.parse(parseJwt(store.user.access_token)).roles;
			for (let role of userRoles) {
				if (role.code == 'admin' || role.code == 'super_admin') {
					return true
				}
			}

			return false
		}
	};

	function handleTheme(selectedTheme) {
		localStorage.theme = selectedTheme
		theme.value = selectedTheme

		if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
			document.documentElement.classList.add('dark')
			return
		}
		
		document.documentElement.classList.remove('dark')
	}

	function toggleProfileCard(show) {
		showProfileCard.value = show;
	}

</script>

<style scoped>

</style>