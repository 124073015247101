<template>
    <div v-for="(ticket, index) in tickets" v-show="!ticket.isDone" class="group p-4 my-6 rounded shadow cursor-pointer hover:shadow-md" :key="index">
        <div class="flex items-center justify-between">
            <div class="w-3/4" @click="emits('selectTicket', ticket)">
                <h1 class="text-xl font-semibold">{{ ticket.name }}</h1>
                <p v-if="ticket.messages[Object.keys(ticket.messages).length - 1]" class="py-1 text-sm text-gray-500">{{ticket.messages[Object.keys(ticket.messages).length - 1].user.firstName}} {{ticket.messages[Object.keys(ticket.messages).length - 1].user.lastName}}: {{ticket.messages[Object.keys(ticket.messages).length - 1].message !== '' ? ticket.messages[Object.keys(ticket.messages).length - 1].message : 'Richiesta file'}}</p>
                <p v-else class="py-1 text-sm text-gray-500">Ancora nessun messaggio</p>
            </div>
            <button @click="emits('selectTicket', ticket)" class="hidden group-hover:block h-12 mx-2 rounded p-2 px-4 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300">
                <div class="flex items-center">
                    <p class="text-sm">Apri</p>
                </div>
            </button>
            <!-- <button @click="toast.error('Impossibile eseguire questa azione.', {position: 'top'});" class="hidden group-hover:block h-12 mx-2 rounded p-2 px-4 bg-gray-300 text-white hover:bg-gray-00 focus:ring focus:ring-gray-300">
                <div class="flex items-center">
                    <span class="text-md material-symbols-outlined">done</span>
                    <p class="text-sm">Completa</p>
                </div>
            </button> -->
            <!-- <GenericConfirmModal v-if="showModal"
                title="Sei sicuro di voler completare il ticket?"
                @close-modal="showModal = false"
                @confirm="completeTicket(ticket)"
            /> -->
        </div>
    </div>

    <div class="mt-24">
        <div @click="isExpanded = !isExpanded" class="flex items-center cursor-pointer hover:text-zinc-800">
            <span v-if="!isExpanded" class="p-2 material-symbols-outlined">chevron_right</span>
            <span v-if="isExpanded" class="p-2 material-symbols-outlined">expand_more</span>
            <p>Richieste completate</p>
        </div>
        <div v-if="isExpanded">
            <div v-for="(ticket, index) in tickets" v-show="ticket.isDone" class="group p-4 my-6 rounded shadow cursor-pointer hover:shadow-md" :key="index">
                <div class="w-3/4" @click="emits('selectTicket', ticket)">
                    <h1 class="text-xl font-semibold">{{ ticket.name }}</h1>
                    <p v-if="ticket.messages[Object.keys(ticket.messages).length - 1]" class="py-1 text-sm text-gray-500">{{ticket.messages[Object.keys(ticket.messages).length - 1].user.firstName}} {{ticket.messages[Object.keys(ticket.messages).length - 1].user.lastName}}: {{ticket.messages[Object.keys(ticket.messages).length - 1].message !== '' ? ticket.messages[Object.keys(ticket.messages).length - 1].message : 'Richiesta file'}}</p>
                    <p v-else class="py-1 text-sm text-gray-500">Ancora nessun messaggio</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { watchEffect, ref, onUnmounted, onMounted } from 'vue';
    import { CompleteTicket } from '@/helpers/messages.service';
    import GenericConfirmModal from '../GenericConfirmModal.vue';
    import { BLOCK_PERIODIC_GET_CLAIM } from '@/helpers/constants';
    import { useToast } from 'vue-toast-notification';

    const emits = defineEmits(['selectTicket', 'refreshTickets']);
    const props = defineProps({
        ticketsProp: Array,
        claimId: String,
    })

    const tickets = ref([]);
    const isExpanded = ref(false);
    // const showModal = ref(false);
    const toast = useToast()

    watchEffect(() => {
        tickets.value = props.ticketsProp;
    })

    onMounted(() => {
        !BLOCK_PERIODIC_GET_CLAIM && refreshTicketsEvery2Seconds();
    });

    onUnmounted(() => {
        !BLOCK_PERIODIC_GET_CLAIM && clearInterval(intervalId);
    });

    const completeTicket = async function setTicketIsDoneTrue(ticket) {
        // showModal.value = false;

        const payload = {
            'ticketId': ticket._id,
            'name': ticket.name,
        }

        await CompleteTicket(props.claimId, payload).then(resp => {
            if (resp.status == 200) {
                emits('refreshTickets');
            }
        }).catch(err => {
            console.log(err);
        })
    }

    let intervalId;
    function refreshTicketsEvery2Seconds() {
        intervalId = setInterval(() => {
            emits('refreshTickets');
        }, 5000);
    }
</script>

<style scoped>

</style>
