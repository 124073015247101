import * as api from './api'
import * as constants from './constants';

export const GetClaimsNumberByState = async () =>  {
    const action = api.GetAction(constants.API, `/tcl-dealer/analytics/getClaimsNumberByState`, 'GET')
    const response = await api.CallApi(action);
    return response
};

export const GetClaimsNumberByDealer = async () =>  {
    const action = api.GetAction(constants.API, `/tcl-dealer/analytics/getClaimsNumberByDealer`, 'GET')
    const response = await api.CallApi(action);
    return response
};

export const GetTotalNumberOfOwnCompanyDealers = async () =>  {
    const action = api.GetAction(constants.API, `/tcl-dealer/analytics/getTotalNumberOfOwnCompanyDealers`, 'GET')
    const response = await api.CallApi(action);
    return response
};

export const GetTotalNumberOfOwnCompanyClaims = async () =>  {
    const action = api.GetAction(constants.API, `/tcl-dealer/analytics/getTotalNumberOfOwnCompanyClaims`, 'GET')
    const response = await api.CallApi(action);
    return response
};

export const GetNumberOfOwnCompanyClaimsByDate = async () =>  {
    const action = api.GetAction(constants.API, `/tcl-dealer/analytics/getNumberOfOwnCompanyClaimsByDate`, 'GET')
    const response = await api.CallApi(action);
    return response
};

export const GetTotalAmountOfOwnCompanyClaimsByStatus = async () =>  {
    const action = api.GetAction(constants.API, `/tcl-dealer/analytics/getTotalAmountOfOwnCompanyClaimsByStatus`, 'GET')
    const response = await api.CallApi(action);
    return response
};