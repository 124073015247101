<template>
    <div class="flex">
        <div class="flex items-center bg-gray-200 dark:bg-zinc-800 p-2 rounded-md xl:mx-6">
            <input @keyup="filterDocuments" v-model="search" autocomplete="off" type="text" id="search" name="search"
                placeholder="Cerca documento" class="bg-gray-200 dark:bg-zinc-800 outline-none w-36" />
            <span class="material-symbols-outlined">search</span>
        </div>
        <button id="download-button" @click="downloadFiles(idClaim)"
            class="rounded p-2 px-4 mx-6 md:mx-12 text-center border-solid border-2 border-green-500 text-green-500 cursor-pointer hover:bg-green-100 dark:hover:bg-green-900 dark:hover:text-green-100 active:bg-green-700 active:text-white">
            <span v-if="isLoading == false">Scarica tutto</span> 
            <span v-if="isLoading == true">
                <svg aria-hidden="true" class="mx-9 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
            </span>
        </button>
    </div>
    <div class="flex justify-center">
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            <div v-for="obj in filteredDocuments"
                class="card rounded shadow w-64 p-4 my-8 mx-4 flex flex-col justify-center dark:bg-zinc-800">
                <div>
                    <span v-if="obj.mimeType == 'application/pdf'"
                        class="p-2 pb-1.5 mb-2 bg-rose-200 text-rose-600 rounded material-symbols-outlined">description</span>
                    <MazAvatar v-else :src="`${BASE_URL}/tcl-dealer/static` + obj.url" size="1rem"
                        :href="`${BASE_URL}/tcl-dealer/static` + obj.url" target="_blank" square clickable>
                        <template #icon>
                            <span class="material-symbols-outlined">visibility</span>
                        </template>
                    </MazAvatar>
                    <h1 class="text-xl font-bold">{{ obj.name }}<br></h1>
                </div>
                <div class="my-4 text-gray-600 dark:text-gray-300">
                    <p class="">Tipologia: {{ obj.mimeType }}</p>
                    <div class="flex content-center">
                        <p class="w-28">Dimensioni:</p>
                        <p class="">{{ (obj.size / 1000000).toFixed(2) }}MB</p>
                    </div>
                </div>
                <a target="_blank" :href="`${BASE_URL}/tcl-dealer/static` + obj.url"
                    class="rounded p-2 px-4 mx-12 text-center border-solid border-2 border-green-500 text-green-500 cursor-pointer hover:bg-green-100 dark:hover:bg-green-900 dark:hover:text-green-100 active:bg-green-700 active:text-white">Apri</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useToast } from 'vue-toast-notification';
import { ref, watch, onMounted } from 'vue';
import { BASE_URL } from '@/helpers/constants';
import Loading from 'vue-loading-overlay';
import MazAvatar from 'maz-ui/components/MazAvatar';
import axios from 'axios';

const props = defineProps({
    documents: Array,
    idClaim: String
})

const search = ref('');
const filteredDocuments = ref([]);
const toast = useToast();
const idClaim = ref("")
const isLoading = ref(false)

onMounted(() => {
    filteredDocuments.value = props.documents;
    idClaim.value = props.idClaim
})

watch(props, () => {
    filteredDocuments.value = props.documents;
    idClaim.value = props.idClaim
})

// function b(url) {
//     let user = JSON.parse(localStorage.getItem('USER_INFO'));

//     if (user && user.access_token) {
//         return fetch(`${BASE_URL}/tcl-dealer/static` + url, {
//             headers: {
//                 'Authorization': `Bearer ${user.access_token}`
//             }
//         })
//     }
// }

// function base64ToArrayBuffer(base64) {
//     var binaryString = window.atob(base64);
//     var binaryLen = binaryString.length;
//     var bytes = new Uint8Array(binaryLen);
//     for (var i = 0; i < binaryLen; i++) {
//     var ascii = binaryString.charCodeAt(i);
//     bytes[i] = ascii;
//     }
//     return bytes;
// }

// function saveByteArray(reportName, byte) {
//     var blob = new Blob([byte], {type: "image/jpeg"});
//     var link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     var fileName = reportName;
//     link.download = fileName;
//     link.click();
// };

// Possiamo filtrare i documenti da Frontend in quanto il numero di documenti caricabili è limitato
function filterDocuments() {
    filteredDocuments.value = [];
    for (let obj of props.documents) {
        if (((obj.name).toLowerCase()).includes((search.value).toLowerCase())) {
            if (!containsObject(obj, filteredDocuments.value)) {
                filteredDocuments.value.push(obj);
            }
        }
    }
}

// Determina se l'oggetto è già presente nella lista così da non far aggiungere lo stesso oggetto più volte
function containsObject(obj, list) {
    for (let i = 0; i < list.length; i++) {
        if (list[i] === obj) {
            return true;
        }
    }
    return false;
}

// Funzione per scaricare tutti i file
// async function downloadFiles(filesArray) {
//     console.log(filesArray);
//     let downloadedFiles = [];

//     for (let i = 0; i < filesArray.length; i++) {
//         let file = filesArray[i];

//         if (file.hasOwnProperty("url")) {
//             try {
//                 let response = await axios.get(`${BASE_URL}/tcl-dealer/static${file.url}`, {
//                     responseType: 'blob'
//                 });
//                 console.log(response);

//                 // Crea un link di download e cliccalo
//                 const url = window.URL.createObjectURL(new Blob([response.data]));
//                 const link = document.createElement('a');
//                 link.href = url;
//                 link.setAttribute('download', file.name + file.ext);
//                 document.body.appendChild(link);
//                 link.click();

//                 downloadedFiles.push(file);
//             } catch (error) {
//                 toast.error(`Errore scaricamento file: ${error}`, {
//                     position: 'top',
//                     timeOut: 15000,
//                 });
//                 console.error();
//             }
//         }
//     }
//     return downloadedFiles;
// }

const downloadFiles = async (idClaim) => {
    try {
        isLoading.value = true

        document.getElementById("download-button").setAttribute("disabled", "")

        // await new Promise(resolve => setTimeout(resolve, 3000));

        let response = await axios.get(`${BASE_URL}/tcl-dealer/getAllDocs/${idClaim}`, {
            responseType: 'blob'
        });
        
        // Crea un link di download e cliccalo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Documents_${idClaim}.zip`);
        document.body.appendChild(link);
        link.click();
        
        document.getElementById("download-button").removeAttribute("disabled")
        isLoading.value = false
    } catch (error) {
        isLoading.value = false
        document.getElementById("download-button").removeAttribute("disabled")
        toast.error(`Errore scaricamento: ${error}`, {
            position: 'top',
            timeOut: 15000,
        });
        console.error();
    }
}

</script>

<style scoped></style>