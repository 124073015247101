<template>
    <div class="flex justify-between">
        <div class="hidden" :style="[isSent && {display: 'block'}]"></div>
        <div class="flex items-end mb-6">
            <div v-if="!isSent" class="font-300 bg-gray-200 rounded-full mx-2 mt-4 p-2 px-3">
				<p>{{initials}}</p>
			</div>
            <div>
                <div class="flex text-sm">
                    <p class="mr-2">{{ owner }}</p>
                    <p class="text-zinc-400">{{ formattedData }} 🕒</p>
                </div>
                <div class="bg-zinc-100 dark:bg-zinc-700 rounded p-2">
                    <div class="card rounded shadow bg-white w-64 p-4 my-4 mx-4 flex flex-col justify-center dark:bg-zinc-800 hover:shadow-lg">
                        <div class="mb-4">
                            <span v-if="fileRequest.mimeType == 'application/pdf'" class="p-2 pb-1.5 mb-2 bg-rose-200 text-rose-600 rounded material-symbols-outlined">description</span>
                            <span v-else class="p-2 pb-1.5 mb-2 bg-rose-200 text-rose-600 rounded material-symbols-outlined">image</span>
                            <h1 class="text-xl font-bold">{{fileRequest.name}}<br></h1>
                        </div>
                        <label v-if="!isFileResponse" class="cursor-pointer mr-4">
                            <div :id="['div_'+ConvertStringToCamelCase(fileRequest.name)+'_'+index]" class="flex rounded p-2 px-4 mx-12 text-center border-solid border-2 border-green-500 text-green-500 cursor-pointer hover:bg-green-100 dark:hover:bg-green-900 dark:hover:text-green-100 active:bg-green-700 active:text-white">
                                <span class="material-symbols-outlined block">upload</span>
                                <input 
                                    :id="['file_'+ConvertStringToCamelCase(fileRequest.name)+'_'+index]"
                                    :name="[fileRequest.name]"
                                    class="hidden w-40" 
                                    type="file" 
                                    accept=".png,.jpg,.jpeg,.pdf" 
                                    @change="handleFile($event)"
                                    maxlength="10" />
                                <p>Carica</p>
                            </div>
                        </label>
                        <p v-else class="text-gray-600">Hai caricato questo file.</p>
                    </div>
                </div>
            </div>
            <div v-if="isSent" class="font-300 bg-gray-200 rounded-full mx-2 mt-4 p-2 px-3">
				<p>{{initials}}</p>
			</div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import { ConvertStringToCamelCase } from '@/helpers/utils.ts';
    import { onFileChanged } from '@/utilities/handleFile.js';
    import { AddDocumentToClaimFromMessage } from '@/helpers/messages.service';
    import { useToast } from 'vue-toast-notification';

    const props = defineProps({
        owner: String,
        time: String,
        fileRequest: Object,
        initials: String,
        claimId: String,
        ticketId: String,
        messageId: String,
        isFileResponse: Boolean,
    })

    const t = new Date(props.time);
    let time = `${t.getHours()}:${t.getMinutes()} • ${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()}`;

    const formattedData = ref(time);
    const isFileResponse = ref(false);

    onMounted(() => {
        isFileResponse.value = props.isFileResponse;
    })

    async function handleFile(event) {
        const files = await onFileChanged(event);
        const payload = {
            'addDocumentToClaimPayload': {
                'optionalDocuments': files,
            },
            'ticketId': props.ticketId,
            'MessageId': props.messageId,
        }

        AddDocumentToClaimFromMessage(props.claimId, payload)
        .then(resp => {
            console.log(resp);
            if (resp.status == 200) {
                isFileResponse.value = true;

                const toast = useToast();
                toast.success("Documenti aggiunti correttamente", {
                    position: 'top',
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

</script>

<style scoped>

</style>