<template>
    <div class="rounded shadow-xl p-4 my-2 dark:bg-zinc-800">
        <div class="my-2 flex flex-col">
            <label for="importo-riparazione-cristalli">Importo di riparazione (iva incl.) (€)</label>
            <input id="importo-riparazione-cristalli" @keyup="($event) => setImportoRiparazione($event)" :value="importoRiparazioneCristalli" class="input dark:bg-zinc-700" type="number" />
        </div>
        <div class="my-2 flex flex-col">
            <label for="">Recupero iva (%)</label>
            <!-- <input v-model="recuperoIvaCristalli" class="dark:bg-zinc-700" type="number" /> -->
            <select @change="getValue" class="p-2 px-4 dark:bg-zinc-700" name="recupero-iva" id="recupero-iva">
                <option v-for="p in [0, 10, 20, 40, 80, 100]" :value="p" class="font-sans">{{ p }}%</option>
            </select>
        </div>
        <hr>
        <button @click="calculateCrystal" class="w-40 p-2 bg-red-500 text-white mt-4 hover:bg-red-600 focus:ring focus:ring-red-300">Calcola</button>
        <div class="my-4 text-sm flex justify-center" v-if="isCalculated">
            <div>
                <p class="">Importo di liquidazione Andie</p>
                <p class="text-2xl font-bold text-center">{{  ToCurrency(importoDiLiquidazioneAndie) }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ToCents, ToCurrency } from '@/helpers/utils';
    import { ref } from 'vue';
    import { useSinistroStore } from '@/stores/sinistro';
    import { CalculateCrystal } from '@/helpers/claims.service.ts';
    import { useToast } from 'vue-toast-notification';


    const toast = useToast();
    const props = defineProps({
        massimaleResiduo: Number,
        franchigia: Number,
        decurtazione: Number,
    })

    const emits = defineEmits(['hasDone'])

    const sinistro = useSinistroStore();

    const importoRiparazioneCristalli = ref(0);
    const recuperoIvaCristalli = ref(0);
    const importoDiLiquidazioneAndie = ref(0);
    const isCalculated = ref(false);

    function getValue() {
        const obj = document.getElementById('recupero-iva');
        recuperoIvaCristalli.value = obj.value;
        importoDiLiquidazioneAndie.value = 0;
    }

    async function calculateCrystal() {
        if (importoRiparazioneCristalli.value < 100) {
            toast.error('Inserire il valore del preventivo, min 100€ !', { position: 'top' });
            return
        }

        isCalculated.value = true;

        const payload = {
            "importoRiparazioneCristalli": importoRiparazioneCristalli.value,
            "recuperoIvaPercentuale": parseInt(recuperoIvaCristalli.value),
            "massimaleResiduo": props.massimaleResiduo,
            "franchigia": props.franchigia,
            "decurtazione": -1,
        }

        if (props.franchigia == undefined) {
            payload['franchigia'] = -1;
            payload['decurtazione'] = props.decurtazione;
        }

        const resp = await CalculateCrystal(payload);
        if (resp && resp.status === 200) {
            importoDiLiquidazioneAndie.value = resp.data.importoDiLiquidazione;
        }

        // amount nel backend è un intero e il formato è in centesimi (es: 390.98 = 39098). Da tenere a mente quando dovremo usarlo, così da dividere per 100
        sinistro.setQuotation({
            'amount': ToCents(importoDiLiquidazioneAndie.value),
            'isDeductiblePercent': true,
            'deductiblePercent': ToCents(parseInt(recuperoIvaCristalli.value)),
            // 'mechanic': '-',
        });

        emits('hasDone');
    }

    function setImportoRiparazione(event) {
        importoRiparazioneCristalli.value = parseFloat(event.target.value);
        importoDiLiquidazioneAndie.value = 0;
    }
</script>

<style scoped>
</style>