import { createApp } from 'vue'
import { createPinia, storeToRefs } from 'pinia'
import { useSessionStore } from './stores/session'
import { useLanguageStore } from './stores/language';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { GOOGLE_API_KEY } from './helpers/constants';
import { createI18n } from 'vue-i18n'
// @ts-ignore
import it from '@/locales/it.json';
// @ts-ignore
import en from '@/locales/en.json';

import App from './App.vue'
import router from './router/index.js'
import 'vue-loading-overlay/dist/css/index.css';

const app = createApp(App);

app.config.globalProperties.$filters = {
    toCurrency(value: Number) {
        if (typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
        });
        return formatter.format(value);
    }
}

app.use(createPinia());
app.use(router);
app.use(VueToast);

const lanStore = useLanguageStore();
const { language } = storeToRefs(lanStore);

const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: language.value,
    messages: {
        it: it,
        en: en
    },
})

app.use(i18n);

app.use(VueGoogleMaps, {
    load: {
        key: GOOGLE_API_KEY,
        libraries: "places"
    },
})

const store = useSessionStore();
const { isAuthenticated } = storeToRefs(store);

router.beforeEach((to: any, from: any) => {    
    if (!isAuthenticated.value && to.name !== 'login') return { name: 'login' }
    if (isAuthenticated.value && to.name == 'login') return { name: 'dashboard' }
})

app.mount('#app')
