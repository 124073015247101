import * as api from './api'
import * as constants from './constants';


//Effettuiamo il login e salviamo l'oggetto 'utente' nel Local Storage
async function login(email, password) {
    const action = api.GetAction(constants.API, '/tcl-auth/auth/login', 'POST', {email: email, password: password})
    return api.CallApi(action).then(response => {
        return {isOk: true, data: response.data};
        
    }).catch(err => {
        api.RemoveToken();
        return {isOk: false, data: null};
    });
};

async function login2FA(email, password) {
    const action = api.GetAction(constants.API, '/tcl-auth/auth/login2FA', 'POST', {email: email, password: password})
    return api.CallApi(action).then(response => {
        return {isOk: true, data: response.data, response};

    }).catch(err => {
        api.RemoveToken();
        return {isOk: false, data: null, response: err};
    });
};

async function confirmCode2FA(code, encrToken) {
    const action = api.GetAction(constants.API, '/tcl-auth/auth/confirmCode2FA', 'POST', {code, encrToken})
    return api.CallApi(action).then(response => {
        if (response.data.access_token) {
            api.AddUserToken(response.data)
        }
        return {isOk: true, data: response.data};
    }).catch(err => {
        api.RemoveToken();
        return {isOk: false, data: null};
    });
};

async function logout() {
    const action = api.GetAction(constants.API, '/tcl-auth/user/logout', 'POST', null)
    return api.CallApi(action).then(response => {
        api.RemoveToken();
        return response;
    }).catch(err => {
        api.RemoveToken()
        return false
    }).finally(() => api.RemoveToken())
};

// function parseToken(token) {
//     var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace('-', '+').replace('_', '/');
//     return JSON.parse(window.atob(base64));
// }

export { login, logout, login2FA, confirmCode2FA }