<template>
    <loading 
        v-model:active="isLoading" 
        :is-full-page=true 
        loader="spinner" 
        color="red" />    
    <div class="flex flex-col md:flex-row justify-center mt-12 px-6 dark:bg-zinc-900 dark:text-white">
        <div class="mr-10">
            <HeaderSinistro />
            <h1 class="text-2xl font-bold text-left">Completa il <span class="text-red-600">sinistro</span> stradale</h1>
            <p class="mt-4 opacity-70 w-90">Compila il form con i dati necessari per proseguire.</p>
            <div>
                <button @click="showModal = true" class="p-2 mt-4 bg-gray-500 text-white hover:bg-gray-600 focus:ring focus:ring-gray-300">{{ msgIban }}</button>
                <SelectIbanModal v-if="showModal == true" 
                    :ibans="iban_list"
                    @close-modal="showModal = false" 
                    @send-iban-to-parent="(iban) => assignIban(iban)" 
                />
            </div>
            <div>
                <p class="mb-2 mt-3">Luogo dell'incidente<span class="text-red-600">*</span></p>
                <GoogleMapsAddress which-address="placeDescriptionClaim" @get-place="(place) => getPlace(place)" />
            </div>
            <div>
                <p class="mb-2 mt-3">Descrizione dell'incidente</p>
                <textarea v-model="descriptionClaime" name="descriptionClaime"  class="resize-none h-40 w-96 box-border dark:bg-zinc-700" placeholder="L'incidente è avvenuto..." type="text" maxlength="150" />
            </div>
            <SendClaimModal v-if="showModalToSendClaim"
                @close-modal="showModalToSendClaim = false"
                @send-claim="goToNext"
            />
        </div>
        <div class="my-6 md:my-0">
            <h1 class="text-2xl font-bold text-left">Documenti <span class="text-red-600">obbligatori</span> <span class="underline">{{garanzia}}</span></h1>
            <p class="mt-4 mb-4 w-90">Carica i seguenti documenti obbligatori!</p>

            <div v-if="docsForGuarantees" v-for="(doc, index) in docsForGuarantees">
                <div v-if="doc.mandatory" style="max-width: 200px;">
                    <label class="cursor-pointer mr-4">
                        <div :id="[`div_${ConvertStringToCamelCase(doc.name)}`]" class="p-6 text-center w-18 bg-gray-200 dark:bg-zinc-700">
                            <span class="material-symbols-outlined block">upload</span>
                            <input 
                                :id="[`file_${ConvertStringToCamelCase(doc.name)}_${index}`]"
                                :name="[doc.name]"
                                class="hidden w-40" 
                                type="file" 
                                accept=".png,.jpg,.jpeg,.pdf" 
                                @change="onFileChanged($event)"
                                maxlength="10" />
                        </div>
                        <p>{{doc.name}}</p>
                    </label>
                </div>
            </div>

            <div v-if="(docs.length > 0)" class="mt-4 mb-4 w-90">
                <p class="font-bold wrap">File caricati</p>
                <ol class="list-inside -list-decimal">
                    <li class="mt-1 leading-8 text-gray-800 flex" v-for="(doc, index) in docs">
                        <div class="w-80">{{ doc.name }}<br><p class="text-gray-400 text-sm wrap">{{Truncate(doc.fname, maxLenName)}}</p></div>
                        <div class=""></div>
                        <div class=""><button class="material-symbols-outlined block" @click="deleteRow(index, doc)">delete</button></div>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <div class="w-full md:relative bottom-3" v-show="!showModal && !showModalToSendClaim">
        <div class="flex justify-between mx-12 md:mx-48 lg:mx-80 xl:justify-around 2xl:mx-[36rem]">
            <button @click="$emit('goBack', 4)" class="w-40 p-2 mx-2 mt-12 bg-gray-500 text-white hover:bg-gray-600 focus:ring focus:ring-gray-300">INDIETRO</button>
            <button @click="showModalToSendClaim = true" class="w-40 p-2 mx-2 mt-12 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300">INVIA SINISTRO</button>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import { GetAllIbans } from '@/helpers/iban.service';
    import HeaderSinistro from './HeaderSinistro.vue'
    import { useSinistroStore } from '@/stores/sinistro';
    import { ConvertStringToCamelCase, Truncate, GetLatLong } from '../../helpers/utils';
    import { useToast } from 'vue-toast-notification';
    import * as constants from '@/helpers/constants';
    import GoogleMapsAddress from '../GoogleMapsAddress.vue';
    import { CreateClaim } from '@/helpers/claims.service';
    import { CreateCode } from '@/helpers/utils';
    import SelectIbanModal from '@/components/new_road_accident/SelectIbanModal.vue';
    import SendClaimModal from '../SendClaimModal.vue';
    import Loading from 'vue-loading-overlay';

    const toast = useToast();
    const sinistro = useSinistroStore();

    const emit = defineEmits(['goNext', 'goBack'])
    const msgIban = ref('Seleziona l\'iban per la liquidazione.');

    const isLoading = ref(false);
    const placeClaime = ref({});
    const latitude = ref(0);
    const longitude = ref(0);
    const descriptionClaime = ref('');
    const selected_iban = ref({});

    const docsForGuarantees = ref(sinistro.docsForGuarantees)
    const garanzia = sinistro.selectedGaranzia.name;
    const selectedGaranziaObj = ref(sinistro.selectedGaranzia);
    const docs = ref([]); //{file: 'ciao', code: 'ciao', fname: 'ciao', name: 'ciao'}
    const maxLenName = constants.MaxFileNameShowLen;
    const maxAllowedSize = constants.MaxAllowedFileSize;

    const showModal = ref(false);
    const showModalToSendClaim = ref(false);
    const iban_list = ref([]);

    onMounted(() => {
        getAllIbans();
    })

    async function getAllIbans() {
        GetAllIbans().then(resp => {
            if (resp.status === 200) {
                iban_list.value = resp.data.ibans;

                selectDefaultIbanIfAvailable();
            }
        })
    }

    function selectDefaultIbanIfAvailable() {
        for (let iban of iban_list.value) {
            if (iban['isDefault']) {
                assignIban(iban);
            }
        }
    }

    function isOkMandatoryDocs() {
        // TODO DOVREBBE VALIDARE I DOC PER CODICE
        let cDLoad = docs.value.length;
        let counter = 0;

        for (let element of docsForGuarantees.value.flat()) {
            if (element.mandatory) {
                counter++;
            }
        };
        return cDLoad == counter;
    }

    function canNext() {
        console.log(placeClaime.value);
        if (!placeClaime.value || Object.keys(placeClaime.value).length == 0) {
            toast.error("Inserire il luogo del sinistro.", { position: 'top' });
            return false;
        }

        if (Object.keys(selected_iban.value).length == 0) {
            toast.error("Selezionare un iban.", { position: 'top' });
            return false;
        }

        if (isOkMandatoryDocs()) {
            return true;
        }

        toast.error("Caricare i documenti obbligatori.", {
            position: 'top',
            timeOut: 15000,
        });
        return false;
    }

    function goToNext() {
        if (canNext()) {
            sinistro.setPlaceDescriptionClaim({
                placeClaime: placeClaime.value,
                latitude: latitude.value,
                longitude: longitude.value,
                descriptionClaime: descriptionClaime.value,
            });

            // aggiungo i dettagli aggiuntivi inerenti alla garanzia
            selectedGaranziaObj.value['otherDetails'] = sinistro.otherDetails;

            const payload = {
                contractor: {
                    name: sinistro.datiSinistro.nomeContraente,
                    fiscalCode: sinistro.datiSinistro.codiceFiscaleContraente,
                    email: sinistro.datiSinistro.emailContraente,
                    address: sinistro.datiSinistro.indirizzoContraente,
                    phone: sinistro.datiSinistro.telefonoContraente,
                },
                claim: {
                    eventDate: sinistro.datiSinistro.eventDate,
                    plateNumber: sinistro.datiSinistro.targa,
                    vin: sinistro.datiSinistro.telaio,
                    place: placeClaime.value.formatted_address,
                    description: descriptionClaime.value,
                    latitude: latitude.value,
                    longitude: longitude.value,
                },
                warranty: selectedGaranziaObj.value,
                mandatoryDocuments: docs.value,
                quotation: sinistro.quotation,
                iban: sinistro.assignedIban,
                policyConditions: sinistro.policyCondition,
                isFastApp: sinistro.isFastApp,
            };

            //console.log(JSON.stringify(payload));

            isLoading.value = true;
            CreateClaim(payload).then((res) => {
                if (res.status === 200) {
                    // {"id":"63a48707b95c599053088519","code":"TC022200000004"}
                    sinistro.setClaimId(res.data.code);
                    
                    toast.success("Sinistro creato correttamente!", {
                        position: 'top',
                        timeOut: 15000,
                    });
                    emit('goNext', 6)
                }
            }).catch((err) => {
                toast.error("Errore creazione sinistro :(\n" + err, {
                    position: 'top',
                    timeOut: 15000,
                });
            }).finally(() => {
                isLoading.value = false;
            });            
        }        
    }

    async function getPlace(place) {
        placeClaime.value = place;
        console.log(placeClaime.value);

        await GetLatLong(place.formatted_address).then( res => {
            latitude.value = res.latitude;
            longitude.value = res.longitude;
        })
    }
    
    async function onFileChanged(event) {
        const obj = event.target;
        const name = obj.getAttribute('name');
        const file =  obj.files[0];
        
        if (file.size > maxAllowedSize) {
            let size = file.size;
            size = ( size >>> 20 ) + ',' + ( size & (2*0x3FF ) ) + ' MB ' ;

            toast.error("File troppo grande " + size + "", {
                position: 'top',
                timeOut: 15000,
            });
            return null;
        }

        sinistro.uploadSingleFileAction(file).then((data) => {
            if (data.isOk){

                setFileColorUploaded(name, true);
                docs.value.push({id: data.data.id, fileName: data.data.fileName, code: CreateCode(name), name: name, fname: file.name});
                return
            }

            toast.error("Errore nel caricamento del file " + file.name, {
                position: 'top',
                timeOut: 15000,
            });
        });
        
    }

    function deleteRow(index, doc) {
        docs.value.splice(index, 1); 
        sinistro.removeUploadSingleFileAction(index);
        setFileColorUploaded(doc.name, false);
    }

    function assignIban(iban) {
        sinistro.setAssignedIban(iban);
        selected_iban.value = iban;
        msgIban.value = "IBAN: " + iban.code;
        showModal.value = false;
    }

    function setFileColorUploaded(name, isUploaded) {
        const div = document.getElementById(`div_${ConvertStringToCamelCase(name)}`);

        if (isUploaded) {
            div.classList.remove("bg-gray-200");
            div.classList.add("bg-green-200");
            return
        }

        div.classList.remove("bg-green-200");
        div.classList.add("bg-gray-200");
    }
</script>

<style scoped>
    input, textarea {
        background-color: #EFF0F0;
        border-top: 2px solid rgb(219, 219, 219);
        padding: 7px;
    }

</style>