<template>
    <div class="bg-white shadow p-6 rounded-md dark:bg-zinc-800 dark:text-white">
        <div>
            <div class="flex justify-between items-center mb-6">
                <div class="flex flex-col md:flex-row">
                    <div class="flex">
                        <h1 class="text-xl font-bold">{{tableName}}</h1>
                        <span v-if="!isExpanded" @click="isExpanded = true" class="material-symbols-outlined cursor-pointer p-2 -mt-1">expand_more</span>
                        <span v-else @click="isExpanded = false" class="material-symbols-outlined cursor-pointer p-2 -mt-1">expand_less</span>
                    </div>
                    <div class="flex mt-3 md:mt-0">
                        <Filters v-if="tableName == 'Sinistri'" @apply-filter="(filter) => {filterRows(filter)}" />
                        <button class="flex items-center justify-center px-3 p-2 mx-6 rounded w-40 text-white cursor-pointer hover:bg-red-700 focus:ring focus:ring-red-300 bg-red-600" @click="handleCSVDownload()" id="btn-ScaricaCsv">
                            <span v-if="isLoading == false">Scarica CSV</span>
                            <span v-if="isLoading == true">
                                <svg aria-hidden="true" class="mx-9 w-5 h-5 text-gray-400 animate-spin dark:text-gray-600 fill-slate-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex my-4">
                <div v-for="filter in listOfAppliedFilters" class="flex bg-zinc-200 rounded-full p-2 px-4 mr-4 dark:bg-zinc-700">
                    <p class="text-sm mr-2">{{filter.nameOfFilter}}<span class="font-bold"> = </span>{{filter.filter}}</p>
                    <span @click="removeFilter(filter)" class="material-symbols-outlined text-sm cursor-pointer hover:bg-zinc-300 dark:hover:bg-zinc-600 px-1 rounded">close</span>
                </div>
            </div>
        </div>
        <MazTransitionExpand>
            <div v-if="isExpanded">
                <div v-if="!isEmpty">
                    <div class="overflow-x-auto">
                        <table class="table-auto w-full text-sm font-thin dark:bg-zinc-800 dark:text-white">
                            <thead class="dark:text-white">
                                <tr class="border-gray-300 border-b text-left">
                                    <th v-for="col in colonne" :style="[col == 'STATO' && { width: '180px' }]" class="p-2 px-5">{{col}}</th>
                                </tr>
                            </thead>
                            <tbody class="odd:bg-gray-200 dark:odd:bg-zinc-700 dark:bg-zinc-800 divide-y dark:divide-zinc-700">
                                <tr ref="tr" :id="obj.claimId" v-for="obj, index in displayedData" class="hover:!bg-gray-300 transition cursor-pointer dark:hover:!bg-zinc-900 claim-row">
                                    <td v-if="displayedData[index].stateOfClaim !== 'ToAssign'" @click="handleTickets(obj)"><span class="p-2 rounded hover:bg-zinc-600 hover:text-white material-symbols-outlined">mail</span></td>
                                    <td v-else></td>

                                    <template v-for="value, key in obj">
                                        <td v-if="key === 'stateOfClaim'">
                                            <StateOfClaim :classValue="value" :valueText="lista_filtri.stateOfClaim[value]"  />
                                        </td>
                                        <td v-if="key !== 'stateOfClaimHr' && key !== 'stateOfClaim' && key !== 'isNewClaim'" class="p-2 px-5" @click="handleSinistroPage(obj, 'dettagli')">
                                            <p>{{value != null ? value : '-'}}</p>
                                        </td>
                                        <td v-if="(tableName == 'Da Fare')" class="p-2 px-5"><button class="bg-gray-400 text-white hover:bg-red-600 active:bg-red-800 p-2 px-3 dark:bg-zinc-700">Gestisci</button></td>
                                    </template>
                                    <!-- <td v-if="displayedData[index].stateOfClaim !== 'ToAssign'" @click="handleSinistroPage(obj, 'richieste')"><span class="p-2 rounded hover:bg-zinc-600 hover:text-white material-symbols-outlined">mail</span></td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="multiple-pages" class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 dark:bg-zinc-800 dark:text-white">
                        <div class="sm:flex sm:flex-1 sm:items-center sm:justify-between">
                            <div>
                                <p class="text-sm text-gray-800 dark:text-white">
                                    {{ $t("table.showingFrom") }} <span class="font-medium">{{from}}</span> {{ $t("table.showingTo") }} <span class="font-medium">{{to}}</span> {{ $t("table.showingOf") }} <span class="font-medium">{{rows}}</span> {{ $t("table.showingResults") }}
                                </p>
                            </div>
                            <div class="isolate inline-flex -space-x-px rounded-md justify-center items-center gap-2.5">
                                <p class="mr-6">Pagina {{ page }} di {{ pages }}</p>

                                <div v-if="page > 1" @click="setPage(1)" class="rounded-md p-1.5 text-gray-800 transition cursor-pointer border">
                                    <svg width="15px" height="15px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                    </svg>
                                </div>
                                <div v-else class="rounded p-1.5 text-gray-400 transition cursor-pointer border">
                                    <svg width="15px" height="15px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                    </svg>
                                </div>
                            
                                <div v-if="page > 1" @click="setPage(page - 1)" class="rounded-md p-1.5 text-gray-800 transition cursor-pointer border">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                    </svg>
                                </div>
                                <div v-else class="rounded p-1.5 text-gray-400 transition cursor-pointer border">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                    </svg>
                                </div>

                                <div v-if="page < pages" @click="setPage(page + 1)" class="rounded-md p-1.5 text-gray-800 transition cursor-pointer border">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                    </svg>
                                </div>
                                <div v-else class="rounded p-1.5 text-gray-400 transition cursor-pointer border">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                    </svg>
                                </div>

                                <div v-if="page < pages" @click="setPage(pages)" class="rounded-md p-1.5 text-gray-800 transition cursor-pointer border">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                    </svg>
                                </div>
                                <div v-else class="rounded p-1.5 text-gray-400 transition cursor-pointer border">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="flex justify-center">
                        <img class="w-[10rem]" src="@/assets/svg/nothing_here.svg" alt="Nothing Here" />
                    </div>
                    <p class="text-center">Sembra che qui sia tutto fatto!</p>
                </div>
            </div>
        </MazTransitionExpand>

        <TicketsPopup v-if="showPopupTickets" 
            :claim-id="claimId"
            @close-popup="showPopupTickets = false"
        />
    </div>
</template>

<script setup>
    import { watchEffect, ref, toRaw } from 'vue';
    import router from '@/router';
    import Filters from './filters/Filters.vue';
    import { lista_filtri } from '@/helpers/constants';
    import { CreateCSVFile, generateCSVFileURL } from '@/helpers/file.service';
    import { useRiepilogoSinistroStore } from '@/stores/riepilogoSinistro';
    import StateOfClaim from './StateOfClaim.vue';
    import MazTransitionExpand from 'maz-ui/components/MazTransitionExpand';
    import { useToast } from 'vue-toast-notification';
    import { DownloadFileHandler } from '@/helpers/claims.service';
    import { BASE_URL } from '@/helpers/constants';
    import TicketsPopup from '@/components/messages/TicketsPopup.vue';

    const props = defineProps({
        tableName: String,
        data: Array,
        formattedDataForCSV: Array,
        colonne: Array,
        pagination: Object,
    })

    const emits = defineEmits(['getPaginateClaims', 'getAllClaimsByPage', 'goToMessages'])

    const tableName = ref(props.tableName);
    const colonne = ref(toRaw(props.colonne));
    const displayedData = ref([]);
    const isEmpty = ref(false);
    const isExpanded = ref(props.tableName == 'Sinistri');
    const listOfAppliedFilters = ref([]);

    const pages = ref(0);
    const rows = ref(0);
    const from = ref(0);
    const to = ref(0);
    const page =  ref(1);
    const perPage = 10;

    const isAppliedFilter = ref(false);
    const appliedFilter = ref(undefined);

    const tr = ref(null);
    const isLoading = ref(false)

    const showPopupTickets = ref(false);
    const claimId = ref('');
    
    watchEffect(() => {
        setPagination();
        
        isEmpty.value = false;
        displayedData.value = props.data;
        
        if (displayedData.value.length === 0) {
            isEmpty.value = true;
        }

        showAnimationClaimIfNew();
    })

    function handleSinistroPage(obj, select) {
        const store = useRiepilogoSinistroStore();
        
        props.tableSinistro = obj;
        store.select = select;
        router.push(`/dashboard/${obj.claimId}`)
    }

    function filterRows(filter) {
        page.value = 1;
        appliedFilter.value = filter.filter == '' ? undefined : filter.filter;
        isAppliedFilter.value = true;

        emits("getAllClaimsByPage", page.value, perPage, appliedFilter.value);
    }

    // Remove banners of applied filters
    function removeFilter(filter) {
        const index = listOfAppliedFilters.value.indexOf(filter);
        (index > -1) && listOfAppliedFilters.value.splice(index, 1);
        
        appliedFilter.value = undefined;
        isAppliedFilter.value = false;
    }

    // Set pagination to show the right values for the client
    function setPagination() {
        if (props.pagination !== undefined) {
            pages.value = props.pagination.totalPage;
            rows.value = props.pagination.total;
            from.value = (props.pagination.page * props.pagination.perPage) - props.pagination.perPage + 1;
            to.value = (props.pagination.page * props.pagination.perPage) > rows.value ? rows.value : props.pagination.page * props.pagination.perPage; 
        }  
    }

    // On page changed
    function setPage(event) {
        page.value = event;

        if (isAppliedFilter.value === false) {
            emits('getAllClaimsByPage', page.value, perPage)
            return
        }

        emits('getAllClaimsByPage', page.value, perPage, appliedFilter.value)
    }

    function handleTickets(obj) {
        showPopupTickets.value = !showPopupTickets.value;
        claimId.value = obj.claimId;

        for (let element of document.getElementsByClassName('claim-row')) {
            element.style.backgroundColor = 'white';
        }

        document.getElementById(obj.claimId).style.backgroundColor = '#fecaca';
    }

    // solo con l'ultimo claim creato.
    function showAnimationClaimIfNew() {
        const index = 0;
        const dataExist = displayedData.value !== undefined && displayedData.value.length !== 0;
        
        if (dataExist) {
            const isNewClaim = displayedData.value[index].isNewClaim;
            if (isNewClaim) {
                // prendiamo il primo elemento
                for (let i in tr.value) {
                    const el = tr.value[i];
                    el.animate(
                        [
                            { backgroundColor: 'white' },
                            { backgroundColor: '#fecaca' },
                            { backgroundColor: 'white' },
                        ],
                        {
                            duration: 1100,
                            iterations: 3
                        }
                    );

                    break
                }
            }
        }
    }

    const handleCSVDownload = async () => {
        let URL_DOWNLOAD = ""
        let fileName = ""

        isLoading.value = true
        document.getElementById("btn-ScaricaCsv").setAttribute("disabled", "")

        await new Promise(resolve => setTimeout(resolve, 3000))

        switch (props.tableName) {
            case "Sinistri":
                URL_DOWNLOAD = `${BASE_URL}/tcl-dealer/claim/downloadClaims`
                fileName = "Sinistri.csv"
                break
            default:
                URL_DOWNLOAD = `${BASE_URL}/tcl-dealer/claim/downloadClaims`
                fileName = "Sinistri.csv"
                break
        }

        const toast = useToast();

        try {
            await DownloadFileHandler(URL_DOWNLOAD, "GET", fileName)

        } catch (error) {
            toast.error(`Errore scaricamento: ${error}`, {
                position: 'top',
                timeOut: 15000,
            });

            isLoading.value = false
            document.getElementById("btn-ScaricaCsv").removeAttribute("disabled")
        }

        document.getElementById("btn-ScaricaCsv").removeAttribute("disabled")
        isLoading.value = false
    }
</script>

<style scoped>

</style>