<template>
    <div>
        <div class="bg-white shadow p-6 rounded-md dark:bg-zinc-800 dark:text-white">
            <div class="flex justify-between items-center">
                <div class="flex mb-6">
                    <h1 class="text-xl font-bold">Richieste da fare</h1>
                    <span v-if="!isExpanded" @click="(isExpanded = true)" class="material-symbols-outlined cursor-pointer p-2 -mt-1">expand_more</span>
                    <span v-if="isExpanded" @click="(isExpanded = false)" class="material-symbols-outlined cursor-pointer p-2 -mt-1">expand_less</span>
                </div>
            </div>
            <MazTransitionExpand>
                <div v-if="isExpanded">
                    <div v-if="!isEmpty">
                        <div class="overflow-x-auto">
                            <table class="table-auto w-full text-sm font-thin dark:bg-zinc-800 dark:text-white">
                                <tbody class="divide-y">
                                    <tr class="text-left">
                                        <th v-for="col in ['CODICE', 'DESCRIZIONE', 'ULTIMO MESSAGGIO', 'ORARIO', 'STATO', 'LIQUIDATORE']" class="p-2 px-2">{{ col }}</th>
                                    </tr>
                                    <tr v-for="obj, i in listaDaFare" @click="handleSinistroPage(obj, 'richieste')" class="hover:!bg-gray-300 transition cursor-pointer dark:hover:!bg-zinc-900">
                                        <td class="p-2">{{ obj.claimId }}</td>
                                        <td class="p-2">{{ obj.name }}</td>
                                        <td class="p-2">{{ latestMessages[i] }}</td>
                                        <td class="p-2">{{ latestTimes[i] }}</td>
                                        <StateOfClaim class="mt-1" classValue="ToAssign" :valueText="listOfStatus[i]" />

                                        <td v-if="obj.claim?.utenteLiquidatorClaim" class="p-2">{{ obj.claim.utenteLiquidatorClaim.firstName }} {{ obj.claim.utenteLiquidatorClaim.lastName }}</td>
                                        <td v-else class="p-2">-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="multiple-pages" class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 dark:bg-zinc-800 dark:text-white">
                            <div class="sm:flex sm:flex-1 sm:items-center sm:justify-between">
                                <div>
                                    <p class="text-sm text-gray-800 dark:text-white">
                                        {{ $t("table.showingFrom") }} <span class="font-medium">{{from}}</span> {{ $t("table.showingTo") }} <span class="font-medium">{{to}}</span> {{ $t("table.showingOf") }} <span class="font-medium">{{rows}}</span> {{ $t("table.showingResults") }}
                                    </p>
                                </div>
                                <div class="isolate inline-flex -space-x-px rounded-md justify-center items-center gap-2.5">
                                    <p class="mr-6">Pagina {{ page }} di {{ pages }}</p>

                                    <div v-if="page > 1" @click="setPage(1)" class="rounded-md p-1.5 text-gray-800 transition cursor-pointer border">
                                        <svg width="15px" height="15px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                        </svg>
                                    </div>
                                    <div v-else class="rounded p-1.5 text-gray-400 transition cursor-pointer border">
                                        <svg width="15px" height="15px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                        </svg>
                                    </div>

                                    <div v-if="page > 1" @click="setPage(page - 1)" class="rounded-md p-1.5 text-gray-800 transition cursor-pointer border">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                        </svg>
                                    </div>
                                    <div v-else class="rounded p-1.5 text-gray-400 transition cursor-pointer border">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                        </svg>
                                    </div>

                                    <div v-if="page < pages" @click="setPage(page + 1)" class="rounded-md p-1.5 text-gray-800 transition cursor-pointer border">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </div>
                                    <div v-else class="rounded p-1.5 text-gray-400 transition cursor-pointer border">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </div>

                                    <div v-if="page < pages" @click="setPage(pages)" class="rounded-md p-1.5 text-gray-800 transition cursor-pointer border">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </div>
                                    <div v-else class="rounded p-1.5 text-gray-400 transition cursor-pointer border">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="flex justify-center">
                            <img class="w-[10rem]" src="@/assets/svg/nothing_here.svg" alt="Nothing Here" />
                        </div>
                        <p class="text-center">Sembra che qui sia tutto fatto!</p>
                    </div>
                </div>
            </MazTransitionExpand>
        </div>
    </div>
</template>


<script setup>
    import { ref, onMounted, onUnmounted } from 'vue';
    import { useRiepilogoSinistroStore } from '@/stores/riepilogoSinistro';
    import { GetPaginateTicketsTodo } from '@/helpers/messages.service';
    import MazTransitionExpand from 'maz-ui/components/MazTransitionExpand'
    import router from '@/router';
    import StateOfClaim from './StateOfClaim.vue';
    import { BLOCK_PERIODIC_GET_CLAIM } from '@/helpers/constants';

    const isExpanded = ref(true);
    const isEmpty = ref(false);

    const listaDaFare = ref([]);
    const latestMessages = ref([]);
    const latestTimes = ref([]);
    const listOfStatus = ref([]);

    const pages = ref(0);
    const rows = ref(0);
    const from = ref(0);
    const to = ref(0);
    const page =  ref(1);
    const perPage = 5;

    onMounted(() => {
        getPaginateTicketsTodo(page.value);
        !BLOCK_PERIODIC_GET_CLAIM && getPaginateTicketsTodoEvery2Seconds();
    })

    onUnmounted(() => {
        !BLOCK_PERIODIC_GET_CLAIM && clearInterval(intervalId);
    })

    const getPaginateTicketsTodo = async (p=1) => {
        try {
            const resp = await GetPaginateTicketsTodo(perPage, p);
            const noError = resp.status == 200;

            if (noError) {
                setEmptyValue();
                setPagination(resp.data.tickets.pagination);

                if (!resp.data.tickets || !resp.data.tickets.data) {
                    isEmpty.value = true;
                    return
                }

                isEmpty.value = false;
                listaDaFare.value = resp.data.tickets.data;

                for (let obj of listaDaFare.value) {
                    listOfStatus.value.push('Non completato')
                    let time;

                    if (obj.messages.length > 0) {
                        const t = new Date(obj.messages[Object.keys(obj.messages).length - 1].createAt);
                        time = `${t.getHours()}:${t.getMinutes()} • ${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()}`;

                        latestTimes.value.push(time)
                        latestMessages.value.push(`${obj.messages[Object.keys(obj.messages).length - 1].user.firstName} ${obj.messages[Object.keys(obj.messages).length - 1].user.lastName}: ${obj.messages[Object.keys(obj.messages).length - 1].message !== '' ? obj.messages[Object.keys(obj.messages).length - 1].message : 'Richiesta file'}`)
                        continue
                    }

                    latestTimes.value.push('-');
                    latestMessages.value.push('Ancora nessun messaggio')
                }

                return
            }

        } catch (err) {
            console.log(err);
        }
    }

    // On page changed
    function setPage(p) {
        page.value = p;
        getPaginateTicketsTodo(p);
    }

    function setPagination(pagination) {
        if (pagination) {
            pages.value = pagination.totalPage;
            rows.value = pagination.total;
            from.value = (pagination.page * pagination.perPage) - pagination.perPage + 1;
            to.value = (pagination.page * pagination.perPage) > rows.value ? rows.value : pagination.page * pagination.perPage;
        }
    }

    function setEmptyValue() {
        latestTimes.value = [];
        latestMessages.value = [];
    }

    function handleSinistroPage(obj, select) {
        const store = useRiepilogoSinistroStore();

        store.select = select;
        router.push(`/dashboard/${obj.claimId}`)
    }

    let intervalId;
    function getPaginateTicketsTodoEvery2Seconds() {
        intervalId = setInterval(() => {
            getPaginateTicketsTodo(page.value);
        }, 5000);
    }

</script>

<style scoped>

</style>
