<template>
    <loading 
        v-model:active="isLoading" 
        :is-full-page=true 
        loader="spinner" 
        color="red" />
    <div class="flex flex-col md:flex-row justify-center p-6">
        <div class="mt-20 mr-10">
            <h1 class="text-4xl mb-6 font-bold text-left">Il sinistro <span class="text-red-600">{{CodiceSinistro}}</span><br/> &egrave; stato caricato: </h1>
            <HeaderSinistro />
            <button @click="saveAndExit()" class="w-64 p-2 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300">{{bMsg}}</button>
        </div>
        <div class="mt-20">
            <h1 class="text-2xl font-bold text-left">Documenti <span class="text-red-600">facoltativi</span> <span class="underline">{{garanzia}}</span></h1>
            <p class="mt-4 opacity-70 w-90">Se vuoi puoi iniziare a caricare i documenti facoltativi!</p>

            <div v-if="(docFacoltativi.length > 0)" class="mt-5 w-full">
                <table class="w-full text-center table-auto">
                    <tbody>
                        <tr v-for="rowIdx in Math.ceil(docFacoltativi.length / 2)" :key="rowIdx" style="height:15px;" >
                            <td v-for="(doc, i) in docFacoltativi.slice(2 * (rowIdx - 1), 2 * rowIdx)" style="height:15px;">
                                <div 
                                    :id="[`pDiv_${ConvertStringToCamelCase(doc.name)}_${rowIdx}_${i}`]" 
                                    style="max-width: 200px; min-height: 120px;">
                                    <label :id="[`lbl_${ConvertStringToCamelCase(doc.name)}_${rowIdx}_${i}`]" class="cursor-pointer">
                                        <div :id="[`div_'+ConvertStringToCamelCase(doc.name)_${rowIdx}_${i}`]" class="p-6 text-center bg-gray-200">
                                            <span class="material-symbols-outlined block">upload</span>
                                            <input 
                                                :id="['file_'+ConvertStringToCamelCase(doc.name)+'_'+rowIdx+'_'+i]"
                                                :name="[doc.name]"
                                                class="hidden w-20" 
                                                type="file" 
                                                accept=".png,.jpg,.jpeg,.pdf" 
                                                @change="onFileChanged($event)"
                                                maxlength="10" />
                                        </div>
                                        <p class="opacity opacity-40 text-sm">{{doc.name}}</p>
                                    </label>
                                    <label :id="['lblRm_'+ConvertStringToCamelCase(doc.name)+'_'+rowIdx+'_'+i]" class="cursor-pointer hidden">
                                        <div 
                                            :id="['div_'+ConvertStringToCamelCase(doc.name)+'_'+rowIdx+'_'+i]" 
                                            class="p-6 text-center bg-green-200">
                                            <span class="material-symbols-outlined block">delete</span>
                                            <button 
                                                :id="['btn_'+ConvertStringToCamelCase(doc.name)+'_'+rowIdx+'_'+i]"
                                                :name="[doc.name]"
                                                @click="deleteFile($event)" class="hidden">delete</button>
                                        </div>
                                        <p class="opacity opacity-40 text-sm">{{doc.name}}</p>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> 
        </div>
    </div>
</template>


<script setup>
    import { ref } from 'vue';
    import HeaderSinistro from './HeaderSinistro.vue'
    import { useSinistroStore } from '@/stores/sinistro';
    import router from '@/router';
    import { ConvertStringToCamelCase } from '../../helpers/utils';
    import { useToast } from 'vue-toast-notification';
    import * as constants from '@/helpers/constants';
    import { CreateCode } from '@/helpers/utils';
    import { AddDocumentToClaim } from '@/helpers/claims.service';
    import Loading from 'vue-loading-overlay';

    const toast = useToast();
    const sinistro = useSinistroStore()
    const CodiceSinistro = ref(sinistro.getClaimId());
    const isLoading = ref(false);

    const docsForGuarantees = ref(sinistro.docsForGuarantees)
    const garanzia = sinistro.selectedGaranzia.name;
    const uploadedOptionalDocs = ref([]); //{file: 'ciao', code: 'ciao', fname: 'ciao', name: 'ciao'}
    const maxLenName = constants.MaxFileNameShowLen;
    const maxAllowedSize = constants.MaxAllowedFileSize;
    const bMsg = ref('ESCI SENZA CARICARE DOCUMENTI');

    const docFacoltativi = ref([]);
    for (let element of docsForGuarantees.value.flat()) {
        if (!element.mandatory) {
           docFacoltativi.value.push(element);
        }
    };

    async function onFileChanged(event) {
        const obj = event.target;
        console.log("obj:", obj);
        const name = obj.getAttribute('name');
        const code = obj.id;
        const file =  obj.files[0];

        const idFile = obj.id;
        const idDiv = idFile.replace('file_', 'div_');
        const idLbl = idFile.replace('file_', 'lbl_');
        const idLbRm = idFile.replace('file_', 'lblRm_');

        if (file.size > maxAllowedSize) {
            let size = file.size;
            size = ( size >>> 20 ) + ',' + ( size & (2*0x3FF ) ) + ' MB ' ;
            toast.error("File troppo grande " + size + "", {
                position: 'top',
                timeOut: 15000,
            });
            return null;
        }

        sinistro.uploadSingleFileAction(file).then((data) => {
            if (data.isOk){
                bMsg.value = "SALVA E ESCI";
                const lbl = document.getElementById(idLbl);
                const lblRm = document.getElementById(idLbRm);
                
                lbl.classList.add("hidden");
                lblRm.classList.remove("hidden");

                uploadedOptionalDocs.value.push({localId: clearId(idFile), id: data.data.id, fileName: data.data.fileName, code: CreateCode(name), name: name, fname: file.name});
                console.log("Upload ok", uploadedOptionalDocs.value);
            } else {
                toast.error("Errore nel caricamento del file " + file.name, {
                    position: 'top',
                    timeOut: 15000,
                });
            }
        });
        
    }

    async function deleteFile(event) {
        // const name = obj.getAttribute('name');
        const obj = event.target;
        const id = obj.id;
        
        const indexToRemove = removeFileFromArray(clearId(id));

        if (indexToRemove > -1) {
            uploadedOptionalDocs.value.splice(indexToRemove, 1); 

            const idLbl = id.replace('btn_', 'lbl_');
            const idLblRm = id.replace('btn_', 'lblRm_');

            const lbl = document.getElementById(idLbl);
            const lbRm = document.getElementById(idLblRm);
            
            lbRm.classList.add("hidden");
            lbl.classList.remove("hidden");
        }        
    }

    function removeFileFromArray(id) {
        for (let i=0; i< uploadedOptionalDocs.value.length; i++) 
        {
            if (id != uploadedOptionalDocs.value[i].localId) 
            {
                continue;
            }
            if (id == uploadedOptionalDocs.value[i].localId) 
            {
                return i
            }   
        }
        return -1;
    }

    function clearId(id) {
        let ret = id.replace('file_', '');
        ret = ret.replace('div_', '');
        ret = ret.replace('lbl_', '');
        ret = ret.replace('lblRm_', '');
        ret = ret.replace('btn_', '');
        return ret;
    }


    async function exit() {
        router.push('/dashboard');
    }

    function saveAndExit() {
        const lenFile = uploadedOptionalDocs.value.length;

        if (lenFile > 0) {
            isLoading.value = true;
            const payload = {
                optionalDocuments: uploadedOptionalDocs.value
            };

            AddDocumentToClaim(CodiceSinistro.value, payload).then((resp)=>{
                console.log("OK--->", resp);
                if (resp.status == 200) {
                    exit();
                } else {
                    toast.error("Errore caricamento documenti !\n" + '', {
                        position: 'top',
                        timeOut: 15000,
                    });
                }
            }).catch((err) => {
                toast.error("Errore creazione sinistro!\n" + err, {
                    position: 'top',
                    timeOut: 15000,
                });
            }).finally(()=>{
                isLoading.value = false;
            });
        } else {
            exit();
        }
    }
    
</script>