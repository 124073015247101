<template>
    <div class="flex flex-col items-center justify-center">
        <div>
            <div class="flex mb-4">
                <h1 class="text-4xl mr-4 text-left">Iban registrati</h1>
                <button v-if="isAdmin" @click="isAddingIban = true" class="w-40 mx-2 p-2 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300">Aggiungi Iban</button>
            </div>
            <div class="overflow-x-auto">
                <table v-if="iban_list.length > 0" class="table-auto font-thin dark:bg-zinc-800 dark:text-white">
                    <thead class="dark:text-white">
                        <tr class="border-gray-300 border-b text-left">
                            <th v-for="col in ['Iban', 'Nome']" class="p-2 px-5">{{col}}</th>
                        </tr>
                    </thead>
                    <tbody class="odd:bg-gray-200 dark:odd:bg-zinc-700 dark:bg-zinc-800 divide-y dark:divide-zinc-700">
                        <tr v-for="iban in iban_list" class="hover:!bg-gray-300 transition cursor-pointer dark:hover:!bg-zinc-900">
                            <template v-for="value, key in iban">
                                <td v-if="key == 'note' || key == 'code'" class="p-2 px-5">
                                    <div class="flex items-center" v-if="key == 'code' && iban['isDefault']">
                                        <p class="mr-4">{{ value }}</p>
                                        <StateOfClaim class="text-sm" class-value="Sky" value-text="Predefinito" />
                                    </div>
                                    <p v-else>{{ value }}</p>
                                </td>
                            </template>
                            <td v-if="isAdmin" @click="startEditIban(iban)"><span class="p-2 rounded hover:bg-zinc-600 hover:text-white material-symbols-outlined">edit</span></td>
                            <td v-if="isAdmin" @click="deleteIban(iban._id)"><span class="p-2 rounded hover:bg-zinc-600 hover:text-white material-symbols-outlined">delete</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="isAddingIban || isEditingIban" class="mt-12">
            <div class="flex flex-col">
                <label for="newIbanName">Nome</label>
                <input id="newIbanName" v-model="newIbanName" class="input dark:bg-zinc-700" type="text" placeholder="Iban Sede 1 Roma" />
            </div>
            <div class="flex flex-col mt-2 mb-4">
                <label for="newIban">Iban</label>
                <input id="newIban" v-model="newIban" class="input dark:bg-zinc-700" type="text" placeholder="IT60X0542811101000000123456" />
            </div>
            <div class="flex items-center mt-2 mb-4">
                <label for="newIban">Imposta come predefinito</label>
                <input v-model="isNewIbanDefault" class="border-0 mx-4 w-5 h-5" type="checkbox" />
            </div>
            <div v-if="!isEditingIban">
                <button @click="removeInputIban" class="w-40 p-2 mt-12 mr-16 border-solid border-2 border-black hover:bg-red-500 active:bg-red-600 active:text-white active:border-red-600">Annulla</button>
                <button @click="addIban" class="w-40 mx-2 p-2 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300">Aggiungi Iban</button>
            </div>
            <div v-if="isEditingIban">
                <button  @click="removeInputIban" class="w-40 p-2 mt-12 mr-16 border-solid border-2 border-black hover:bg-red-500 active:bg-red-600 active:text-white active:border-red-600">Annulla</button>
                <button @click="editIban" class="w-40 mx-2 p-2 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300">Modifica Iban</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { GetAllIbans, DeleteIban, AddIban, EditIban, SetDefaultIban } from '@/helpers/iban.service.js';
    import { useToast } from 'vue-toast-notification';

    import { useSessionStore } from '@/stores/session.js';
    import { parseJwt } from '@/helpers/api.js';
    import StateOfClaim from '../StateOfClaim.vue';

    const toast = useToast();
    const iban_list = ref([]);
    const newIban = ref('');
    const newIbanName = ref('');
    const isNewIbanDefault = ref(false);
    const isAddingIban = ref(false);
    const isEditingIban = ref(false);
    const whichIbanIsEditing = ref({});

    const session = useSessionStore();
    const isAdmin = ref(false);

    onMounted(() => {
        getAllIbans();

        const accessTokenPayload = JSON.parse(parseJwt(session.user.access_token));
        checkUserRoles(accessTokenPayload);
    })

    async function getAllIbans() {
        return await GetAllIbans().then(resp => {
            // console.log(resp)
            if (resp.status === 200) {
                iban_list.value = resp.data.ibans;
                console.log(resp.data.ibans);
            }
        })
    }

    async function deleteIban(ibanId) {
        return await DeleteIban(ibanId).then(resp => {
            if (resp.status === 200) {
                toast.success('Iban eliminato correttamente.', {
                    position: 'top'
                });
                getAllIbans();
                return
            }
        }).catch(() => {
            toast.error('Non sei autorizzato a rimuovere questo iban.', {
                position: 'top'
            });
        })
    }

    async function addIban() {
        if (checkInputUser()) {
            const payload = {
                "note": newIbanName.value,
                "code": newIban.value,
                "isDefault": isNewIbanDefault.value,
            }

            return await AddIban(payload).then(resp => {
                if (resp.status === 201) {
                    toast.success('Iban creato correttamente.', {
                        position: 'top'
                    });
                    getAllIbans();
                    removeInputIban();

                    return
                }
            })   
        }
    }

    function startEditIban(iban) {
        isEditingIban.value = true;
        whichIbanIsEditing.value = iban;
        newIban.value = iban.code;
        newIbanName.value = iban.note;
        isNewIbanDefault.value = iban.isDefault;
    }

    async function editIban() {
        // if (isNewIbanDefault) {
        //     SetDefaultIban()
        // }

        if (newIban.value !== '') {
            let payload = {
                "code": newIban.value,
                "isDefault": isNewIbanDefault.value,
            }

            if (newIbanName.value !== '') {
                payload = {
                    "note": newIbanName.value,
                    "code": newIban.value,
                    "isDefault": isNewIbanDefault.value,
                }
            }

            return await EditIban(whichIbanIsEditing.value['_id'], payload).then(resp => {
                if (resp.status === 201) {
                    toast.success('Iban modificato correttamente.', {
                        position: 'top'
                    });
                    getAllIbans();
                    removeInputIban();

                    return
                }
            })   
        }

        toast.error("Inserisci l'iban (27 caratteri).", {
            position: 'top'
        });
    }

    function checkInputUser() {
        if ( newIbanName.value == '' || newIbanName.value.length < 3 ) {
            toast.error("Inserisci il nome dell'iban (min. 3 caratteri).", {
                position: 'top'
            });
            return false
        }
        if ( newIban.value == '' || newIban.value.length !== 27 ) {
            toast.error("Inserisci l'iban (27 caratteri).", {
                position: 'top'
            });
            return false
        }

        return true
    }

    function removeInputIban() {
        isEditingIban.value = false;
        isAddingIban.value = false;
        clearVariables();
    }

    function clearVariables() {
        newIban.value = '';
        newIbanName.value = '';
    }

    function checkUserRoles(payload) {
        for (let obj of payload.roles) {
            if (obj.code === 'admin' || obj.code === 'super_admin') {
                isAdmin.value = true;
                break;
            }
        }
    }

</script>

<style scoped>

</style>