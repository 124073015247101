<template>
    <Modal v-if="isEditingMechanic" @close="isEditingMechanic = false" @confirm="confirmEdit" title="Modifica il valore">
        <template v-slot:body>
            <div>
                <p>Modifica meccanico</p>
                <div class="flex justify-center my-4">
                    <input v-model="text" />
                </div>
            </div>
        </template>
    </Modal>

    <div class="mr-12">
        <h1 class="font-bold text-2xl">{{ title }}</h1>
        <table class="my-6 text-sm dark:bg-zinc-800 dark:text-white divide-y">
            <tr  v-for="item, key in obj" class="text-left relative">
                <th class="p-2 px-2">{{ key }}</th>
                <td v-if="item !== '' && key !== 'Importo da liquidare'" class="p-2 px-2 w-96">{{ item }}</td>
                <td v-if="item == ''" class="p-2 px-2 w-96">-</td>
                <td v-if="key == 'Importo da liquidare'" class="p-2 px-2 w-96">
                    <div class="flex items-center">
                        {{ item }}
                        <StateOfClaim
                            @click="$emit('showModal')"
                            v-if="isAmountEdited"
                            class="absolute left-1/2 top-auto bottom-auto cursor-pointer"
                            class-value="Sky"
                            value-text="Aggiornamento, clicca qui"
                        />
                    </div>
                </td>
                
                <span v-if="key == 'Meccanico'" @click="isEditingMechanic = true" class="cursor-pointer p-2 rounded hover:bg-zinc-600 hover:text-white material-symbols-outlined">edit</span>
                
            </tr>
        </table>
    </div>
</template>

<script setup>
    import { ref, watchEffect } from 'vue';
    import Modal from './Modal.vue';
    import StateOfClaim from './StateOfClaim.vue';

    const emits = defineEmits(['confirmEdit', 'showModal'])
    const props = defineProps({
        obj: Object,
        title: String,
        isAmountEdited: Boolean
    })

    const obj = ref({});
    const title = ref('');
    const isAmountEdited = ref(false);

    watchEffect(() => {
        obj.value = props.obj;
        title.value = props.title;
        isAmountEdited.value = props.isAmountEdited;
    })
    
    const text = ref('');
    const isEditingMechanic = ref(false);
    
    function confirmEdit() {
        isEditingMechanic.value = false;
        emits('confirmEdit', text.value);
    }
</script>

<style scoped>

input {
    background-color: #EFF0F0;
    border-top: 2px solid rgb(219, 219, 219);
    padding: 7px;
}

</style>