import * as api from './api'
import * as constants from './constants';

export const UploadSingleFile = async (file) => {
    const action = api.GetAction(constants.API_FILE_UPLOAD, '/tcl-repo/uploadSingle', 'POST', {file: file});
    const response = await api.CallApi(action);
    return response;
};

export const CreateCSVFile = async (payload) => {
    const action = api.GetAction(constants.API, '/tcl-repo/file/createCSV', 'POST', payload);
    const response = await api.CallApi(action);
    return response;
};

export function generateCSVFileURL(csv) {
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "sinistri.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export function prepareCSVFileFromClaims(data) {        
    let result = [];

    let formattedData = setValuesAvailableInRightFormat(data);
    for (let i = 0; i < formattedData.length; i ++) {
        if (i === 0) {
            let columns = Object.keys(formattedData[i]);
            result.push(columns);
        }

        let values = Object.values(formattedData[i]);
        result.push(values);
    }

    return result
}

export function setValuesAvailableInRightFormat(data) {
    let formattedData = [];

    for (let i = 0; i < data.length; i++) {
        const obj = data[i];
        let formattedObj = {};
        
        formattedObj['Codice'] = obj['claimId'];
        formattedObj['Cliente'] = `${obj['contractorRegistry'].firstName} ${obj['contractorRegistry'].lastName}`;
        formattedObj['Telefono'] = `${obj['contractorRegistry'].mobilePhone} `;
        formattedObj['Codice Fiscale'] = obj['contractorRegistry'].fiscalCode;
        formattedObj['Targa'] = obj['vehicleData'].plateNumber;
        formattedObj['Telaio'] = obj['vehicleData'].vin;
        formattedObj['Data evento'] = obj['eventDate'];
        formattedObj['Segnalato il'] = obj['claimCreateAt'];
        formattedObj['Approvazione Rapida'] = obj['isFastApp'] ? 'Si' : 'No';
        // formattedObj['Importo da liquidare'] = obj['quotation'].amount;
        // formattedObj['Recupero IVA'] = obj['quotation'].deductiblePercent;
        formattedObj['Stato'] = obj['stateOfClaim'];
        formattedObj['Garanzia'] = obj['warranty'].name;
        formattedObj['Segnalatore'] = `${obj['utenteCreateClaim'][0].firstName} ${obj['utenteCreateClaim'][0].lastName}`;
        formattedObj['Liquidatore'] = obj['utenteLiquidatorClaim'][0] ? `${obj['utenteLiquidatorClaim'][0].firstName} ${obj['utenteLiquidatorClaim'][0].lastName}` : '-';
    
        formattedData.push(formattedObj);
    }

    return formattedData
}