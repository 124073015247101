<template>
    <div>
        <div class="w-80 bg-gray-200 dark:bg-zinc-800 p-2 m-2 rounded-md align-between flex">
            <input @keyup="filterDocuments" v-model="search" autocomplete="off" type="text" id="search" name="search" placeholder="Cerca per descrizione" class="bg-gray-200 dark:bg-zinc-800 outline-none" />
            <span class="material-symbols-outlined">search</span>
        </div>
        <div class="bg-white flex justify-center shadow p-6 rounded-md dark:bg-zinc-800 dark:text-white">
            <div class="overflow-x-auto">
                <table class="text-sm font-thin dark:bg-zinc-800 dark:text-white">
                    <thead>
                        <tr class="text-left">
                            <th v-for="value in columns" class="p-2">{{ value }}</th>
                        </tr>
                    </thead>
                    <tbody class="odd:bg-gray-200 dark:odd:bg-zinc-700 dark:bg-zinc-800 divide-y dark:divide-zinc-700">
                        <tr v-for="obj, index in data" class="text-left hover:!bg-gray-300 transition cursor-pointer dark:hover:!bg-zinc-900">
                            <template v-for="value, key in obj">
                                <td v-if="key == 'percentageCost'" class="p-2 w-96">{{ ToMoney(value) }}%</td>
                                <td v-else-if="key == 'hourlyCost'" class="p-2 w-96">{{ ToCurrency(ToMoney(value)) }}</td>
                                <td v-else class="p-2 w-96">{{ value }}</td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { watchEffect, ref } from 'vue';
    import { ToMoney, ToCurrency } from '@/helpers/utils';

    const props = defineProps({
        dataProp: Array,
        columnsProp: Object,
        title: String,
    });

    const data = ref([]);
    const columns = ref([]);
    const search = ref('');

    watchEffect(() => {
        try {
            data.value = props.dataProp;
            columns.value = props.columnsProp;
        } catch (error) {
            console.error(error);
        }
    })

    function filterDocuments() {
        data.value = [];
        for (let obj of props.dataProp) {
            // alcuni oggetti non hanno la description
            const isObjAndIncludesSearch = obj.description !== undefined && ((obj.description).toLowerCase()).includes((search.value).toLowerCase())
            
            if (isObjAndIncludesSearch) {
                if (!containsObject(obj, data.value)) {
                    data.value.push(obj);
                }
            }
        }
    }

    function containsObject(obj, list) {
        for (let i = 0; i < list.length; i++) {
            if (list[i] === obj) {
                return true;
            }
        }
        return false;
    }

</script>

<style scoped>

</style>