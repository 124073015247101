<template>
    <div class="bg-white shadow p-6 rounded-md dark:bg-zinc-800 dark:text-white">
        <div>
            <div class="overflow-x-auto">
                <table class="table-auto w-full text-sm font-thin dark:bg-zinc-800 dark:text-white">
                    <thead class="dark:text-white">
                        <tr class="border-gray-300 border-b text-left">
                            <th v-for="col in colonne" :style="[col == 'STATO' && { width: '180px' }]" class="p-2 px-5">{{ col }}</th>
                        </tr>
                    </thead>
                    <tbody class="odd:bg-gray-200 dark:odd:bg-zinc-700 dark:bg-zinc-800 divide-y dark:divide-zinc-700">
                        <tr ref="tr" :id="`claim_${index}`" v-for="obj, index in displayedData" class="hover:!bg-gray-300 transition cursor-pointer dark:hover:!bg-zinc-900">
                            <template v-for="value, key in obj">
                                <td v-if="key === 'stateOfClaim'">
                                    <StateOfClaim :classValue="value" :valueText="lista_filtri.stateOfClaim[value]"  />
                                </td>
                                <td v-if="key !== 'stateOfClaim' && key !== 'isNewClaim'" class="p-2 px-5" @click="handleSinistroPage(obj, 'dettagli')">
                                    <p>{{value != null ? value : '-'}}</p>
                                </td>
                                <td v-if="(tableName == 'Da Fare')" class="p-2 px-5"><button class="bg-gray-400 text-white hover:bg-red-600 active:bg-red-800 p-2 px-3 dark:bg-zinc-700">Gestisci</button></td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { watchEffect, ref, toRaw } from 'vue';
    import router from '@/router';
    import { lista_filtri } from '@/helpers/constants';
    import { useRiepilogoSinistroStore } from '@/stores/riepilogoSinistro';
    import StateOfClaim from './StateOfClaim.vue';
    import { DisplayedData } from '@/models/models';

    const props = defineProps<{
        tableName?: String,
        data: any[],
        colonne: any[],
    }>()

    const emits = defineEmits(['getPaginateClaims', 'getFilteredClaimsByPage', 'getAllClaimsByPage', 'goToMessages'])

    const tableName = ref(props.tableName);
    const colonne = ref(toRaw(props.colonne));
    const displayedData = ref<DisplayedData[]>([]);

    const tr = ref(null);
    
    watchEffect(() => {        
        displayedData.value = props.data as DisplayedData[];
    })

    // Go to page x
    function handleSinistroPage(obj: DisplayedData, select: string) {
        const store = useRiepilogoSinistroStore();
        
        store.select = select;
        router.push(`/dashboard/${obj.claimId}`)
    }
</script>

<style scoped>

</style>