import { jsPDF } from 'jspdf'

export default function createDiaryPDF(pdfName, claimId, arr) {
    var doc = new jsPDF();
    let positionY = 20;
    
    doc.setFontSize(18);
    doc.text(claimId, 20, positionY);
    positionY += 10;

    doc.setFontSize(12);
    for (let obj of arr) {
        positionY += 10;
        doc.setTextColor(120);
        doc.text(obj.time, 20, positionY);

        positionY += 5;
        doc.setTextColor(0);
        doc.text(obj.message, 20, positionY);
    }

    doc.save(`${pdfName}.pdf`);
}