<template>
        <div class="dark:text-white pt-12">
            <button @click="cancelOperation" v-if="step !== 1 && step !== 6" class="absolute right-24 hover:text-red-600 hover:border-red-600 active:scale-95">Annulla operazione</button>
            <Popup v-if="step === 1" @go-next="(next_step) => step = next_step" />
            <GeneraliGaranzie v-if="step === 2" @go-next="(next_step) => step = next_step" />
            <Garanzie v-if="step === 3" @go-next="(next_step) => step = next_step" @go-back="(step_to_go) => step = step_to_go" />
            <CalcoloCondizioni v-if="step === 4" @go-next="(next_step) => step = next_step" @go-back="(step_to_go) => step = step_to_go" />
            <SinistroStradale v-if="step === 5" @go-next="(next_step) => step = next_step" @go-back="(step_to_go) => step = step_to_go" />
            <SinistroDocumenti v-if="step === 6" @go-next="(next_step) => step = next_step" />
        </div>
</template>

<script setup>
    import Popup from '../components/new_road_accident/Popup.vue';
    import GeneraliGaranzie from '../components/new_road_accident/GeneraliGaranzie.vue';
    import Garanzie from '@/components/new_road_accident/Garanzie.vue';
    import CalcoloCondizioni from '@/components/new_road_accident/CalcoloCondizioni.vue';
    import SinistroStradale from '../components/new_road_accident/SinistroStradale.vue';
    import SinistroDocumenti from '../components/new_road_accident/SinistroDocumenti.vue';
    import { ref } from 'vue'
    import router from '@/router'
    import { useSinistroStore } from '@/stores/sinistro';

    const step = ref(1);
    const sinistro = useSinistroStore();

    function cancelOperation() {
        router.push('/dashboard')
    }
</script>

<style scoped>

</style>