<template>
    <loading
        v-model:active="isLoading"
        :is-full-page=true
        loader="spinner"
        color="red" />

    <div class="w-screen h-2/3 flex justify-center items-center dark:bg-zinc-900 dark:text-white">
        <div class="flex flex-row shadow-2xl p-6 md:p-12 pb-14 pb-18 w-3/4 2xl:mx-96 h-fit dark:bg-zinc-800">
            <div>
                <h1 class="text-xl md:text-4xl w-64 font-bold">{{ $t('popup.createNew') }} <span class="text-red-600">{{ $t('popup.sinistro') }}</span></h1>
                <p class="w-64 md:w-96 opacity-70 mt-4 mb-14">{{ $t('popup.instructions') }}</p>
                <div>
                    <div>
                        <p class="mb-1">{{ $t('popup.date') }}</p>
                        <input ref="datePicker" v-model="data" :style="[data == '' && {color: '#9CA3AF'}]" class="input p-2 mb-4 w-64 dark:bg-zinc-700" type="date" />
                    </div>
                    <div>
                        <p class="mb-1">{{ $t('popup.insertPlateNumber') }}</p>
                        <input @keyup="$event => changeTarga($event)" v-model="targa" maxlength="10" class="input p-2 mb-4 w-64 dark:bg-zinc-700" placeholder="AA123AA" type="text" />
                    </div>
                    <table class="mt-2">
                        <tr>
                            <td class="pr-4"><label for="taxi">Il veicolo è un taxi?</label></td>
                            <td class="pr-4"><input v-model="isTaxi" class="border-0 mr-12 w-5 h-5" name="radio" id="taxi" type="checkbox"></td>
                        </tr>
                        <tr>
                            <td class="pr-4"><label for="vhcomm">È un veicolo commerciale?</label></td>
                            <td class="pr-4"><input v-model="isVhCommercial" class="border-0 mr-12 w-5 h-5" name="radio" id="vhcomm" type="checkbox"></td>
                        </tr>
                        <tr>
                            <td class="pr-4"><label for="fastapp">Approvazione rapida?</label></td>
                            <td class="pr-4"><input v-model="isFastApp" class="border-0 mr-12 w-5 h-5" name="radio" id="fastapp" type="checkbox"></td>
                        </tr>
                    </table>
                </div>
                <button @click="handleGuarantees" class="w-64 p-2 bg-red-500 text-white mt-12 hover:bg-red-600 focus:ring focus:ring-red-300">{{ $t('next') }}</button>
            </div>
            <div>
                <img class="car hidden lg:block" src="../../assets/svg/undraw_by_my_car_re_eyri-2.svg"/>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useSinistroStore } from '@/stores/sinistro';
    import { useToast } from 'vue-toast-notification';
    import Loading from 'vue-loading-overlay';
    import { DEBUG_CREATE_CLAIM } from '@/helpers/constants';

    const emit = defineEmits(['goNext'])
    const store = useSinistroStore();

    let data = ref('');
    let targa = ref('');
    let isTaxi = ref(false);
    let isVhCommercial = ref(false);
    let isVhSubstitute = ref(true);
    let isFastApp = ref(false);
    const datePicker = ref(null);

    if (DEBUG_CREATE_CLAIM) {
        data = ref('2022-12-05');
        targa = ref('GA126ZE');
    }


    const isLoading = ref(false);
    const canGoNext = ref(false);

    store.initStore();
    const toast = useToast();

    onMounted(() => {
        datePicker.value.max = new Date().toISOString().split("T")[0];
    })

    function changeTarga(event) {
        targa.value = event.target.value.toUpperCase();
    }

    async function handleGuarantees() {
        if (checkInputUser()){
            isLoading.value = true;
            const {response, isOk} = await store.getGuaranteesByPlateNumberAction(targa.value.toUpperCase(), data.value)
            isLoading.value = false;

            if (isOk) {
                nextStepHandler();
                return
            }

            toast.error(`Dati non trovati, o copertura scaduta sulla targa ${targa.value}`, {
				position: 'top'
			});
        }
    }

    function nextStepHandler() {
        store.setEventDate(data.value);
        store.setIsTaxi(isTaxi.value);
        store.setIsVhCommercial(isVhCommercial.value);
        store.setIsFastApp(isFastApp.value);
        emit('goNext', 2);
    }

    function checkInputUser() {
        if ( data.value == '' ) {
            toast.error('Inserisci la data del sinistro.', {
                position: 'top'
            });
            return false
        }
        if ( targa.value == '' ) {
            toast.error('Inserisci la targa del veicolo.', {
                position: 'top'
            });
            return false
        }
        if ( isTaxi.value == undefined ) {
            toast.error('Inserisci Taxi si/no.', {
                position: 'top'
            });
            return false
        }
        if ( isVhCommercial.value == undefined ) {
            toast.error('Inserisci Veicolo Commerciale si/no.', {
                position: 'top'
            });
            return false
        }
        if ( isVhSubstitute.value == undefined ) {
            toast.error('Inserisci Veicolo Sostitutivo si/no.', {
                position: 'top'
            });
            return false
        }

        return true
    }
</script>

<style scoped>
    .car {
        padding-top: 100px;
    }
</style>
