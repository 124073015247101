<template>
    <div class="flex">
        <div id="lista-filtri" v-for="obj, nomeFiltro in lista_filtri">
            <select @change="submitFilter(nomeFiltro, $event)" class="p-2 bg-white dark:bg-zinc-700 dark:border-zinc-600 border-solid border border-zinc-300 rounded ml-3 md:ml-6 cursor-pointer w-24 md:w-48">
                <option v-if="nomeFiltro === 'stateOfClaim'" selected="true" class="font-sans" value="">Tutti</option>
                <option v-else selected="true" disabled="disabled" class="font-sans" value="">{{ nomeFiltro }}</option>
                <option v-for="value, key in obj" :value="key" class="font-sans">{{value}}</option>
            </select>
        </div>
    </div>
</template>

<script setup>
    import { lista_filtri } from '@/helpers/constants';

    const emit = defineEmits(['applyFilter'])

    function submitFilter(nomeFiltro, event) {
        console.log(nomeFiltro, event.target.value);
        emit('applyFilter', { 'filter': event.target.value, 'nameOfFilter': nomeFiltro });
    }
</script>

<style scoped>

</style>