<template>
    <div class="absolute">
        <div :style="[{opacity: 1, visibility: 'visible', transition: 'all 0.15s linear'}]" class="modal fixed top-0 left-0 w-full h-full backdrop-blur opacity-0 z-50">
            <div class="modal-content absolute top-1/2 left-1/2 bg-white shadow-2xl py-4 px-6 min-w-[50%] w-fit rounded">
                <div class="flex items-center justify-between">
                    <h1 class="text-2xl font-bold">{{ title }}</h1>
                    <span @click="close" class="material-symbols-outlined float-right cursor-pointer rounded p-2 hover:bg-gray-200 active:bg-gray-400">Close</span>
                </div>
                <hr class="my-4" /> 
                <slot name="body"></slot>
        
                <div class="my-4 flex items-center justify-between">
                    <button type="button" class="" @click="close">Chiudi</button>
                    <button type="button" class="btn-primary px-4" @click="confirm">Conferma</button>
                </div>
            </div>
      </div>
    </div>
</template>

<script setup>
    const emits = defineEmits(['close', 'confirm']);
    const props = defineProps({
        title: String,
    });

    function close() {
        emits('close');
    }

    function confirm() {
        emits('confirm');
    }

</script>

<style scoped>
.modal {
    transition: all 0.15s linear;
    visibility: hidden;
}
.show-modal {
    opacity: 1;
    visibility: visible !important;
    transition: all 0.15s linear;
}
.modal-content {
    transform: translate(-50%, -50%);
}
</style>