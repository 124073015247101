import * as constants from '../helpers/constants'

//Definizione
export default function getInizialiUser() {
    const user = JSON.parse(localStorage.getItem(constants.USER_INFO));

    let fullName = '';
    let email = '';
    let iniziali = '';
    let companyName = '';

    if (user) {
        for (var letter of user.fullName.split(" ")){
            iniziali += letter[0]
        }

        fullName = user.fullName;
        email = user.email;

        companyName = localStorage.getItem(constants.COMPANY_NAME);
    }

    return { iniziali, fullName, email, companyName }
}

export function returnInitials(first, last) {
    return first[0] + last[0]
}