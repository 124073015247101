import { defineStore } from 'pinia'
import * as constants from '@/helpers/constants'

export const useRiepilogoSinistroStore = defineStore('riepilogoSinistro', {
    state: () => ({
        select: 'dettagli'
	}),
    actions: {
        initStore() {
            this.select = 'dettagli';
        },
    }
});
