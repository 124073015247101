import { defineStore } from 'pinia'
import * as constants from '../helpers/constants'

const lan = localStorage.getItem(constants.LANGUAGE);
const initialState = lan 
? { 'language': lan }
: { 'language': 'it' }

export const useLanguageStore = defineStore('language', {
    state: () => (
		initialState
	),
    actions: {
        changeLanguageAction(lan) {
            localStorage.setItem("language", lan);
            this.language = lan;
        }
    }
});
