<template>
	<Header></Header>
	<RouterView />
</template>

<script>
	import { RouterLink, RouterView } from 'vue-router'
	import Header from './components/Header.vue'
	import { onMounted } from 'vue'
	import * as constants from './helpers/constants'
	// import { useLoading } from 'vue-loading-overlay'
    //  // console.log(useLoading)
	//  const $loading = useLoading({
    //     container: true
    //  });
   
   
	export default {
		components: { Header },
		setup() {

			onMounted(() => {
                if (localStorage.theme === constants.THEME_DARK || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
					document.documentElement.classList.add(constants.THEME_DARK)
				} else {
					document.documentElement.classList.remove(constants.THEME_DARK)
				}
            })
		},
	}
</script>


<!-- 
rosso = #FF0022;
grigioHeader = #62626B
grigioScuro = #494950;
grigioPiuChiaro = #DEDFE0;
grigioChiaro = #CECFD0;
grigioChiarissimo = #EFF0F0;
grigioMenoScuro = #6C6C70;
grigioScurissimo = #282830;
verde = #127734;
azzurro = #0995D3;
giallo = #F9AE17;
gialloScuro = #E08B04;
grigioMenoChiaro = #ADADB0;
verde2 = #0F7734;
-->
<style>
  	@import './assets/styles/index.css';
</style>
