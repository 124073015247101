export default function setDisplayedData(data) {
    let temp_data = [];

    if (data !== null) {
        const now = new Date();

        for (let i in data) {
            const claim = data[i];

            let utenteCreateClaim = claim.utenteCreateClaim[0] ? claim.utenteCreateClaim[0].company.name + ' (' + claim.utenteCreateClaim[0].firstName + ' ' + claim.utenteCreateClaim[0].lastName + ')' : "-";
            let NomeUtenteLiquidatorClaim = claim.utenteLiquidatorClaim[0] ? claim.utenteLiquidatorClaim[0].firstName : '-';
            let CognomeUtenteLiquidatorClaim = claim.utenteLiquidatorClaim[0] ? claim.utenteLiquidatorClaim[0].lastName : '';
            let eventDate = new Date(claim.eventDate).toLocaleDateString();
            let createDate = new Date(claim.claimCreateAt).toLocaleDateString();

            const t = new Date(claim.createAt);
            const diffInMinutes = Math.abs((t - now) / 60000);
            const isNewClaim = diffInMinutes < 1;
    
            temp_data.push({
                'claimId': claim.claimId,
                'claimBakend': claim.claimBackend !== null ? claim.claimBackend.code : '-',
                'contractorRegistry.fullname': claim.contractorRegistry.firstName + ' ' + claim.contractorRegistry.lastName,
                'contractorRegistry.mobilePhone': claim.contractorRegistry.mobilePhone,
                'contractorRegistry.fiscalCode/vat': claim.contractorRegistry.fiscalCode !== '' ? claim.contractorRegistry.fiscalCode : '-',
                'vehicleData.plateNumber': claim.vehicleData.plateNumber,
                'eventDate': eventDate,
                'createDate': createDate,
                'stateOfClaim': claim.stateOfClaim,
                'utenteCreateClaim.fullName': utenteCreateClaim,
                'utenteLiquidatorClaim.fullName': NomeUtenteLiquidatorClaim + ' ' + CognomeUtenteLiquidatorClaim,
                'isNewClaim': isNewClaim,
            })
        }
    }

    return temp_data;
}