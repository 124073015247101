<template>
    <div class="pt-24 absolute">
        <div :style="[showModal && {opacity: 1, visibility: 'visible', transition: 'all 0.15s linear'}]" class="modal fixed top-0 left-0 w-full h-full backdrop-blur opacity-0 z-50">
            <div class="modal-content absolute top-1/2 left-1/2 bg-white shadow-2xl py-4 px-6 min-w-[50%] w-fit rounded">
                <div class="flex">
                    <input ref="input" @keydown="$emit('keyDown', $event)" @keyup="$emit('searchData', $event)" type="text" placeholder="Cerca sinistro" class="p-2 mb-2 w-full outline-none" />
                    <!-- <span @click="toggleModal" class="material-symbols-outlined float-right cursor-pointer rounded-full">close</span> -->
                    <span @click="$emit('closeModal')" class="material-symbols-outlined float-right cursor-pointer rounded-full">close</span>
                </div>
                <!-- <div class="mt-12 flex">
                    <button class="w-40 p-2 mr-16 border-solid border-2 border-red-400 hover:bg-red-400 active:bg-red-600 active:text-white active:border-red-600">Cancella</button>
                    <button class="w-40 p-2 border-solid border-2 border-green-400 hover:bg-green-400 active:bg-green-600 active:text-white active:border-green-600">Conferma</button>
                </div> -->
                <div v-if="listOfDataFound != null && listOfDataFound.length > 0" class="font-thin text-sm">
                    <hr class="mb-6" />
                    <table class="mt-3" id="searchList">
                        <thead>
                            <tr class="border-gray-300 border-b text-left">
                                <th class="p-1 px-5 font-thin">Codice</th>
                                <th class="p-1 px-5 font-thin">Targa</th>
                                <th class="p-1 px-5 font-thin">Cliente</th>
                                <th class="p-1 px-5 font-thin">Codice Fiscale</th>
                                <th class="p-1 px-5 font-thin">Email</th>
                                <th class="p-1 px-5 font-thin">Data</th> 
                                <th class="p-1 px-5 font-thin w-44">Stato</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(c, i) in listOfDataFound" class="hover:!bg-gray-300 transition cursor-pointer">
                                <td class="p-2"><a class="underline " :href="'/dashboard/'+c.claimId">{{ c.claimId }}</a></td>
                                <td class="p-2">{{ c.vehicleData.plateNumber }}</td>
                                <td class="p-2">{{  c.contractorRegistry.lastName + ' ' + c.contractorRegistry.firstName }}</td>
                                <td class="p-2">{{  c.contractorRegistry.fiscalCode }}</td>
                                <td class="p-2">{{  c.contractorRegistry.email }}</td>
                                <td class="p-2">{{ new Date(c.eventDate).toLocaleDateString() }}</td>
                                <td class="p-2"><StateOfClaim :classValue="c.stateOfClaim" :valueText="lista_filtri.stateOfClaim[c.stateOfClaim]"  /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, watch, ref, onUpdated, nextTick } from 'vue';
    import StateOfClaim from './StateOfClaim.vue';
    import { lista_filtri } from '@/helpers/constants';

    const emit = defineEmits(['keyDown', 'searchData', 'closeModal']);
    const props = defineProps({
        listSearch: Array,
        // showModalProp: Boolean,
    });
    const listOfDataFound = ref([]);
    const showModal = ref(true);
    const input = ref(null);

    watch(props, () => {
        listOfDataFound.value = props.listSearch
        // showModal.value = props.showModalProp
    })

    // function toggleModal() {
    //     modal.classList.toggle("show-modal");
    // }

    function windowOnClick(event) {
        let modal = document.querySelector(".modal");

        if(event.target === modal) {
            emit('closeModal')
        }
    }

    nextTick(() => {
        input.value.focus();
    })

    window.addEventListener("click", windowOnClick);
</script>

<style scoped>
    .modal {
        transition: all 0.15s linear;
        visibility: hidden;
    }
    .show-modal {
        opacity: 1;
        visibility: visible !important;
        transition: all 0.15s linear;
    }
    .modal-content {
        transform: translate(-50%, -50%);
    }
</style>