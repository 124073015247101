<template>
    <div class="w-full">
        <GenericInputModal v-if="showModal"
            title="Inserisci il titolo della richiesta"
            @close-modal="showModal = false"
            @confirm="(name) => startTicket(name)"
        />
        <div class="flex flex-col items-center" v-if="!isStartedTicket">
            <div class="mb-6 flex flex-col items-center">
                <img class="w-64 lg:w-128" src="@/assets/svg/messages_not_found.svg" />
                <p>Complimenti! Non hai nessuna richiesta.</p>
            </div>
            <button @click="showModal = true" class="w-64 h-12 rounded mt-2 p-2 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300 flex justify-center items-center">
                <span class="material-symbols-outlined">add</span>Nuova richiesta
            </button>
        </div>
        <div v-else class="mx-6 xl:mx-36">
            <div v-if="!isChat">
                <div class="flex justify-between items-center">
                    <button @click="showModal = true" class="w-64 h-12 rounded mt-2 p-2 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300 flex justify-center items-center">
                        <span class="material-symbols-outlined">add</span>Nuova richiesta
                    </button>
                    <RefreshComponent @refresh="() => getTickets(claimId)" />
                </div>
                <ListOfTickets
                    :tickets-prop="tickets"
                    :claimId="claimId"
                    @select-ticket="(ticket) => handleChat(ticket)"
                    @refresh-tickets="getTickets(claimId)"
                />
            </div>
            <div v-else>
                <div class="flex justify-between items-center">
                    <span @click="isChat = false" class="cursor-pointer material-symbols-outlined">arrow_back</span>
                    <!-- <RefreshComponent @refresh="() => getMessages()" /> -->
                </div>
                <ChatInterface
                    :ticketProp="ticket"
                    :claimId="claimId"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, watch, onMounted, onUnmounted } from 'vue';
    // import { useSessionStore } from '@/stores/session.js';
    import ListOfTickets from './ListOfTickets.vue';
    import { CreateTicket, GetTickets, GetMessages } from '@/helpers/messages.service.js';
    import { useToast } from 'vue-toast-notification';
    import GenericInputModal from '../GenericInputModal.vue';
    import ChatInterface from './ChatInterface.vue';
    import RefreshComponent from '../RefreshComponent.vue';
    import { BLOCK_PERIODIC_GET_CLAIM } from '@/helpers/constants';

    // const session = useSessionStore();
    const props = defineProps({
        claimId: String,
    });

    const showModal = ref(false);
    const isStartedTicket = ref(false);
    const isChat = ref(false);
    const tickets = ref([]);
    const ticket = ref({});

    onMounted(() => {
        getTickets(props.claimId);
        !BLOCK_PERIODIC_GET_CLAIM && getMessagesEvery2Seconds();
    })

    onUnmounted(() => {
        !BLOCK_PERIODIC_GET_CLAIM && clearInterval(intervalId);
    });

    watch(props, () => {
        getTickets(props.claimId);
    });

    async function getTickets(claimId) {
        await GetTickets(claimId)
        .then(resp => {
            if (resp.status == 200) {
                tickets.value = resp.data.tickets;

                if (tickets.value) {
                    if (tickets.value.length > 0) {
                        isStartedTicket.value = true;
                    }
                }
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

    async function startTicket(name) {
        showModal.value = false;

        const payload = {
            'name': name,
        };

        await CreateTicket(props.claimId, payload).then(resp => {
            // console.log(resp);
            const statusCode = resp.status;
            if (statusCode === 201) {
                getTickets(props.claimId);
            }
        }).catch(err => {
            const toast = useToast();
            toast.error("C'è stato un problema, riprova.", {
                position: 'top',
            })
            console.log(err);
        });
    }

    function handleChat(ticketObj) {
        isChat.value = true;

        const index = tickets.value.indexOf(ticketObj);
        if (index !== -1) {
            ticket.value = tickets.value[index];
        }
    }

    async function getMessages() {
        if (!isChat.value) {
            return
        }

        const resp = await GetMessages(ticket.value._id);
        if (resp.data) {
            ticket.value.messages = resp.data.messages;
        }
    }

    let intervalId;
    function getMessagesEvery2Seconds() {
        intervalId = setInterval(() => {
            getMessages();
        }, 5000);
    }
</script>

<style scoped>

</style>
