<template>
    <div>
        <div>
            <h1 class="text-3xl text-center">{{ dealerAgreement.description }}</h1>
            <div class="my-4">
                <div class="flex justify-center">
                    <div v-for="option, key in optionsAvailableToSelect" class="min-w-fit w-32 border border-gray-200 mr-4">
                        <input @click="showTable(key)" type="radio" name="option" :value="option" :id="option" class="peer hidden" />
                        <label :style="[key == selectedOption && {backgroundColor: '#ef4444', color: 'white'}]" :for="option" class="block cursor-pointer p-2 text-center">{{ option }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-6 flex flex-col items-center">
            <div @click="isExpandedMaximal = !isExpandedMaximal" class="flex items-center cursor-pointer hover:text-zinc-800">
                <span v-if="!isExpandedMaximal" class="p-2 material-symbols-outlined">chevron_right</span>
                <span v-if="isExpandedMaximal" class="p-2 material-symbols-outlined">expand_more</span>
                <p>Riepilogo costo orario e sconti</p>
            </div>
            <table class="w-80 text-left" v-show="isExpandedMaximal">
                <tr v-for="value, key in objOfOptions">
                    <th v-if="value !== 'Sconti ricambi' && value !== 'Consumo'">Manodopera {{ value }}</th>
                    <th v-if="value == 'Sconti ricambi'">{{ value }}</th>
                    <th v-if="value == 'Consumo'">Materiali {{ value }}</th>
                    <td v-if="key !== 'sparePartsDiscounts'">{{ calculateMaximumHourlyCost(dealerAgreement[key]) }}</td>
                    <td v-else>
                        <span class="mr-2" v-for="discount in calculateMaximumsSparePartsDiscounts(dealerAgreement[key])">
                            {{ discount }}
                        </span>
                    </td>
                </tr>
            </table>
        </div>
        <TableAgreement v-if="isOptionSelected"
            :dataProp="displayedData"
            :columnsProp="displayedColumns"
        />
    </div>
</template>

<script setup>
    import { watchEffect, ref, onMounted } from 'vue';
    import TableAgreement from './TableAgreement.vue';
    import { ToMoney, ToCurrency } from '@/helpers/utils';
    import { GetDealerAgreement } from '@/helpers/company.service';

    const optionsAvailableToSelect = ref([]);
    const isOptionSelected = ref(false);
    const selectedOption = ref('');
    const displayedData = ref([]);
    const displayedColumns = ref([]);
    const isExpandedMaximal = ref(false);

    const dealerAgreement = ref({});

    // ---------- Costanti ----------
    const objOfOptions = {
        'workforce4Parts': 'Carrozzeria',
        'workforce4Province': 'Officina',
        'sparePartsDiscounts': 'Sconti ricambi',
        'consumables': 'Consumo'
    }
    const columns = {
        'workforce4Parts': ['Codice', 'Categoria', 'Costo orario', 'Descrizione'],
        'workforce4Province': ['Codice', 'Provincia', 'Categoria', 'Costo orario', 'Descrizione'],
        'sparePartsDiscounts': ['Codice', 'Categoria', 'Descrizione', 'Sconto %'],
        'consumables': ['Codice', 'Categoria', 'Costo orario', 'Decrizione'],
    }

    onMounted(() => {
        getDealerAgreementHandler();
    })

    watchEffect(() => {
        optionsAvailableToSelect.value = setOptionsAvailableToSelect();
    })

    async function getDealerAgreementHandler() {
        await GetDealerAgreement().then(resp => {
            if (resp.status === 200) {
                dealerAgreement.value = resp.data.agreement[0];
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function setOptionsAvailableToSelect() {
        let data = {};

        for (let key in objOfOptions) {
            const optionIsUndefinedOrEmpty = dealerAgreement.value[key] == undefined || dealerAgreement.value[key].lenght == 0;
            if (!optionIsUndefinedOrEmpty) data[key] = objOfOptions[key];
        }

        return data;
    }

    function showTable(whichData) {
        if (whichData == selectedOption.value) {
            isOptionSelected.value = false;
            selectedOption.value = '';
        } else {
            isOptionSelected.value = true;
            selectedOption.value = whichData;
        }

        displayedData.value = dealerAgreement.value[whichData];
        displayedColumns.value = columns[whichData];
    }

    function calculateMaximumHourlyCost(data) {
        let maximum = 0;

        for (let i in data) {
            maximum = data[i]['hourlyCost'] > maximum ? data[i]['hourlyCost'] : maximum;
        }

        return ToCurrency(ToMoney(maximum))
    }

    function calculateMaximumsSparePartsDiscounts(data) {
        let listOfDiscountsValue = [];
        let listOfMaximumValues = [];
        const n_times = 3;

        // Lista completa dei valori
        for (let i in data) {
            listOfDiscountsValue.push(data[i]['percentageCost']);
        }

        for (let n_time = 0; n_time < n_times; n_time++) {
            let maximum = 0;
            // Prendi il valore maggiore
            for (let index in listOfDiscountsValue) {
                if (maximum < listOfDiscountsValue[index]) maximum = listOfDiscountsValue[index];
            }
    
            // aggiungi il valore maggiore alla lista di massimali da ritornare
            listOfMaximumValues.push(ToMoney(maximum) + '%');
    
            // rimuovi valore dalla lista completa così da cercare il prossimo valore
            for (let i = 0; i < listOfDiscountsValue.length; i++) {
                if (listOfDiscountsValue[i] == maximum) {
                    listOfDiscountsValue.splice(i, 1);
                    i--; 
                }
            }
        }

        return listOfMaximumValues;
    }

</script>

<style scoped>

</style>