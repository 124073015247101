<template>
    <loading 
        v-model:active="isLoading" 
        :is-full-page=true 
        loader="spinner" 
        color="red" />
    <div>
        <div class="mt-12 flex flex-col items-center justify-center">
            <!-- cristalli -->
            <CriterioCondizioni v-if="selectedGaranziaCode == 'cristalli'" 
                v-show="step == 1"
                question="Ripara o sostituisce il vetro?"
                :options="['ripara', 'sostituisce']"
                @send-value="(value) => setRiparaSostituisce(value)"
            />

            <FurtoTotaleIncendio v-if="selectedGaranziaCode == 'furtoTotale' || selectedGaranziaCode == 'incendio'" 
                v-show="step == 1"
                :selectedGaranziaCode="selectedGaranziaCode"
                :ods="ods"
                @change-step-and-calculate="() => changeStepAndCalculate()"
            />
            <div class="flex flex-col items-center" v-show="step == 2">
                <div class="flex">
                    <div class="p-4 px-6 mx-6 rounded-xl shadow">
                        <h1 class="text-lg">Numero sinistri</h1>
                        <p class="text-xs text-gray-600 w-48">Numero di sinistri segnalati nell'anno in corso</p>
                        <h1 v-if="conditBackendResponse.count" class="mt-2 text-3xl">{{ conditBackendResponse.count }}</h1>
                        <h1 v-else class="mt-2 text-3xl">0</h1>
                    </div>
                    <div class="p-4 px-6 mx-6 rounded-xl shadow">
                        <h1 class="text-lg">Totale valore dei sinistri</h1>
                        <p class="text-xs text-gray-600 w-48">Valore totale dei sinistri segnalati nell'anno in corso</p>
                        <h1 v-if="conditBackendResponse.amount" class="mt-2 text-3xl">{{ ToCurrency(conditBackendResponse.amount) }}</h1>
                        <h1 v-else class="mt-2 text-3xl">0,00 €</h1>
                    </div>
                </div>
                <div>
                    <h1 class="text-2xl font-bold my-6"><span class="text-red-600">Condizioni</span> di polizza</h1>
                    <table>
                        <tr v-for="value, key in dettagliGaranzia">
                            <th class="text-left p-1 pr-3">{{ key }}</th>
                            <td class="px-3">{{ value }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="mt-12" v-if="isCalculated && !isLoading" v-show="step == 2">
                <CalcolatoreCristalli v-if="selectedGaranziaCode === 'cristalli' && isCalculated" 
                    @has-done="setIsDoneCalculateCrystal" 
                    :massimaleResiduo="dettagliGaranzia['Massimale per sinistro'] ? policyCondition.maximalForClaim : policyCondition.maximal" 
                    :franchigia="policyCondition.deductible"
                    :decurtazione="policyCondition.reduction"
                />
                <div v-else class="mt-6 flex flex-col">
                    <label v-if="selectedGaranziaCode == 'furtoTotale' || selectedGaranziaCode == 'incendio'" for="importo-riparazione">Importo di liquidazione (iva incl.) (€)</label>
                    <label v-else for="importo-riparazione">Importo di riparazione (iva incl.) (€)</label>
                    <input id="importo-riparazione" @keyup="($event) => setImportoRiparazione($event)" :value="importoDiRiparazione" class="input dark:bg-zinc-700" type="number" />
                </div>
            </div>
        </div>
        <div class="w-full mt-36 mb-12">
            <div class="flex justify-between mx-24 md:mx-48 lg:mx-80 xl:justify-around 2xl:mx-[36rem]">
                <button @click="$emit('goBack', 3)" class="w-40 p-2 mt-6 bg-gray-500 text-white hover:bg-gray-600 focus:ring focus:ring-gray-300">INDIETRO</button>
                <button @click="goNext()" class="w-40 p-2 mt-6 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300">AVANTI</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import CalcolatoreCristalli from './CalcolatoreCristalli.vue';
    import FurtoTotaleIncendio from './guarantees/FurtoTotaleIncendio.vue';
    import Loading from 'vue-loading-overlay';
    import { useSinistroStore } from '@/stores/sinistro';
    import { storeToRefs } from 'pinia';
    import { GetCalculatedCondit } from '@/helpers/claims.service.ts';
    import { ToCents } from '@/helpers/utils';
    import { useToast } from 'vue-toast-notification';
    import CriterioCondizioni from './CriterioCondizioni.vue';
    import { ToCurrency } from '@/helpers/utils';

    const emits = defineEmits(['goNext', 'goBack']);
    const sinistro = useSinistroStore();
    const { ods, policyCondition, otherDetails } = storeToRefs(sinistro);
    
    const toast = useToast();

    const step = ref(1);
    const isLoading = ref(false);
    const isCalculated = ref(false);
    const isDoneCalculateCrystal = ref(false);
    const importoDiRiparazione = ref(0);
    const importoDiRiparazioneLimit = ref(0);
    const selectedGaranziaCode = ref(sinistro.selectedGaranzia.code);
    const isGuaranteeWithConditions = selectedGaranziaCode.value == 'cristalli' || selectedGaranziaCode.value == 'furtoTotale' || selectedGaranziaCode.value == 'incendio';
    
    const ripara_sostituisce = ref(null);
    const isReplace = ref(false);

    // Franchigia = deducibile/deductible
    // Decurtazione = decurtazione
    const dettagliGaranzia = ref({
        'Massimale indennizzabile': 0,
        'Residuo': 0,
        'Scoperto': 0,
        'Franchigia': 0,
        'Deprezzamento ricambi': 0,
    });

    const conditBackendResponse = ref({});

    onMounted(() => {
        step.value = 1;
        handleStepBasedOnGuarantee();
    })

    function calculateConditHandler() {
        if (selectedGaranziaCode.value == 'cristalli' && ripara_sostituisce.value) {
            getCalculatedCondit(sinistro.selectedGaranzia);
            return
        }

        if (selectedGaranziaCode.value !== 'cristalli') {
            getCalculatedCondit(sinistro.selectedGaranzia);
            return
        }

        toast.error('Seleziona prima la condizione', {
            position: 'top',
        })
    }

    function setIsDoneCalculateCrystal() {
        isDoneCalculateCrystal.value = true;
    }

    function setImportoRiparazione(event) {
        importoDiRiparazione.value = parseFloat(event.target.value);
    }

    async function getCalculatedCondit(garanzia) {
        const payload = {
            'eventDate': sinistro.eventDate,
            'plateNumber': sinistro.datiSinistro.targa,
            'warranty': {
                'code': garanzia.code,
                'name': garanzia.name,
                'policy': garanzia.policy,
                'policyType': garanzia.policyType
            },
            'taxi': sinistro.isTaxi,
            "vhlComm": sinistro.isVhCommercial,
            "replacementOfCar": sinistro.isVhSubstitute,
            "typeAOG": sinistro.selectedParameterActOfGod == '' ? 'OTHER' : sinistro.selectedParameterActOfGod,
            "restart": {
                "restart10": sinistro.isRestart10,
                "restart20": sinistro.isRestart20,
                "isRestartSelected": sinistro.isRestartSelected,
            }
        }
        
        isLoading.value = true;
        isCalculated.value = true;

        try {
            const resp = await GetCalculatedCondit(payload);
            if (resp.status == 200 || resp.status == 201) {
                policyCondition.value = {
                    'currentYearInsuredValue': resp.data.currentYearInsuredValue,
                    'maximal': resp.data.maximal,
                    'maximalForClaim': resp.data.maximalForClaim,
                    'deductible': resp.data.deductible,
                    'reduction': resp.data.decurtazione,
                    'overdraft': resp.data.overdraft,
                    'sparePartsDepreciation': resp.data.sparePartsDepreciation
                };
                
                conditBackendResponse.value = {
                    amount: resp.data.conditBackendResponse.amount,
                    backend: resp.data.conditBackendResponse.backend,
                    count: resp.data.conditBackendResponse.count,
                };
                
                setDetailsGuarantee(resp.data);
                
                isLoading.value = false;
                return
            }
        } catch (err) {
            isLoading.value = false;
            sinistro.initStore();
            
            toast.error(`Errore nel calcolo delle condizioni.\n ${err}`, {
                position: 'top'
            });
            
            emits('goBack', 1);
        }
    }

    function goNext() {
        if (checkInputUser()) {
            if (selectedGaranziaCode.value !== 'cristalli') {
                sinistro.setQuotation({
                    'amount': ToCents(importoDiRiparazione.value),
                });
            }

            emits('goNext', 5);
        }
    }

    function checkInputUser() {
        const isCristalliButHasNotCalculated = selectedGaranziaCode.value == 'cristalli' && !isDoneCalculateCrystal.value;
        // const isNotCristalliButHasNotInsertedInput = selectedGaranziaCode.value !== 'cristalli' && importoDiRiparazione.value == 0;

        if (isCristalliButHasNotCalculated) {
            toast.error("Calcola l'importo di riparazione cristalli prima.", {
                position: 'top',
            });
            return false;
        };

        // if (isNotCristalliButHasNotInsertedInput) {
        //     toast.error("Inserisci l'importo di riparazione.", {
        //         position: 'top',
        //     });
        //     return false;
        // }

        // if (importoDiRiparazione.value > importoDiRiparazioneLimit.value) {
        //     toast.error("L'importo di riparazione supera l'ammontare residuo", {
        //         position: 'top',
        //     });
        //     return false;
        // }

        return true;
    }

    function setRiparaSostituisce(value) {
        ripara_sostituisce.value = value;
        otherDetails.value.cristalliDetails.isRepare = value == 'ripara' ? true : false;
        otherDetails.value.cristalliDetails.isReplace = value == 'sostituisce' ? true : false;
        
        changeStepAndCalculate();
    }

    function changeStep(nextStep) {
        step.value = nextStep;
    }

    function changeStepAndCalculate() {
        calculateConditHandler();
        changeStep(2);
    }

    function handleStepBasedOnGuarantee() {
        if (!isGuaranteeWithConditions) {
            changeStepAndCalculate();
            return
        }
    }

    function setDetailsGuarantee(data) {
        importoDiRiparazioneLimit.value = (Number(data.maximal) - conditBackendResponse.value.amount) > 0 ? (Number(data.maximal) - conditBackendResponse.value.amount) : 0; 

        dettagliGaranzia.value = {
            'Massimale indennizzabile': ToCurrency(Number(data.currentYearInsuredValue)),
            'Residuo': ToCurrency(importoDiRiparazioneLimit.value),
            'Scoperto': `${data.overdraft}%`,
            'Deprezzamento ricambi': `${data.sparePartsDepreciation}%`,
            'Franchigia': ToCurrency(Number(data.deductible)), 
        }

        const isFurtoTotaleOrIncendio = selectedGaranziaCode.value == 'furtoTotale' || selectedGaranziaCode.value == 'incendio';
        if (isFurtoTotaleOrIncendio) {
            dettagliGaranzia.value = {
                'Importo assicurato': ToCurrency(Number(data.currentYearInsuredValue)),
                'Importo indennizzabile': ToCurrency(Number(data.maximal)),
                'Restart': ToCurrency(Number(data.restart)),
                'Scoperto': isReplace.value ? `0%` : '10%',
                'Deprezzamento ricambi': `${data.sparePartsDepreciation}%`, // TODO
            }

            otherDetails.value.furtoTotaleIncendioDetails.restartAmount = data.restart;
        }
        
        if (!isGuaranteeWithConditions) {
            delete dettagliGaranzia.value['Deprezzamento ricambi'];
        }
        
        if (data.deductible == -1) {
            delete dettagliGaranzia.value['Franchigia'];
            dettagliGaranzia.value['Decurtazione'] = data.decurtazione;
        }

        if (data.maximalForClaim !== data.maximal && data.maximalForClaim !== 0) {
            dettagliGaranzia.value['Massimale per sinistro'] = data.maximalForClaim;
        }

        // Suggerito da Sandro Spiniello in quanto non vengono utilizzati in questo caso.
        if (ripara_sostituisce.value == 'ripara' && selectedGaranziaCode.value == 'cristalli') {
            dettagliGaranzia.value['Franchigia'] = 0;
            dettagliGaranzia.value['Decurtazione'] = 0;
        }
    }

</script>

<style scoped>
</style>
