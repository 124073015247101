<template>
    <GMapAutocomplete
        id="gmap-autocomplete"
        class="input w-80 md:w-96 dark:bg-zinc-700"
        placeholder="Digita l'indirizzo e clicca tra quelli forniti"
        @place_changed="(address) => getPlace(address)"
        >
    </GMapAutocomplete>
</template>

<script setup>
    import { useSinistroStore } from '@/stores/sinistro';
    import { onMounted } from 'vue';

    const store = useSinistroStore();
    const emits = defineEmits(['getPlace']);
    const props = defineProps({
        addressFound: String,
        whichAddress: String,
    });

    onMounted(() => {
        if (props.addressFound && props.addressFound != "") {
            document.getElementById("gmap-autocomplete").value = props.addressFound;
        }
    });

    function getPlace(address) {
        store[props.whichAddress].returnedPlace = address;
        store[props.whichAddress].placeClaime = address.formatted_address;

        //Per comunicare con il parent. Quindi ogni componente che lo chiama deve avere il parametro @get-place
        emits('getPlace', address);
    }
</script>