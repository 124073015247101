<template>
    <div class="mr-12">
        <h1 class="font-bold text-2xl">{{ title }}</h1>
        <table class="my-6 text-sm dark:bg-zinc-800 dark:text-white divide-y">
            <tr v-for="item, key in obj" class="text-left">
                <th class="p-2 px-2">{{ key }}</th>
                <td v-if="item !== '' && key !== 'Approvazione Rapida'" class="p-2 px-2 w-96">{{ item }}</td>
                <td v-if="item == ''" class="p-2 px-2 w-96">-</td>
                <td v-if="key == 'Approvazione Rapida'" class="p-2 px-2 w-96">
                    <p v-if="item == 'true'">Si</p>
                    <p v-else>No</p>
                </td>
                <div>
                    <span v-if="key == 'Targa'" @click="copyText(item)" class="cursor-pointer p-1 rounded hover:bg-zinc-600 hover:text-white material-symbols-outlined" title="Copia">content_copy</span>
                    <span v-if="key == 'Telaio'" @click="copyText(item)" class="cursor-pointer p-1 rounded hover:bg-zinc-600 hover:text-white material-symbols-outlined" title="Copia">content_copy</span>
                </div>
            </tr>
        </table>
    </div>
</template>

<script setup>
    import { useToast } from 'vue-toast-notification';

    const props = defineProps({
        obj: Object,
        title: String,
    });

      const copyText = async (text) => {
        const toast = useToast()
        try {
            await navigator.clipboard.writeText(text);
            toast.success("Copiato.")
        } catch (error) {
            toast.error("Non è stato possibile copiarlo.")
            console.error(error);
        }
    }
</script>

<style scoped>

</style>