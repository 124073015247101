<template>
    <div>
        <h1 class="text-lg md:text-3xl font-bold mb-12 mx-6">Carica i seguenti <span class="text-red-600">documenti facoltativi</span></h1>
        <UploadFileComponent
            :claimId="claimId"
            :docsForGuaranteesProps="filteredDocuments"
        />
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import { getDocsFromGaranzia } from '@/helpers/user.service';
    import UploadFileComponent from './UploadFileComponent.vue';

    const props = defineProps({
        uploadedDocuments: Array,
        warranty: Object,
        claimId: String,
    })

    const search = ref('');
    const filteredDocuments = ref([]);
    const documentsLeftToUpload = ref([]);

    let documentsAll = [];
    onMounted(() => {
        getAllDocs('apertura', props.warranty.code).then(docs => {
            documentsAll = docs;
            checkDocumentsLeftToUpload();
            
            filteredDocuments.value = documentsLeftToUpload.value;
        })  
    })

    
    async function getAllDocs(fase, garanzia) {
        return await getDocsFromGaranzia(fase, garanzia).then(resp => {
            if (resp.status === 200) {
                return resp.data.docs;
            }
        })
    }

    function checkDocumentsLeftToUpload() {
        for (let index = 0; index < documentsAll.length; index++) {
            const fileObjOfAllDocuments = documentsAll[index];

            for (let i = 0; i < props.uploadedDocuments.length; i++) {
                const fileObjOfUploadedDocuments = props.uploadedDocuments[i];

                if (fileObjOfUploadedDocuments.name == fileObjOfAllDocuments.name) break;
                if (fileObjOfUploadedDocuments.name !== fileObjOfAllDocuments.name && i == props.uploadedDocuments.length-1) {
                    documentsLeftToUpload.value.push(fileObjOfAllDocuments);
                    break;
                }
            }
        }
    }

    // Possiamo filtrare i documenti da Frontend in quanto il numero di documenti caricabili è limitato
    function filterDocuments() {
        filteredDocuments.value = [];
        for (let obj of documentsLeftToUpload.value) {
            if (((obj.name).toLowerCase()).includes((search.value).toLowerCase())) {
                if (!containsObject(obj, filteredDocuments.value)) {
                    filteredDocuments.value.push(obj);
                }
            }
        }
    }

    // Determina se l'oggetto è già presente nella lista così da non far aggiungere lo stesso oggetto più volte
    function containsObject(obj, list) {
        for (let i = 0; i < list.length; i++) {
            if (list[i] === obj) {
                return true;
            }
        }
        return false;
    }

</script>

<style scoped>

</style>