import * as api from './api'
import * as constants from './constants';


export const GetCompanyByUserId = async () => {
    const action = api.GetAction(constants.API, '/tcl-auth/user/getCompanyByUserId', 'GET', null);
    const response = await api.CallApi(action);
    return response;
};

export const GetDealerAgreement = async () => {
    const action = api.GetAction(constants.API, '/tcl-dealer/dealerAgreement', 'GET');
    const response = await api.CallApi(action);
    return response;
};