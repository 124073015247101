<template>
    <loading 
        v-model:active="isLoading" 
        :is-full-page=true 
        loader="spinner" 
        color="red" />
    <form class="col-lg-6 col-sm-12">
        <div @click="showModal = true" class="align-center bg-gray-200 p-2 w-fit rounded-md md:mr-4 xl:mr-8 flex">
            <input autocomplete="off" type="text" id="search" name="search" placeholder="Cerca..." class="bg-gray-200 outline-none w-32 md:w-64 xl:w-96" />
            <span class="material-symbols-outlined cursor-pointer hover:text-red-500">search</span>
        </div>
        <ModalDialog v-if="showModal" :showModalProp="showModal" :listSearch="listSearch" @key-down="(e) => keyDown(e)" @search-data="(e) => searchData(e)" @closeModal="showModal = false" />
    </form>
</template>

<script setup>
    import { ref } from "vue";
    import { SearchClaims } from '@/helpers/claims.service'
    import Loading from 'vue-loading-overlay';
    import ModalDialog from '@/components/ModalDialog.vue';


    let listSearch = ref([]);
    let isLoading = ref(false);
    const showModal = ref(false);

    function keyDown(event) {
        if (event.keyCode == 13) {
            event.preventDefault();
        }
    }

    function searchData(event) {
        event.preventDefault();
        const str = event.target.value.trim()
        
        if (str.length < 1) {
            listSearch.value = [];
            isLoading.value = false;

            return
        }

        if (!isLoading.value) {
            setTimeout(async () => {
                const resp = await SearchClaims(str);
                if (resp.status == 200) {
                    listSearch.value = resp.data.claims.data;
                }
            }, 10);
        }
    }

</script>

<style scoped>
</style>