import { defineStore } from 'pinia'
import { GetCompanyByUserId } from '@/helpers/company.service'
import * as constants from '@/helpers/constants'

export const useCompanyStore = defineStore('company', {
    state: () => ({
        company: {},
        companyName: '',
        companyIsSet: false
	}),
    actions: {
        initStore() {
            this.company = {};
            this.companyName = '';
            this.companyIsSet = false;
        },
        SetCompanyAction() {
            //console.log('SetCompanyAction');
            GetCompanyByUserId().then((resp) => {
                if (resp.status === 200) {
                    //console.log(resp.data.company)
                    this.company = resp.data.company;
                    this.companyIsSet = true;
                    this.companyName = this.company.name;
                    localStorage.setItem(constants.COMPANY_NAME, this.company.name)
                    //console.log(this.companyName)
                }
            }).catch(err => {
                this.companyIsSet = false;
            });
        }
    }
});
