<template>
    <div class="w-full h-screen bg-slate-100 dark:bg-zinc-900">
        <div class="p-6">
            <TableTicketsToDo />
        </div>
        <div class="px-6">
            <TableNew
                :tableName="$t('sinistriName')"
                :data="listaSinistri"
                :colonne="['#', $t('table.columns.sinistri.code'), $t('table.columns.sinistri.backendCode'), $t('table.columns.sinistri.client'), $t('table.columns.sinistri.phone'), $t('table.columns.sinistri.fiscalCode'), $t('table.columns.sinistri.plateNumber'), $t('table.columns.sinistri.eventDate'), $t('table.columns.sinistri.createDate'), $t('table.columns.sinistri.status'), $t('table.columns.sinistri.reportingUser'), $t('table.columns.sinistri.liquidatorUser')]"
                :pagination="pagination"
                :formattedDataForCSV="formattedDataForCSV"

                @get-all-claims-by-page="(page: number, perPage: number, filter?: string) => getFilteredPaginateClaims(page, perPage, filter)"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
    import TableNew from '@/components/TableNew.vue';
    import { ref, onMounted, onUnmounted } from 'vue';
    import { GetFilteredPageClaims } from '@/helpers/claims.service'
    import setDisplayedData from '@/utilities/setDisplayedDataClaims';
    import TableTicketsToDo from '@/components/TableTicketsToDo.vue';

    import { TableClaim, Pagination } from '@/models/Claims';
    import { prepareCSVFileFromClaims } from '@/helpers/file.service';

    import { BLOCK_PERIODIC_GET_CLAIM } from '@/helpers/constants';

    const listaSinistri = ref<TableClaim[]>([]);
    const formattedDataForCSV = ref<string[][]>([]);
    const pagination = ref<Pagination | any>({});

    const page =  ref(1);
    const perPage = 10;

    const isAppliedFilter = ref(false);
    const appliedFilter = ref<string | undefined>(undefined);

    onMounted(() => {
        getFilteredPaginateClaims(page.value, perPage);
        !BLOCK_PERIODIC_GET_CLAIM && getPaginateClaimsEvery2Seconds();
    })

    onUnmounted(() => {
        !BLOCK_PERIODIC_GET_CLAIM && clearInterval(intervalId);
    });

    // Get filtered claims based on which page
    async function getFilteredPaginateClaims(pageNumber: number, perPageNumber: number, filter?: string) {
        if (filter && filter != "") {
            appliedFilter.value = filter;
            isAppliedFilter.value = true;
        } else {
            appliedFilter.value = undefined;
            isAppliedFilter.value = false;
        }

        const resp = await GetFilteredPageClaims(pageNumber, perPageNumber, appliedFilter.value);
        if (resp.status === 200) {
            pagination.value = resp.data.claims.pagination;
            page.value = pagination.value.page;

            formattedDataForCSV.value = prepareCSVFileFromClaims(resp.data.claims.data);
            listaSinistri.value = setDisplayedData(resp.data.claims.data);
        }
    }

    let intervalId: number;
    function getPaginateClaimsEvery2Seconds() {
        intervalId = setInterval(async () => {
            await getFilteredPaginateClaims(page.value, perPage, appliedFilter.value);
        }, 5000);
    }
</script>

<style scoped>

</style>
