<template>
    <div class="text-sm">
        <div>
            <h2 class="text-4xl font-bold">{{ NomeContraente }}</h2>
            <!-- <div class="mx-4">
                <p class="text-gray-800">{{ emailContraente }}</p>
                <p class="text-gray-800">{{ telefonoContraente }}</p>
            </div> -->
            <div class="p-4 grid grid-cols-2 gap-4">
                <div>
                    <p>Targa</p>
                    <p class="text-xl font-bold">{{ targa }}</p>
                </div>
                <div>
                    <p>Data sinistro</p>
                    <p class="text-xl font-bold">{{ data }}</p>
                </div>
                <div v-if="sinistro.selectedGaranzia.name !== undefined">
                    <p>Garanzia</p>
                    <p class="text-xl font-bold">{{ sinistro.selectedGaranzia.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { useSinistroStore } from '@/stores/sinistro';
    import { ref, onMounted } from 'vue';
    import { storeToRefs } from 'pinia';

    const sinistro = useSinistroStore()
    const targa = sinistro.datiSinistro.targa;
    
    const data = `${sinistro.datiSinistro.eventDate.substring(8, 10)}/${sinistro.datiSinistro.eventDate.substring(5, 7)}/${sinistro.datiSinistro.eventDate.substring(0, 4)}`;
    const NomeContraente = sinistro.datiSinistro.nomeContraente;

    const emailContraente = ref(sinistro.datiSinistro.emailContraente);
    const telefonoContraente = ref(sinistro.datiSinistro.telefonoContraente);
    const place = storeToRefs(sinistro.placeDescriptionClaim);

    // onMounted(() => {
    //     sinistro.selectedGaranzia = {};
    // })
    
</script>
<style scoped>
    #infoBase {
        border-radius: 6px;
        border: 1px solid black;
        padding: 10px;
        text-align: center;
    }
</style>