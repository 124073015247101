<template>
    <div class="pt-24 absolute">
        <div :style="[showModal && {opacity: 1, visibility: 'visible', transition: 'all 0.15s linear'}]" class="modal fixed top-0 left-0 w-full h-full backdrop-blur opacity-0">
            <div class="modal-content absolute top-1/2 left-1/2 bg-white shadow-2xl py-4 px-6 min-w-[50%] w-fit rounded dark:bg-zinc-800">
                <div class="flex mb-4">
                    <span @click="$emit('closeModal')" class="material-symbols-outlined float-right cursor-pointer rounded-full">close</span>
                </div>
                <div v-for="iban in iban_list" class="p-2 hover:bg-gray-200 dark:hover:bg-zinc-900 rounded">
                    <label class="text-lg flex">
                        <input class="mr-2" type="radio" name="radio" :value="iban" v-model="selected_iban" />
                        <div>
                            <p class="text-xl font-semibold">{{ iban.note }}</p>
                            <p class="text-gray-500">{{ iban.code }}</p>
                        </div>
                        <br>
                    </label>
                </div>
                <button @click="assingIban" class="w-40 p-2 bg-red-500 text-white float-right mt-4 hover:bg-red-600 focus:ring focus:ring-red-300">Seleziona</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    // import { GetAllIbans } from '@/helpers/iban.service';
    import { useSinistroStore } from '@/stores/sinistro';
    import { watchEffect, ref } from 'vue';

    const props = defineProps({
        ibans: Array,
    });
    const emits = defineEmits(['sendIbanToParent', 'closeModal']);
    const sinistro = useSinistroStore();

    const showModal = ref(true);
    const iban_list = ref([]);
    const selected_iban = ref({});

    watchEffect(() => {
        iban_list.value = props.ibans;
    })

    // async function getAllIbans() {
    //     return await GetAllIbans().then(resp => {
    //         if (resp.status === 200) {
    //             iban_list.value = resp.data.ibans;
    //         }
    //     })
    // }

    function assingIban() {
        if (selected_iban.value._id == undefined) {
            return
        }
        
        emits('sendIbanToParent', selected_iban.value);
	}

    function windowOnClick(event) {
        let modal = document.querySelector(".modal");

        if(event.target === modal) {
            emits('closeModal')
        }
    }
    window.addEventListener("click", windowOnClick);
</script>

<style scoped>
    .modal {
        transition: all 0.15s linear;
        visibility: hidden;
    }
    .show-modal {
        opacity: 1;
        visibility: visible !important;
        transition: all 0.15s linear;
    }
    .modal-content {
        transform: translate(-50%, -50%);
    }
</style>