<template>
    <div class="flex flex-col items-center">
        <div class="w-full">
            <button v-if="uploadedOptionalDocs.length == 0" @click="sendWarningNoDocuments()" class="w-64 p-2 ml-6 bg-gray-500 text-white hover:bg-gray-600 focus:ring focus:ring-gray-300">Carica documenti</button>
            <button v-else @click="saveAndExit()" class="w-64 p-2 ml-6 float-left bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300">{{ uploadMessageButton }}</button>
        </div>
        <div class="mt-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
            <div v-for="(doc, index) in docsForGuarantees" class="card rounded shadow w-64 p-4 flex flex-col justify-center dark:bg-zinc-800">
                <div class="mb-4">
                    <span v-if="doc.mimeType == 'application/pdf'" class="p-2 pb-1.5 mb-2 bg-rose-200 text-rose-600 rounded material-symbols-outlined">description</span>
                    <span v-else class="p-2 pb-1.5 mb-2 bg-rose-200 text-rose-600 rounded material-symbols-outlined">image</span>
                    <h1 class="text-xl font-bold">{{doc.name}}<br></h1>
                </div>
                <label class="cursor-pointer mr-4">
                    <div :id="['div_'+ConvertStringToCamelCase(doc.name)+'_'+index]" class="flex rounded p-2 px-4 mx-12 text-center border-solid border-2 border-green-500 text-green-500 cursor-pointer hover:bg-green-100 dark:hover:bg-green-900 dark:hover:text-green-100 active:bg-green-700 active:text-white">
                        <span class="material-symbols-outlined block">upload</span>
                        <input 
                            :id="['file_'+ConvertStringToCamelCase(doc.name)+'_'+index]"
                            :name="[doc.name]"
                            class="hidden w-40" 
                            type="file" 
                            accept=".png,.jpg,.jpeg,.pdf" 
                            @change="onFileChanged($event)"
                            maxlength="10" />
                        <p>Carica</p>
                    </div>
                </label>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, watch } from 'vue';
    import router from '@/router';
    import { ConvertStringToCamelCase } from '@/helpers/utils.ts';
    import * as constants from '@/helpers/constants';
    import { useToast } from 'vue-toast-notification';
    import { useSinistroStore } from '@/stores/sinistro';
    import { CreateCode } from '@/helpers/utils';
    import { AddDocumentToClaim } from '@/helpers/claims.service';

    const sinistro = useSinistroStore();
    const toast = useToast();
    const props = defineProps({
        docsForGuaranteesProps: Array,
        claimId: String,
    });

    const maxAllowedSize = constants.MaxAllowedFileSize;
    const docsForGuarantees = ref([]);
    const uploadedOptionalDocs = ref([]);
    const uploadMessageButton = ref('Carica documenti')
    
    onMounted(() => {
        docsForGuarantees.value = props.docsForGuaranteesProps;
    })
    
    watch(props, () => {
        docsForGuarantees.value = props.docsForGuaranteesProps;
        console.log("documenti:", docsForGuarantees.value);
    })

    async function onFileChanged(event) {
        const obj = event.target;
        const name = obj.getAttribute('name');
        const code = obj.id;
        const file =  obj.files[0];

        const idFile = obj.id;
        const idDiv = idFile.replace('file_', 'div_')
        
        if (file.size > maxAllowedSize) {
            let size = file.size;
            size = ( size >>> 20 ) + ',' + ( size & (2*0x3FF ) ) + ' MB ' ;
            toast.error("File troppo grande " + size + "", {
                position: 'top',
                timeOut: 15000,
            });
            return null;
        }

        sinistro.uploadSingleFileAction(file).then((data) => {
            if (data.isOk){
                const div = document.getElementById(idDiv);
                div.classList.remove("bg-gray-200");
                div.classList.add("bg-green-200");

                uploadedOptionalDocs.value.push({id: data.data.id, fileName: data.data.fileName, code: CreateCode(name), name: name, fname: file.name});
                setMessageButton()
            } else {
                toast.error("Errore nel caricamento del file " + file.name, {
                    position: 'top',
                    timeOut: 15000,
                });
            }
        });
        
    }

    function setMessageButton() {
        if (uploadedOptionalDocs.value.length == 1) {
            uploadMessageButton.value = `Carica 1 documento`
            return
        }

        uploadMessageButton.value = `Carica ${uploadedOptionalDocs.value.length} documenti`
        return
    }

    function saveAndExit() {
        const lenFile = uploadedOptionalDocs.value.length;

        if (lenFile > 0) {
            const payload = {
                optionalDocuments: uploadedOptionalDocs.value
            };

            AddDocumentToClaim(props.claimId, payload).then((resp)=>{
                if (resp.status == 200) {
                    toast.success("Documenti aggiunti correttamente", {
                        position: 'top',
                        timeOut: 15000,
                    });

                    router.replace(`/dashboard`)
                    return
                }

                toast.error("Errore caricamento documenti", {
                    position: 'top',
                    timeOut: 15000,
                });

            }).catch((err) => {
                console.log("err--->", err);
            })
        } else {
            toast.warning("Carica almeno un documento per procedere.", {
                position: 'top',
                timeOut: 15000,
            });
        }
    }

    function sendWarningNoDocuments() {
        toast.warning("Carica almeno un documento per procedere.", {
            position: 'top',
            timeOut: 15000,
        });
        return
    }

    async function deleteFile(event) {
        const obj = event.target;
        const name = obj.getAttribute('name');
        const id = obj.id;
        
        const indexToRemove = removeFileFromArray(clearId(id));

        console.log("indexToRemove", indexToRemove)

        if (indexToRemove > -1) {
            uploadedOptionalDocs.value.splice(indexToRemove, 1); 

            const idLbl = id.replace('btn_', 'lbl_');
            const idLblRm = id.replace('btn_', 'lblRm_');

            const lbl = document.getElementById(idLbl);
            const lbRm = document.getElementById(idLblRm);
            
            lbRm.classList.add("hidden");
            lbl.classList.remove("hidden");
        }

        
    }

    function removeFileFromArray(id) {
        //console.log("removeFileFromArray --> id", id)
        for (let i=0; i< uploadedOptionalDocs.value.length; i++) 
        {
            if (id != uploadedOptionalDocs.value[i].localId) 
            {
                continue;
            }
            if (id == uploadedOptionalDocs.value[i].localId) 
            {
                //console.log("Trovato", id, i)
                return i
            }   
        }
        return -1;
    }

    function clearId(id) {
        let ret = id.replace('file_', '');
        ret = ret.replace('div_', '');
        ret = ret.replace('lbl_', '');
        ret = ret.replace('lblRm_', '');
        ret = ret.replace('btn_', '');
        return ret;
    }

</script>

<style scoped>

</style>