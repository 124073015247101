<template>
	<ModalNoButtons v-if="showModal" @close="showModal = false" @confirm="showModal = false" title="L'importo di liquidazione è stato modificato">
        <template v-slot:body>
            <div>
                <p class="text-gray-500 text-sm">{{ Number(data.quotation.updateAt.substring(8, 10)) }} {{ listOfMonths[Number(data.quotation.updateAt.substring(5, 7))-1] }} {{ data.quotation.updateAt.substring(0, 4) }}, {{ data.quotation.updateAt.substring(11, 16) }}</p>
                <div class="mt-3 mb-6">
                    <p v-if="data.quotation.notes !== ''">{{ data.quotation.notes }}</p>
                    <p v-else>Non è stata inserita alcuna nota nella modifica dell'importo</p>
                </div>
            </div>
        </template>
    </ModalNoButtons>
    <div class="pt-6 px-4 lg:px-12 dark:bg-zinc-900 dark:text-white">
		<div>
			<div class="flex flex-col md:flex-row mx-6 md:mx-0">
				<h1 class="text-lg font-bold md:text-3xl mr-3 md:mr-6 mb-1 md:mb-0">Sinistro {{data.claimId}}</h1>
				<StateOfClaim :class="data.stateOfClaim" :value-text="lista_filtri.stateOfClaim[data.stateOfClaim]"/>
			</div>
			<p v-if="dettagli_liquidatore.Nome !== ''" class="ml-2 p-1">Assegnato a <u>{{dettagli_liquidatore.Nome}} {{ dettagli_liquidatore.Cognome }}</u></p>
		</div>
        <hr class="my-6" />
		<div class="flex flex-col lg:flex-row lg:divide-x lg:divide-dashed lg:divide-gray-300">
			<div>
				<select :value="select" class="p-2 px-4 rounded-lg w-full lg:hidden dark:bg-zinc-700" name="opzioni" id="opzioni" @change="handleSelect($event)">
					<option class="font-sans" value="dettagli">Dettagli</option>
					<option class="font-sans" value="mappa">Luogo sinistro</option>
					<option v-if="data.stateOfClaim !== 'ToAssign'" class="font-sans" value="richieste">Richieste</option>
					<option class="font-sans" value="documenti_caricati">Documenti caricati</option>
					<option class="font-sans" value="documenti_da_caricare">Documenti da caricare</option>
					<option v-if="data.stateOfClaim !== 'ToAssign'" class="font-sans" value="diario_di_sistema">Diario di sistema</option>
				</select>
				<div class="hidden lg:flex">
					<div>
						<p @click="handleSelect($event)" :style="[select == 'dettagli' && { color: 'red' }]" class="dettagli mb-3 cursor-pointer text-l font-300 rounded-md w-36 px-4 py-2 hover:bg-gray-300 dark:hover:bg-zinc-900 active:bg-red-600 dark:active:bg-red-300 active:!text-white dark:text-white dark:active:!text-black">Dettagli</p>
						<p @click="handleSelect($event)" :style="[select == 'mappa' && { color: 'red' }]" class="mappa mb-3 cursor-pointer text-l font-300 rounded-md w-36 px-4 py-2 hover:bg-gray-300 dark:hover:bg-zinc-900 active:bg-red-600 dark:active:bg-red-300 active:!text-white dark:text-white dark:active:!text-black">Luogo sinistro</p>
						<p v-if="data.stateOfClaim !== 'ToAssign'" @click="handleSelect($event)" :style="[select == 'richieste' && { color: 'red' }]" class="richieste mb-3 cursor-pointer text-l font-300 rounded-md w-36 px-4 py-2 hover:bg-gray-300 dark:hover:bg-zinc-900 active:bg-red-600 dark:active:bg-red-300 active:!text-white dark:text-white dark:active:!text-black">Richieste</p>
						<p @click="handleSelect($event)" :style="[select == 'documenti_caricati' && { color: 'red' }]" class="documenti_caricati mb-3 cursor-pointer text-l font-300 rounded-md w-36 px-4 py-2 hover:bg-gray-300 dark:hover:bg-zinc-900 active:bg-red-600 dark:active:bg-red-300 active:!text-white dark:text-white dark:active:!text-black">Documenti caricati</p>
						<p @click="handleSelect($event)" :style="[select == 'documenti_da_caricare' && { color: 'red' }]" class="documenti_da_caricare mb-3 cursor-pointer text-l font-300 rounded-md w-36 px-4 py-2 hover:bg-gray-300 dark:hover:bg-zinc-900 active:bg-red-600 dark:active:bg-red-300 active:!text-white dark:text-white dark:active:!text-black">Documenti da caricare</p>
						<p v-if="data.stateOfClaim !== 'ToAssign'" @click="handleSelect($event)" :style="[select == 'diario_di_sistema' && { color: 'red' }]" class="diario_di_sistema mb-3 cursor-pointer text-l font-300 rounded-md w-36 px-4 py-2 hover:bg-gray-300 dark:hover:bg-zinc-900 active:bg-red-600 dark:active:bg-red-300 active:!text-white dark:text-white dark:active:!text-black">Diario di sistema</p>
					</div>
				</div>
			</div>
			<hr class="my-6" />
			<div class="w-full mx-3 md:mx-6 px-3 md:px-6">
				<div v-if="select == 'mappa'" id="luogo-incidente">
					<h1 class="text-xl font-bold">Luogo dell'incidente</h1>
					<p class="mt-6 px-2 rounded text-lg w-fit bg-red-100 dark:text-black dark:bg-red-200">{{ placeName }}</p>
					<GMapMap
						:center="{lat: latitude, lng: longitude}"
						:zoom="17"
						:options="{
							zoomControl: true,
							mapTypeControl: false,
							scaleControl: false,
							streetViewControl: false,
							rotateControl: true,
							fullscreenControl: false,
						}"
						map-type-id="terrain"
						class="mt-2 w-96 h-56 md:w-[42rem] md:h-[24rem] lg:w-[48rem] xl:w-[64rem] xl:h-[36rem]"
					>
						<GMapMarker
							:position="{lat: latitude, lng: longitude}"
							:clickable="true"
							:draggable="false"
						/>
					</GMapMap>
					<hr class="my-6" />
				</div>
				<div v-if="select == 'dettagli'">
					<div class="lg:flex">
						<TableSimpleWithException :obj="dettagli" title="Dettagli" />
						<TableSimple :obj="dettagli_contraente" title="Dati contraente" />
					</div>
					<div class="lg:flex">
						<TableSimple :obj="dettagli_calcoli" title="Condizioni polizza" />
						<TableSimpleEdit 
							:obj="dettagli_quotazione_riparazione" 
							title="Quotazione di riparazione"
							:is-amount-edited="data.quotation?.isAmountEdited"
							@confirm-edit="(newMechanic) => editMechanicHandler(newMechanic)"
							@show-modal="() => showLiquidationNotesModal()"
						/>
					</div>
					<hr class="my-6" />
					<div>
						<select class="p-2 px-4 rounded-lg w-fit dark:bg-zinc-700" :value="whichDatiUser" name="scelta-dati" id="scelta-dati" @change="handleDatiUser($event)">
							<option class="font-sans" value="segnalatore">Segnalatore</option>
							<option class="font-sans" value="liquidatore">Liquidatore</option>
						</select>
						<div id="dati-anagrafici" class="m-3 mt-6">
							<TableSimple v-if="whichDatiUser === 'segnalatore'" :obj="dettagli_segnalatore" title="Dati segnalatore" />
							<TableSimple v-else-if="whichDatiUser === 'liquidatore'" :obj="dettagli_liquidatore" title="Dati liquidatore" />
							<p v-else class="mt-2">Il sinistro non è stato ancora liquidato.</p>
						</div>
					</div>
				</div>
				<Messages v-if="select == 'richieste'" 
					:claimId="data.claimId"	
				/>
				<TableDocuments v-if="select == 'documenti_caricati'" :documents="documents" :idClaim="dettagli.Codice"/>
				<div v-if="select == 'documenti_da_caricare'">
					<TableDocumentsToUpload :claimId="data.claimId" :uploaded-documents="documents" :warranty="warranty" />
				</div>
				<SystemMessages v-if="select == 'diario_di_sistema'" :claim-id="data.claimId" />
			</div>
		</div>
    </div>
</template>

<script setup>
	import router from '@/router';
	import { ref, onMounted } from 'vue';
	import { EditMechanic, getClaimByClaimId } from '@/helpers/claims.service.ts';
	import StateOfClaim from '@/components/StateOfClaim.vue';
	import TableDocuments from '@/components/TableDocuments.vue';
	import { lista_filtri, listOfMonths } from '@/helpers/constants';
	import Messages from '@/components/messages/Messages.vue';
	import SystemMessages from '@/components/messages/SystemMessages.vue';
	import { useRiepilogoSinistroStore } from '@/stores/riepilogoSinistro.js';
	import TableSimple from '@/components/TableSimple.vue';
	import TableSimpleWithException from '@/components/TableSimpleWithException.vue';
	import TableDocumentsToUpload from '@/components/TableDocumentsToUpload.vue';
	import { ToMoney, ToCurrency } from '@/helpers/utils';
	import TableSimpleEdit from '@/components/TableSimpleEdit.vue';
	import { useToast } from 'vue-toast-notification';
	import ModalNoButtons from '@/components/ModalNoButtons.vue';

	const store = useRiepilogoSinistroStore();
	const select = ref(store.select);
	const whichDatiUser = ref('segnalatore');
	const message = ref('');
	const showModal = ref(false);

	const data = ref({});
	const warranty = ref({});
	const contractorRegistry = ref({});
	const tickets = ref([]);
	const documents = ref([]);
	
	const latitude = ref(0);
	const longitude = ref(0);
	const placeName = ref('');

	// Strutture display dati nelle tabelle
	const dettagli = ref({});
	const dettagli_contraente = ref({});
	const dettagli_segnalatore = ref({});
	const dettagli_liquidatore = ref({});
	const dettagli_quotazione_riparazione = ref({});
	const dettagli_calcoli = ref({});
	

	onMounted(() => {
		getClaimByClaimIdHandler(router.currentRoute.value.params.claimId);
	})

	async function getClaimByClaimIdHandler(claimId) {
		const response = await getClaimByClaimId(claimId);
		if (response.status === 200) {
			data.value = response.data;

			warranty.value = data.value.warranty;
			contractorRegistry.value = data.value.contractorRegistry;
			tickets.value = data.value.tickets ? data.value.tickets : [];
			documents.value = data.value.documentsDealer ? data.value.documentsDealer : [];

			dettagli.value = {			
				'Codice': data.value.claimId,
				'Codice interno': data.value.claimBackend ? `${data.value.claimBackend.code} - ${data.value.claimBackend.systemName}` : '-',
				'Data evento': new Date(data.value.eventDate).toLocaleDateString(),
				'Data segnalazione evento': new Date(data.value.claimCreateAt).toLocaleDateString(),
				'Garanzia': data.value.warranty.name,
				'Approvazione Rapida': data.value.isFastApp ? 'true' : 'false',
				'Targa': data.value.vehicleData.plateNumber,
				'Telaio': data.value.vehicleData.vin,
				'Descrizione': data.value.createData.description
			}

			addDetailsBasedOnWarranty(data.value.warranty.code);

			dettagli_contraente.value = {
				'Nome': data.value.contractorRegistry.firstName,
				'Cognome': data.value.contractorRegistry.lastName,
				'Email': data.value.contractorRegistry.email,
				'Indirizzo': data.value.contractorRegistry.address,
				'Telefono': data.value.contractorRegistry.mobilePhone,
				'Codice Fiscale / P. IVA': data.value.contractorRegistry.fiscalCode,
			}

			dettagli_segnalatore.value = {
				'Email': (data.value.utenteCreateClaim && data.value.utenteCreateClaim.length > 0) ? data.value.utenteCreateClaim[0].email : '',
				'Nome': (data.value.utenteCreateClaim && data.value.utenteCreateClaim.length > 0) ? data.value.utenteCreateClaim[0].firstName : '',
				'Cognome': (data.value.utenteCreateClaim && data.value.utenteCreateClaim.length > 0) ? data.value.utenteCreateClaim[0].lastName : '',
				'Compagnia': (data.value.utenteCreateClaim && data.value.utenteCreateClaim.length > 0) ? data.value.utenteCreateClaim[0].company.name : '',
			}

			dettagli_liquidatore.value = {
				'Email': (data.value.utenteLiquidatorClaim && data.value.utenteLiquidatorClaim.length > 0) ? data.value.utenteLiquidatorClaim[0].email : '',
				'Nome': (data.value.utenteLiquidatorClaim && data.value.utenteLiquidatorClaim.length > 0) ? data.value.utenteLiquidatorClaim[0].firstName : '',
				'Cognome': (data.value.utenteLiquidatorClaim && data.value.utenteLiquidatorClaim.length > 0) ? data.value.utenteLiquidatorClaim[0].lastName : '',
				'Compagnia': (data.value.utenteLiquidatorClaim && data.value.utenteLiquidatorClaim.length > 0) ? data.value.utenteLiquidatorClaim[0].company.name : '',
			}

			dettagli_calcoli.value = {
				'Massimale': ToCurrency(data.value.policyConditions.maximal),
				'Franchigia': ToCurrency(data.value.policyConditions.deductible),
				'Decurtazione': '-',
        		'Scoperto': `${data.value.policyConditions.overdraft}%`,
			}

			if (data.value.policyConditions.deductible == -1) {
				dettagli_calcoli.value['Franchigia'] = '-';
				dettagli_calcoli.value['Decurtazione'] = ToCurrency(data.value.policyConditions.reduction);
			}

			dettagli_quotazione_riparazione.value = {
				'Iban': data.value.ibanDealer.code,
				'Importo da liquidare': ToCurrency(ToMoney(data.value.quotation.amount)),
				'Recupero IVA': data.value.quotation.deductiblePercent ? ToMoney(data.value.quotation.deductiblePercent) + '%' : '-',
				'Meccanico': data.value.quotation.mechanic,
			}

			latitude.value = response.data.location.latitude;
			longitude.value = response.data.location.longitude;
			placeName.value = response.data.location.placeName;
		}
	}

	function handleSelect(event) {
		if (event.target.tagName == 'P') {
			select.value = event.target.classList[0]
		} else {
			select.value = event.target.value;
		}
	};

	function handleDatiUser(event) {
		whichDatiUser.value = event.target.value;
	}

	function addDetailsBasedOnWarranty(warranty) { // string
		if (warranty == 'incendio' || warranty == 'furtoTotale') {
			const furtoIncendio = data.value.warranty.otherDetails.furtoTotaleIncendioDetails;
			furtoIncendio.isReplace ? dettagli.value['Sostituisce'] = 'Si' : dettagli.value['Ripara'] = 'Si';
			
			if (furtoIncendio.isRestart10 || furtoIncendio.isRestart20) {
				dettagli.value['Restart selezionata'] = furtoIncendio.isRestartSelected ? 'Si' : 'No';
				dettagli.value['Importo Restart'] = ToCurrency(Number(furtoIncendio.restartAmount));
			}
		}

		if (warranty == 'cristalli') {
			const cristalli = data.value.warranty.otherDetails.cristalliDetails;
			cristalli.isReplace ? dettagli.value['Sostituisce'] = 'Si' : dettagli.value['Ripara'] = 'Si';
		}
	}

	async function editMechanicHandler(newMechanic) {
		const toast = useToast();
		
		const payload = {
			'mechanic': newMechanic,
		}

		const resp = await EditMechanic(data.value.claimId, payload);
		if (resp) {
			toast.success('Meccanico modificato correttamente', { position: 'top' });
			
			await getClaimByClaimIdHandler(router.currentRoute.value.params.claimId);
			return
		}

		toast.error('Errore durante la modifica del meccanico', { position: 'top' });
	}

	function showLiquidationNotesModal() {
		showModal.value = true;
	}
</script>	

<style scoped>

</style>
