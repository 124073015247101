<template>
    <div class="p-4">
        <div v-if="isNewTicket" class="flex flex-col items-center">
            <img class="p-2" src="@/assets/svg/team_chat.svg" />
            <p>Scrivi qualcosa per iniziare!</p>
        </div>
        <div v-else>
            <div id="messages-container" class="overflow-y-auto pb-12">
                <div v-for="message in messages" :id="message._id" :key="message._id">
                    <ChatMessage v-if="!message.isFileRequest"
                        :isSent="message.user._id == session.user.id"
                        :time="message.createAt"
                        :message="message.message"
                        :initials="returnInitials(message.user.firstName, message.user.lastName)"
                    />
                    <FileMessage v-else 
                        :isSent="message.user._id == session.user.id"
                        :time="message.createAt"
                        :fileRequest="message.fileRequest"
                        :initials="returnInitials(message.user.firstName, message.user.lastName)"
                        :isFileResponse="message.isFileResponse"
                        :claimId="claimId"
                        :ticketId="ticket._id"
                        :messageId="message._id"
                    />
                </div>
            </div>
        </div>

        <div v-if="!ticketProp.isDone" class="flex justify-center items-center">
            <div class="fixed bottom-2 pb-3 bg-white">
                <hr class="my-3" />
                <form @submit.prevent="sendMessage">
                    <div class="flex bg-white p-2 rounded shadow">
                        <input v-model="textMessage" name="ticket" class="rounded outline-none px-4 p-2 h-12 w-[24rem] dark:bg-zinc-700" placeholder="Scrivi un messaggio" type="text" autocomplete="off" />
                        <button type="submit" class="h-10 w-12 rounded p-2 bg-red-500 text-white hover:bg-red-600 focus:ring focus:ring-red-300"><span class="material-symbols-outlined">send</span></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useSessionStore } from '@/stores/session';
    import { ref, watchEffect } from 'vue';
    import ChatMessage from './ChatMessage.vue';
    import { SendMessage } from '@/helpers/messages.service.js';
    import { returnInitials } from '@/utilities/getInizialiUser';
    import FileMessage from './files/FileMessage.vue';

    const session = useSessionStore();
    const props = defineProps({
        ticketProp: Object,
        claimId: String,
    });

    // If there are no messages
    const isNewTicket = ref(false);
    const ticket = ref({});
    const messages = ref([]);
    const textMessage = ref('');

    watchEffect(() => {
        handleTicket();
    });

    function handleTicket() {
        ticket.value = props.ticketProp;
        isNewTicket.value = ticket.value.messages.length == 0;

        if (messages.value?.length < ticket.value?.messages?.length) {
            messages.value = ticket.value.messages;
            // scrollToBottom();
        }
    }

    async function sendMessage() {
        const payload = {
            'ticketId': ticket.value._id,
            'message': textMessage.value,
        }

        await SendMessage(props.claimId, payload).then(resp => {
            if (resp.status == 201) {
                const message = resp.data;
                addMessage(message);

                isNewTicket.value = false;
            }
        }).catch(err => {
            console.log(err);
        });

        textMessage.value = '';
	}

    const addMessage = function addMessageToMessagesArray(message) {
        messages.value.push(message);
    }

    function scrollToBottom() {
        if (messages.value.length <= 0) {
            return 
        }

        const lastMessageID = messages.value[messages.value.length - 1]._id;
        var container = document.getElementById('messages-container');
        var rowToScrollTo = document.getElementById(lastMessageID);

        if (rowToScrollTo) {
            rowToScrollTo.scrollIntoView();
            // container.scrollTop = rowToScrollTo.offsetTop;
        }
    }
</script>

<style scoped>

</style>