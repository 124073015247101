<template>
    <button @click="$emit('refresh')" class="pt-2 px-2 pb-1 bg-gray-200 rounded-full hover:bg-gray-300 active:bg-gray-400"><span class="text-md material-symbols-outlined">sync</span></button>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>