import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import NuovoSinistro from '../views/NuovoSinistro.vue'
import Account from '../views/Account.vue'
import RiepilogoSinistro from '../views/RiepilogoSinistro.vue'
import Amministrazione from '../views/Amministrazione.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/account',
    name: 'account',
    component: Account
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/dashboard/:claimId',
    name: 'riepilogoSinistro',
    component: RiepilogoSinistro
  },
  {
    path: '/nuovo-sinistro',
    name: 'NuovoSinistro',
    component: NuovoSinistro
  },
  {
    path: '/amministrazione',
    name: 'amministrazione',
    component: Amministrazione
  },
]

//TODO: avviare web history post login (da vedere su internet)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
